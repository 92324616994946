import React, { useState } from 'react';
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { QrReader } from 'react-qr-reader';
import { FaCheckCircle, FaTimesCircle, FaExclamationTriangle, FaInfoCircle, FaRedo } from 'react-icons/fa';

interface ModalQrReaderProps {
    isOpen: boolean;
    onClose: () => void;
}

interface ScanResult {
    status: string;
    details: any | null;
}

export const ModalQrReader = ({ isOpen, onClose }: ModalQrReaderProps): JSX.Element | null => {
    const { theme } = useTheme();
    const [scanResult, setScanResult] = useState<ScanResult | null>(null);

    const handleScanResult = async (data: string | null) => {
        if (data) {
            try {
                const response = await fetch(data);
                if (response.ok) {
                    const result = await response.json();
                    const status = result.message || result.status;

                    switch (status) {
                        case "Confirmado":
                            setScanResult({ status: "Confirmado", details: result });
                            break;
                        case "Cancelado":
                            setScanResult({ status: "Cancelado", details: result });
                            break;
                        case "Pagamento Antecipado":
                            setScanResult({ status: "Pagamento Antecipado", details: result });
                            break;
                        case "QR Code já foi utilizado para entrada.":
                            setScanResult({ status: "QR Code já utilizado", details: result });
                            break;
                        default:
                            setScanResult({ status: "Participação não encontrada", details: result });
                            break;
                    }
                } else {
                    setScanResult({ status: "Participação não encontrada", details: null });
                }
            } catch (error) {
                setScanResult({ status: "Erro ao verificar QR Code", details: null });
            }
        }
    };

    const handleRetry = () => {
        setScanResult(null);
    };

    const getModalContent = () => {
        let title, icon, color, message;

        switch (scanResult?.status) {
            case "Confirmado":
                title = "Confirmado";
                icon = <FaCheckCircle size={50} color="#E1FF01" />;
                color = "#E1FF01";
                message = "Este usuário confirmou sua presença antecipadamente. No entanto, é necessário realizar o pagamento dos ingressos para completar a entrada.";
                break;
            case "Cancelado":
                title = "Cancelado";
                icon = <FaTimesCircle size={50} color="#dc3545" />;
                color = "#dc3545";
                message = "A participação do usuário foi cancelada. A entrada não está autorizada.";
                break;
            case "Pagamento Antecipado":
                title = "Pagamento Antecipado";
                icon = <FaInfoCircle size={50} color="#007bff" />;
                color = "#007bff";
                message = "O pagamento foi realizado antecipadamente. A entrada do usuário está autorizada.";
                break;
            case "QR Code já utilizado":
                title = "QR Code já utilizado";
                icon = <FaRedo size={50} color="#ff6347" />;
                color = "#ff6347";
                message = `Este QR Code já foi utilizado para a entrada.`
                break;
            default:
                title = "Participação não encontrada";
                icon = <FaExclamationTriangle size={50} color="#ffc107" />;
                color = "#ffc107";
                message = "Não foi possível localizar uma participação registrada para este evento. Verifique as informações e tente novamente.";
                break;
        }

        return { title, icon, color, message };
    };


    if (!isOpen) return null;

    const { title, icon, color, message } = getModalContent();

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-4 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{ backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6" }}
            >
                <IoMdClose
                    size={20}
                    onClick={onClose}
                    className="cursor-pointer transition-colors"
                    style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                />

                {scanResult ? (
                    <div className="flex flex-col items-center w-full">
                        <div className="mb-4" style={{ color }}>
                            {icon}
                        </div>
                        <h2 className="text-2xl font-bold" style={{ color }}>
                            {title}
                        </h2>
                        <p className="text-base text-center mt-2" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                            {message}
                        </p>

                        <div className="flex w-full gap-8 mt-6">
                            <button
                                className="h-10 flex-1 bg-[#42404a] text-[#f1f1f1] font-bold py-2 px-4 rounded-2xl"
                                onClick={onClose}
                            >
                                Cancelar
                            </button>

                            <button
                                className="h-10 flex-1 bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl"
                                onClick={handleRetry}
                            >
                                Escanear novamente
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-start w-full">
                        <h2 className="text-2xl font-bold mb-4" style={{
                            color: "#E1FF01",
                        }}>
                            Escaneie o QR Code
                        </h2>
                        <p className="mb-4" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>
                            Posicione o QR Code no centro da tela para capturar as informações.
                        </p>

                        <div className="flex justify-center w-full mb-4">
                            <QrReader
                                onResult={(result, error) => {
                                    if (result) handleScanResult(result.getText());
                                }}
                                containerStyle={{ width: '100%' }}
                                constraints={{
                                    facingMode: "environment"
                                }}
                            />
                        </div>

                        <button
                            onClick={onClose}
                            className="self-center bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl transition-all hover:bg-[#d1e600]"
                        >
                            Cancelar escâner
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
