import React, { useState, useEffect, useContext } from "react";
import logo from "../../imagens/logo.png";
import { API_URL } from "../../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

export const LoginAdmin = (): JSX.Element => {
    const [formData, setFormData] = useState({
        username: "",
        password: ""
    });
    const [rememberMe, setRememberMe] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const savedUsername = localStorage.getItem("rememberedUsername");
        const savedRememberMe = localStorage.getItem("rememberMe");
        if (savedUsername && savedRememberMe === "true") {
            setFormData({ ...formData, username: savedUsername });
            setRememberMe(true);
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setRememberMe(isChecked);

        if (isChecked) {
            localStorage.setItem("rememberedUsername", formData.username);
            localStorage.setItem("rememberMe", "true");
        } else {
            localStorage.removeItem("rememberedUsername");
            localStorage.removeItem("rememberMe");
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/api/login-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                const token = result.token;
                login(token);
                toast.success('Login realizado com sucesso!');
                navigate('/saque-requests');
            } else {
                toast.error(result.message || 'Erro ao realizar o login.');
            }
        } catch (error) {
            toast.error('Erro ao conectar com o servidor.');
        }
    };

    return (
        <div className="relative w-full h-full min-h-screen bg-[#1b2026] overflow-hidden">
            <ToastContainer />
            <div className="relative w-full h-auto overflow-hidden">
                <div className="absolute w-full h-full top-0 left-0 opacity-[0.2] bg-[url(https://c.animaapp.com/RLk3Kegs/img/glow.svg)] bg-cover bg-center overflow-hidden"></div>

                <div className="flex justify-center items-center w-full h-screen bg-[#1b2026]">
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col w-full max-w-lg h-auto p-10 gap-8 rounded-[20px] border border-solid border-[#707070] flex items-center"
                    >
                        <div className="relative w-52 h-10">
                            <img
                                className="w-[215px] max-w-full h-auto bg-blend-soft-light"
                                srcSet={`${logo} 1x, ${logo}@2x.png 2x, ${logo}@3x.png 3x`}
                                src={logo}
                                alt="Logo"
                            />
                        </div>

                        <header className="flex flex-col items-center gap-6 relative self-stretch w-full flex-[0_0_auto] bg-transparent">
                            <div className="flex flex-col items-start gap-3 relative self-stretch w-full">
                                <div className="self-stretch font-semibold text-[#e1ff01] text-3xl text-center leading-[38px] tracking-[0]">
                                    Entre na sua conta
                                </div>
                                <p className="relative self-stretch font-medium text-[#ffffff] text-base text-center tracking-[0] leading-6">
                                    Insira suas credenciais para acessar o painel administrador.
                                </p>
                            </div>
                        </header>

                        <div className="flex-col gap-6 relative self-stretch w-full rounded-xl flex items-center">
                            <div className="flex flex-col items-start gap-5 relative self-stretch w-full">
                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Usuário
                                    </label>
                                    <input
                                        type="text"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid border-1 rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira seu usuário"
                                    />
                                </div>

                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Senha
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid border-1 rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira sua senha"
                                    />
                                </div>
                            </div>

                            <div className="w-full flex justify-between items-center">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="remember"
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        className="mr-2 appearance-none w-5 h-5 rounded-full bg-[#302f39] border border-[#575561] checked:bg-[#e1ff01] checked:border-none focus:ring-2 focus:ring-offset-2 focus:ring-[#e1ff01]"
                                    />
                                    <label
                                        htmlFor="remember"
                                        className="text-[#ffffff] text-sm"
                                    >
                                        Lembrar de mim
                                    </label>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="self-stretch h-10 bg-[#e1ff01] border-none text-[#22252a] font-semibold rounded-2xl transition duration-300 ease-in-out transform hover:bg-[#a8bf00] hover:scale-105"
                            >
                                Entrar
                            </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
