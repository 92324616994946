import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../imagens/logo.png";
import { HiMenuAlt3, HiX } from "react-icons/hi";

export const Menu = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const getLinkClassName = (path: string) => {
    return location.pathname === path
      ? "text-white font-semibold"
      : "text-white/60 font-semibold hover:text-white cursor-pointer";
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="relative top-0 left-0 right-0 flex items-center justify-between px-4 lg:px-20 w-full h-20 z-50">
      <div className="flex items-center gap-4 lg:gap-10">
        <div
          className="w-28 lg:w-36 h-10 cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
          onClick={() => navigate("/")}
        >
          <img
            className="w-full h-auto"
            srcSet={`${logo} 1x, ${logo}@2x.png 2x, ${logo}@3x.png 3x`}
            src={logo}
            alt="Logo"
          />
        </div>

        <div className="hidden lg:block w-px h-5 bg-white/40 rounded-sm" />

        <div className="hidden md:flex items-center gap-4 lg:gap-7">
          <Link to="/" className={getLinkClassName("/")}>
            Início
          </Link>
          <Link to="/sobre" className={getLinkClassName("/sobre")}>
            Sobre
          </Link>
          <Link to="/planos" className={getLinkClassName("/planos")}>
            Planos
          </Link>
          <Link to="/contato" className={getLinkClassName("/contato")}>
            Contato
          </Link>
        </div>
      </div>

      {/* Botões com ocultação condicional para dispositivos móveis */}
      <div className="hidden md:inline-flex items-center gap-5 relative flex-[0_0_auto]">
        <div className="justify-center p-2.5 bg-[#22252a] rounded-[28px] border border-solid border-[#2a2a2a] inline-flex items-center gap-5 relative flex-[0_0_auto]">
          <button
            className="flex-[0_0_auto] border-none bg-[#e1ff01] text-[#22252a] font-semibold py-2 px-4 rounded-[28px] transition duration-300 ease-in-out transform hover:bg-[#a8bf00] hover:scale-105 cursor-pointer"
            onClick={() => navigate("/login")}
          >
            Entrar
          </button>
          <button
            className="flex-[0_0_auto] border-none bg-[#22252a] text-[#ffffff] font-semibold py-2 px-4 rounded-[28px] border border-solid cursor-pointer border-[#2a2a2a] transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#333] "
            onClick={() => navigate("/cadastro")}
          >
            Cadastro
          </button>
        </div>
      </div>

      {/* Ícone de menu para dispositivos móveis */}
      <button
        className="md:hidden text-white text-2xl"
        onClick={toggleMenu}
      >
        {menuOpen ? <HiX /> : <HiMenuAlt3 />}
      </button>

      {/* Menu responsivo */}
      {menuOpen && (
        <div className="absolute top-20 right-0 w-full bg-[#1b2026] py-4 px-6 md:hidden flex flex-col items-center gap-4 z-20">
          <Link
            to="/"
            className={getLinkClassName("/")}
            onClick={toggleMenu}
          >
            Início
          </Link>
          <Link
            to="/sobre"
            className={getLinkClassName("/sobre")}
            onClick={toggleMenu}
          >
            Sobre
          </Link>
          <Link
            to="/planos"
            className={getLinkClassName("/planos")}
            onClick={toggleMenu}
          >
            Planos
          </Link>
          <Link
            to="/contato"
            className={getLinkClassName("/contato")}
            onClick={toggleMenu}
          >
            Contato
          </Link>
          <button
            className="w-full bg-[#e1ff01] text-[#22252a] font-semibold py-2 rounded-full transition duration-300 ease-in-out hover:bg-[#a8bf00]"
            onClick={() => {
              navigate("/login");
              toggleMenu();
            }}
          >
            Entrar
          </button>
          <button
            className="w-full bg-[#22252a] text-white font-semibold py-2 rounded-full border border-[#2a2a2a] transition duration-300 ease-in-out hover:bg-[#333]"
            onClick={() => {
              navigate("/cadastro");
              toggleMenu();
            }}
          >
            Cadastro
          </button>
        </div>
      )}
    </div>
  );
};
