import React, { useState } from 'react';
import { useTheme } from "../../ThemeContext";
import { API_URL } from "../../config";
import { IoMdClose } from "react-icons/io";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalCadastroProps {
    isOpen: boolean;
    onClose: () => void;
}

export const ModalCreateUser = ({ isOpen, onClose }: ModalCadastroProps): JSX.Element | null => {
    if (!isOpen) return null;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const { theme } = useTheme();

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleSave = async () => {
        const company_id = localStorage.getItem("companyId");
    
        if (!name || !email || !role || !company_id) {
            toast.error("Preencha todos os campos.");
            return;
        }
    
        try {
            const response = await fetch(`${API_URL}/api/users`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ name, email, role, company_id }),
            });
    
            if (!response.ok) throw new Error("Erro ao criar usuário");
    
            toast.success("Usuário criado com sucesso!");
            onClose();
        } catch (error) {
            toast.error("Erro ao criar o usuário.");
            console.error(error);
        }
    };
    

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{
                backdropFilter: 'blur(5px)',
            }}
        >
            <ToastContainer />
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{
                    backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
                }}
            >
                <IoMdClose
                    size={20}
                    onClick={onClose}
                    className="cursor-pointer transition-colors"
                    style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    <div className="flex flex-col items-start gap-2 w-full">
                        <div className="text-[#e1ff01] text-2xl font-bold">Criar novo usuário</div>
                        <div className="text-base font-medium" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>Preencha o formulário para criar um novo usuário</div>
                    </div>

                    {/* Nome Completo */}
                    <div className="flex flex-col w-full">
                        <label
                            className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                            htmlFor="nomeCompleto" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Nome Completo
                        </label>

                        <input
                            id="nomeCompleto"
                            name="nomeCompleto"
                            type="text"
                            placeholder="Insira o Nome Completo"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                border: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        />
                    </div>

                    {/* E-mail */}
                    <div className="flex flex-col w-full">
                        <label
                            className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                            htmlFor="email" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            E-mail
                        </label>

                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Insira o E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                border: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        />
                    </div>

                    {/* Cargo */}
                    <div className="flex flex-col w-full">
                        <label
                            className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                            htmlFor="cargo"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Cargo
                        </label>

                        <select
                            id="cargo"
                            name="cargo"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#666666" : "#666666",
                            }}
                        >
                            <option value="" disabled selected hidden>Selecione o Cargo</option>
                            <option value="Administrador">Administrador</option>
                            <option value="Moderador">Moderador</option>
                            <option value="Recepcionista">Recepcionista</option>
                        </select>
                    </div>
                </div>

                <div className="flex w-full gap-8">
                    <button
                        className="h-10 flex-1 bg-[#42404a] text-[#f1f1f1] font-bold py-2 px-4 rounded-2xl border-none transition-all hover:bg-[#3a383d]"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>

                    <button
                        className="h-10 flex-1 bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl border-none transition-all hover:bg-[#d1e600]"
                        onClick={handleSave}
                    >
                        Salvar usuário
                    </button>
                </div>
            </div>
        </div>
    );
};
