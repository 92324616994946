import React, { useState, useEffect } from 'react';
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { API_URL } from "../../config";
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';

interface ModalSaqueProps {
    isOpen: boolean;
    onClose: () => void;
    fetchData: () => void;
}
export const ModalSaque = ({ isOpen, onClose, fetchData }: ModalSaqueProps): JSX.Element | null => {
    const { theme } = useTheme();
    const [userData, setUserData] = useState<{ chave_pix: string | null; full_name: string } | null>(null);
    const [pixKey, setPixKey] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditingPix, setIsEditingPix] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState('');

    useEffect(() => {
        if (isOpen) {
            const fetchUserData = async () => {
                try {
                    const session_token = localStorage.getItem('authToken');
                    const company_id = localStorage.getItem('companyId');

                    if (!session_token || !company_id) {
                        throw new Error("Token de sessão ou ID da empresa não encontrado.");
                    }

                    const response = await fetch(`${API_URL}/api/user-info?session_token=${session_token}&company_id=${company_id}`);
                    const data = await response.json();

                    if (response.ok) {
                        setUserData(data);
                    } else {
                        console.error("Erro ao buscar informações do usuário:", data.message);
                    }
                } catch (error) {
                    console.error("Erro ao buscar dados do servidor:", error);
                }
            };

            fetchUserData();
        }
    }, [isOpen]);

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleSetPixKey = async () => {
        setIsSubmitting(true);
        try {
            const session_token = localStorage.getItem('authToken');
            const company_id = localStorage.getItem('companyId');

            if (!session_token || !company_id) {
                throw new Error("Token de sessão ou ID da empresa não encontrado.");
            }

            const response = await fetch(`${API_URL}/api/set-pix-key`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    session_token,
                    company_id,
                    chave_pix: pixKey
                })
            });

            const data = await response.json();

            if (response.ok) {
                setUserData(prev => prev ? { ...prev, chave_pix: pixKey } : null);
                toast.success("Chave PIX definida com sucesso!");
                setIsEditingPix(false); // Retorna para o conteúdo de saque
            } else {
                console.error("Erro ao definir chave PIX:", data.message);
                toast.error("Erro ao definir chave PIX");
            }
        } catch (error) {
            console.error("Erro ao enviar chave PIX:", error);
            toast.error("Erro ao enviar chave PIX");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleWithdrawRequest = async () => {
        setIsSubmitting(true);
        try {
            const session_token = localStorage.getItem('authToken');
            const company_id = localStorage.getItem('companyId');

            if (!session_token || !company_id || !withdrawAmount) {
                throw new Error("Dados insuficientes para solicitar o saque.");
            }

            const response = await fetch(`${API_URL}/api/create-withdrawal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    session_token,
                    company_id,
                    amount: parseFloat(withdrawAmount.replace('R$', '').replace('.', '').replace(',', '.')) // Formatação para float
                })
            });

            const data = await response.json();

            if (response.ok) {
                toast.success("Solicitação de saque enviada com sucesso!");
                setWithdrawAmount(''); // Limpa o valor de saque
                fetchData(); // Atualiza os dados da página
                onClose(); // Fecha o modal
            } else {
                console.error("Erro ao solicitar saque:", data.message);
                toast.error(data.message || "Erro ao solicitar saque"); // Mostra o motivo específico do erro
            }
        } catch (error) {
            console.error("Erro ao enviar solicitação de saque:", error);
            toast.error("Erro ao enviar solicitação de saque");
        } finally {
            setIsSubmitting(false);
        }
    };


    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{
                backdropFilter: 'blur(5px)',
            }}
        >
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{
                    backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
                }}
            >
                <IoMdClose
                    size={20}
                    onClick={onClose}
                    className="cursor-pointer transition-colors"
                    style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    {userData?.chave_pix && !isEditingPix ? (
                        <>
                            <h2 className="text-2xl font-bold text-[#e1ff01]">Olá, {userData.full_name}</h2>
                            <p className="text-sm font-medium" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Sua chave PIX está configurada como: <span className="font-bold text-white" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>{userData.chave_pix}</span>
                            </p>

                            <label
                                className="text-[#f1f1f1] text-sm font-bold"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            >
                                Valor do Saque
                            </label>
                            <div
                                className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl text-base font-medium leading-6 transition-all focus-within:ring-2 focus-within:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                    border: theme === "dark" ? "#575560" : "#DDDDDD",
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ color: "#E1FF01", marginRight: "8px" }}>R$</span>
                                <CurrencyInput
                                    value={withdrawAmount}
                                    onValueChange={(value) => setWithdrawAmount(value || '')}
                                    placeholder="Insira o valor desejado"
                                    className="flex-1 bg-transparent outline-none"
                                    style={{
                                        color: theme === "dark" ? "#FFFFFF" : "#000000"
                                    }}
                                    decimalsLimit={2}
                                />
                            </div>

                            <div className="flex gap-4 w-full mt-4">
                                <button
                                    onClick={() => setIsEditingPix(true)}
                                    className="bg-[#42404a] text-[#f1f1f1] font-bold py-2 px-4 rounded-2xl w-1/2 transition-all hover:bg-[#3a383d]"
                                >
                                    Alterar Chave PIX
                                </button>
                                <button
                                    onClick={handleWithdrawRequest}
                                    className="bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl w-1/2 transition-all hover:bg-[#d1e600]"
                                    disabled={!withdrawAmount || parseFloat(withdrawAmount.replace('R$', '').replace(',', '.')) <= 0 || isSubmitting}
                                >
                                    {isSubmitting ? "Processando..." : "Solicitar Saque"}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className="text-2xl font-bold text-[#e1ff01]">Defina sua chave PIX</h2>
                            <p className="text-base font-medium" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Para realizar uma solicitação de saque, é necessário configurar uma chave PIX.
                            </p>
                            <div className="flex flex-col w-full">
                                <label
                                    className="text-[#f1f1f1] text-sm font-bold mb-2"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                >
                                    Chave Pix
                                </label>
                                <input
                                    type="text"
                                    value={pixKey}
                                    onChange={(e) => setPixKey(e.target.value)}
                                    placeholder="Insira sua chave PIX"
                                    className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                        border: theme === "dark" ? "#575560" : "#DDDDDD",
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                />
                            </div>
                            <button
                                onClick={handleSetPixKey}
                                disabled={isSubmitting || !pixKey}
                                className="bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl w-full mt-4 transition-all hover:bg-[#d1e600]"
                            >
                                {isSubmitting ? "Salvando..." : "Definir chave PIX"}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
