import React, { useState, useEffect } from "react";
import { Menu } from "../../components/Menu";
import { FaArrowRightLong } from "react-icons/fa6";
import precos from "../../imagens/precos.png";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom"; 
const Feature = ({ text, icon }) => (
    <div className="inline-flex items-center gap-2.5">
        <img className="w-5 h-5" alt="Icon" src={icon} />
        <p className="text-white text-base font-medium">{text}</p>
    </div>
);



const PlanCard = ({ title, description, price, features, isPopular, bgColor, borderColor }) => {
    const navigate = useNavigate();

    const handlePayment = () => {
        navigate("/pagamento", { state: { title, price } }); // Redireciona com os dados
    };

    return (
        <div className={`flex flex-col items-center gap-5 rounded-[40px] p-10 w-96 ${bgColor} border border-solid ${borderColor} flex-grow`}>
            {isPopular && (
                <div className="bg-[#e1ff01] text-[#22252a] text-xs font-bold rounded-[39px] px-4 py-1">
                    Mais vendido
                </div>
            )}
            <div className="text-[#e1ff01] text-xl font-bold">{title}</div>
            <p className="text-white text-center text-base font-medium max-w-xs">{description}</p>
            <div className="text-5xl text-[#e1ff01] font-semibold">
                R${price} <span className="text-2xl text-white">/mês</span>
            </div>
            <div className="mt-4">
                <button
                    onClick={handlePayment}
                    className={`px-6 w-[314px] py-2 ${
                        isPopular ? "bg-[#E1FF01] text-[#22252A]" : "bg-[#22252A] border border-[#706D85] text-white"
                    } font-bold rounded-[36px] flex items-center justify-center gap-2 transition-colors ${
                        isPopular ? "hover:bg-[#a8bf00]" : "hover:bg-[#333]"
                    }`}
                >
                    <span>Pagar agora</span>
                    <FaArrowRightLong className={isPopular ? "text-[#22252A]" : "text-[#FFFFFF]"} />
                </button>
            </div>
            <div className="flex flex-col items-start gap-6 w-full mt-6 space-y-4">
                {features.map((feature, index) => (
                    <Feature key={index} text={feature.text} icon={feature.icon} />
                ))}
            </div>
        </div>
    );
};

export const Precos = (): JSX.Element => {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await fetch(`${API_URL}/api/plans`);
                if (response.ok) {
                    const data = await response.json();
                    const normalizedPlans = [
                        {
                            title: "Plano Básico",
                            description: data.basic.descricao,
                            price: data.basic.price.replace("R$", "").trim(),
                            features: data.basic.features.map((text) => ({
                                text,
                                icon: "https://c.animaapp.com/958aD3IT/img/icon-20.svg",
                            })),
                            bgColor: "bg-[rgba(48,47,57,0.25)]",
                            borderColor: "border-[#575561]",
                        },
                        {
                            title: "Plano Intermediário",
                            description: data.intermediate.descricao,
                            price: data.intermediate.price.replace("R$", "").trim(),
                            features: data.intermediate.features.map((text) => ({
                                text,
                                icon: "https://c.animaapp.com/958aD3IT/img/icon-12.svg",
                            })),
                            isPopular: true,
                            bgColor: "bg-[rgba(12,12,12,0.25)]",
                            borderColor: "border-[#e1ff01]",
                        },
                        {
                            title: "Plano Corporativo",
                            description: data.corporate.descricao,
                            price: data.corporate.price.replace("R$", "").trim(),
                            features: data.corporate.features.map((text) => ({
                                text,
                                icon: "https://c.animaapp.com/958aD3IT/img/icon-20.svg",
                            })),
                            bgColor: "bg-[rgba(48,47,57,0.25)]",
                            borderColor: "border-[#575561]",
                        },
                    ];
                    setPlans(normalizedPlans);
                } else {
                    console.error("Erro ao buscar os planos.");
                }
            } catch (error) {
                console.error("Erro ao buscar os planos:", error);
            }
        };

        fetchPlans();
    }, []);

    return (
        <div className="relative w-full min-h-screen bg-[#1b2026] overflow-hidden">
            <Menu />
            <div className="flex flex-col items-center py-10 px-4 sm:px-10 md:px-20 lg:px-32">
                <img
                    src={precos}
                    alt="Nossos Planos"
                    className="mb-10 w-[480px] h-auto"
                />
                <div className="flex flex-wrap justify-center gap-10">
                    {plans.map((plan, index) => (
                        <PlanCard key={index} {...plan} />
                    ))}
                </div>
            </div>
        </div>
    );
};
