import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiCalendar } from "react-icons/fi";
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const RelatorioPromoter = (): JSX.Element => {
    const { id: event_id } = useParams<{ id: string }>();

    const { id: urlPromoterId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [promoterData, setPromoterData] = useState<{
        nome: string;
        created_at: string;
        media_vendas_diarias: string;
        total_ingressos_vendidos: number;
        total_presencas_confirmadas: number;
        total_vendas: string;
        daily_data: { day: string; men: number; women: number }[];
        eventos: { event_name: string; category: string; event_date: string }[]; // Altere aqui para "eventos"
    }>({
        nome: '',
        created_at: '',
        media_vendas_diarias: '',
        total_ingressos_vendidos: 0,
        total_presencas_confirmadas: 0,
        total_vendas: '',
        daily_data: [],
        eventos: [] // Altere aqui para "eventos"
    });



    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    const { theme } = useTheme();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const role = localStorage.getItem("role");
        const localPromoterId = localStorage.getItem("promoterId");

        // Verifica se o usuário é promoter e se o id da URL corresponde ao id do localStorage
        if (role === "promoter" && localPromoterId && localPromoterId !== urlPromoterId) {
            navigate(`/promoters/relatorio/${localPromoterId}`);
            window.location.reload();
        }
    }, [urlPromoterId, navigate]);

    const fetchPromoterData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const promoterId = event_id;
            if (!companyId || !promoterId || !selectedDate) {
                toast.error("Informações de promotor, empresa ou data estão faltando.");
                return;
            }

            const month = selectedDate.getMonth() + 1;
            const year = selectedDate.getFullYear();

            const response = await fetch(`${API_URL}/api/promoter-info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    company_id: companyId,
                    promoter_id: promoterId,
                    month,
                    year
                }),
            });

            if (!response.ok) {
                throw new Error("Erro ao buscar informações do promotor.");
            }

            const data = await response.json();
            setPromoterData(data);
        } catch (error) {
            console.error(error);
            toast.error("Erro ao carregar dados do promotor.");
        }
    };

    useEffect(() => {
        fetchPromoterData();
    }, [selectedDate]);


    const formatEventDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleDateString('en-US', { month: 'short' }); // Obtém o mês abreviado em inglês
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };


    const chartData = {
        labels: promoterData?.daily_data.map(item => {
            const date = new Date(selectedDate!.getFullYear(), selectedDate!.getMonth(), parseInt(item.day));
            return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
        }) || [],
        datasets: [
            {
                label: "Homens",
                data: promoterData?.daily_data.map(item => item.men) || [],
                borderColor: theme === "dark" ? "#FFFFFF" : "#000000",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderWidth: 2,
                tension: 0.4,
            },
            {
                label: "Mulheres",
                data: promoterData?.daily_data.map(item => item.women) || [],
                borderColor: "#E1FF01",
                backgroundColor: "rgba(225, 255, 1, 0.1)",
                borderWidth: 2,
                tension: 0.4,
            },
        ],
    };


    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                titleColor: "#fff",
                bodyColor: "#fff",
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: theme === "dark" ? "#FFFFFF" : "#000000",
                },
            },
            y: {
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                    color: theme === "dark" ? "#FFFFFF" : "#000000",
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);

        if (date) {
            const selectedMonth = date.getMonth();
            const selectedYear = date.getFullYear();

        }
    };

    const formatRegistrationDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };
    return (
        <div className="flex flex-col lg:flex-row h-screen overflow-hidden"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6 flex flex-col lg:flex-row items-center lg:items-start justify-between">
                    <div className="text-left w-full">
                        <h1
                            className="font-bold [font-family:'Poppins',Helvetica] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Relatório do Promoter
                        </h1>

                        <div className="flex flex-wrap items-center mt-2 space-x-4">
                            <span
                                className="text-2xl font-bold"
                                style={{ color: "#E1FF01" }}
                            >
                                {promoterData ? promoterData.nome : "Carregando..."}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="text-sm font-medium"
                    style={{
                        color: theme === "dark" ? "#F1F1F1" : "#545454",
                    }}
                >
                    {promoterData ? `Data de Cadastro: ${formatRegistrationDate(promoterData.created_at)}` : "Carregando data..."}
                </div>

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="flex flex-col lg:flex-row w-full justify-center items-center gap-4 mt-2">
                    {[
                        { label: "Média de vendas diárias", value: promoterData ? promoterData.media_vendas_diarias : "Carregando..." },
                        { label: "Total ingressos vendidos", value: promoterData ? promoterData.total_ingressos_vendidos : "Carregando..." },
                        { label: "Total presenças confirmadas", value: promoterData ? promoterData.total_presencas_confirmadas : "Carregando..." },
                        { label: "Total de vendas", value: promoterData ? `R$ ${promoterData.total_vendas}` : "Carregando..." }
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[420px] text-left"
                            style={{
                                backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                            }}
                        >
                            <div className="flex items-center mb-2">
                                <p className="text-[#E1FF01] font-medium text-sm whitespace-nowrap">
                                    {item.label}
                                </p>
                            </div>
                            <span
                                className="text-2xl font-bold whitespace-nowrap"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    fontSize: "clamp(1rem, 2vw, 1.5rem)", // fonte responsiva com clamp
                                }}
                            >
                                {item.value}
                            </span>
                        </div>
                    ))}
                </div>


                <div className="mt-6 p-4 rounded-lg border border-solid border-[#575560]" style={{ height: '300px', overflow: 'hidden' }}>
                    <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-4 space-y-2 sm:space-y-0">
                        <h2 className="text-[#E1FF01] font-bold text-lg">
                            Presenças confirmadas no mês
                        </h2>

                        <div className="flex items-center space-x-2 sm:space-x-4">
                            <div className="flex items-center space-x-1">
                                <span
                                    className="w-2.5 h-2.5 sm:w-3 sm:h-3 bg-[#FFFFFF] rounded-full inline-block"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                ></span>
                                <span
                                    className="text-[#FFFFFF] font-medium text-xs sm:text-sm"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                >
                                    Homens
                                </span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-2.5 h-2.5 sm:w-3 sm:h-3 bg-[#E1FF01] rounded-full inline-block"></span>
                                <span className="text-[#E1FF01] font-medium text-xs sm:text-sm">Mulheres</span>
                            </div>
                        </div>

                        <div className="flex items-center space-x-2 sm:space-x-4 mt-2 sm:mt-0">
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                customInput={
                                    <button
                                        className="flex items-center justify-center px-3 py-1.5 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-medium"
                                        style={{
                                            backgroundColor: "#E1FF01",
                                            color: "#22252A",
                                        }}
                                    >
                                        <FiCalendar className="mr-1 sm:mr-2" size={16} />
                                        {selectedDate
                                            ? selectedDate.toLocaleDateString("pt-BR", {
                                                month: "long",
                                                year: "numeric",
                                            })
                                            : "Selecionar Mês"}
                                    </button>
                                }
                            />
                        </div>
                    </div>
                    <div className="w-full" style={{ height: 'calc(100% - 50px)' }}>
                        <Line
                            data={chartData}
                            options={{
                                ...chartOptions,
                                maintainAspectRatio: false,
                            }}
                        />
                    </div>
                </div>



                <div className="mt-6 p-4 overflow-x-auto w-full rounded-lg border border-solid border-[#575560]">
                    <div className="flex justify-between items-center mb-4 ">
                        <h2 className="text-[#E1FF01] font-bold text-lg">Eventos promovidos </h2>
                    </div>
                    <div className="min-w-full h-full bg-transparent text-center">
                        <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr className="bg-[#302F38]" style={{
                                    backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                }}>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm rounded-tl-lg rounded-bl-lg w-1/4" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Evento</th>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Categorias</th>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4 rounded-tr-lg rounded-br-lg" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Data do evento</th>
                                </tr>
                            </thead>
                        </table>
                        <div
                            style={{
                                maxHeight: '55px',
                                overflowY: 'auto',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                            className="w-full"
                        >
                            <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {[
                                        ...new Map(
                                            promoterData?.eventos
                                                .sort((a, b) => new Date(b.event_date).getTime() - new Date(a.event_date).getTime())
                                                .map(evento => [evento.event_name + evento.event_date, evento])
                                        ).values(),
                                    ].map((evento, index) => (
                                        <tr key={index} className="border-b border-[#575560]">
                                            <td className="p-2 sm:p-3 text-[#F1F1F1] text-xs font-bold sm:text-sm" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{evento.event_name}</td>
                                            <td className="p-2 sm:p-3 text-[#E1FF01] text-xs font-bold sm:text-sm">{evento.category}</td>
                                            <td className="p-2 sm:p-3 text-[#F1F1F1] text-xs font-bold sm:text-sm" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{formatEventDate(evento.event_date)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};
