import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { FaTrashAlt, FaLink, FaArrowRight } from 'react-icons/fa';

interface Promoter {
    id: number;
    full_name: string;
    email: string;
    created_at: string;
    total_sales?: number;
    revenue_generated?: number;
    token: string;
    companyName: string;
}

export const Promoter = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [promoters, setPromoters] = useState<Promoter[]>([]);
    const navigate = useNavigate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { theme } = useTheme();

    useEffect(() => {
        const fetchPromoters = async () => {
            const company_id = localStorage.getItem("companyId");

            if (!company_id) {
                toast.error("Company ID não encontrado.");
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/promoters-list?company_id=${company_id}`);
                if (!response.ok) {
                    throw new Error("Erro ao buscar os dados dos promoters");
                }

                const data: Promoter[] = await response.json();
                console.log("Promoters data:", data);
                setPromoters(data);
            } catch (error) {
                console.error(error);
                toast.error("Erro ao buscar dados dos promoters.");
            }
        };

        fetchPromoters();
    }, []);

    const handleDelete = async (id: number) => {
        try {
            const response = await fetch(`${API_URL}/api/${id}/delete`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Erro ao deletar o promoter');
            }
            toast.success('Promoter deletado com sucesso');
            setPromoters((prevPromoters) => prevPromoters.filter((promoter) => promoter.id !== id));
        } catch (error) {
            console.error(error);
            toast.error('Erro ao deletar promoter');
        }
    };

    const handleCopyURL = (companyName: string, token: string) => {
        if (!companyName || !token) {
            toast.error("Informações do promoter incompletas.");
            return;
        }
        const formattedCompanyName = companyName.replace(/\s+/g, '-');
        const url = `${window.location.origin}/eventos/${encodeURIComponent(formattedCompanyName)}/${token}`;
        navigator.clipboard.writeText(url);
        toast.success("URL copiada para a área de transferência!");
    };

    const handleApproveRedirect = () => {
        navigate("/promoters/aprovar");
    };

    const handleViewReport = (id: number) => {
        navigate(`/promoters/relatorio/${id}`);
    };


    return (
        <div className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6 flex items-center justify-between">
                    <div>
                        <h1
                            className="font-bold [font-family:'Poppins',Helvetica] text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Promoters
                        </h1>
                        <p
                            className="text-xs sm:text-xs md:text-sm lg:text-base xl:text-base font-medium mt-2 [font-family:'Poppins',Helvetica] leading-tight"
                            style={{
                                color: theme === "dark" ? "#ffffff66" : "#545454",
                            }}
                        >
                            Monitore o desempenho e cadastros de cada promoter.
                        </p>
                    </div>
                    <button
                        onClick={handleApproveRedirect}
                        className="flex items-center border border-[#575560] justify-center rounded-full bg-[#302F38] text-[#F1F1F1] py-2 px-3 text-xs sm:text-sm md:text-base font-medium hover:bg-[#42404a] transition-all whitespace-nowrap"
                    >
                        Aprovar promotores
                        <span className="ml-2">➔</span>
                    </button>
                </div>

                <img className="w-full h-3 object-cover mb-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <p className="font-semibold mb-4 [font-family:'Poppins',Helvetica] text-[#E1FF01] text-sm sm:text-base md:text-lg lg:text-xl whitespace-nowrap">
                    Promoters Cadastrados
                </p>

                <div className="overflow-x-auto w-full">
                    <table className="min-w-full bg-transparent border-separate border-spacing-0 text-center">
                        <thead>
                            <tr className="bg-[#302F38]" style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                            }}>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm rounded-tl-lg rounded-bl-lg" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Promoter</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Total de vendas</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Receita Gerada</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm rounded-tr-lg rounded-br-lg text-center" style={{ width: "200px", color: theme === "dark" ? "#F1F1F1" : "#000000", }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {promoters.map((promoter, index) => (
                                <tr key={promoter.id} className="text-[#F1F1F1]">
                                    <td className="p-4 text-sm font-bold text-center" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>{promoter.full_name}</td>
                                    <td className="p-4 text-sm font-bold text-center" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>
                                        {promoter.total_sales || 0} {promoter.total_sales === 1 ? "venda" : "vendas"}
                                    </td>
                                    <td className="p-4 text-sm text-center text-[#E1FF01] font-bold">
                                        R$ {promoter.revenue_generated?.toFixed(2) || "0.00"}
                                    </td>
                                    <td className="p-4 flex gap-2 justify-center items-center">
                                        <button
                                            className="flex items-center justify-center w-10 h-10 rounded-full bg-[#333] hover:bg-[#444] text-[#F1F1F1] transition"
                                            onClick={() => handleDelete(promoter.id)}
                                        >
                                            <FaTrashAlt />
                                        </button>
                                        <button
                                            className="flex items-center gap-2 px-4 py-2 border border-[#539CFF] rounded-full text-[#F1F1F1] text-sm font-medium transition hover:bg-[#539CFF] whitespace-nowrap"
                                            onClick={() => handleCopyURL(promoter.companyName, promoter.token)}
                                            style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}
                                        >
                                            <FaLink />
                                            Copiar URL
                                        </button>
                                        <button
                                            className="flex items-center gap-2 px-4 py-2 rounded-full border border-[#E1FF01] text-[#E1FF01] text-sm font-medium transition hover:bg-[#E1FF01] hover:text-[#22252A] whitespace-nowrap"
                                            onClick={() => handleViewReport(promoter.id)} // Alterado para usar o `id` ao invés do `token`
                                        >
                                            Ver relatório
                                            <FaArrowRight />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
