import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { ModalSaque } from "../../components/ModalSaque";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";
import { FiCalendar } from "react-icons/fi";
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

interface DailyRevenue {
    date: string;
    revenue_men: string;
    revenue_women: string;
    total_revenue: string;
}

interface WithdrawRequest {
    user_name: number;
    amount: string;
    status: string;
    request_date: string;
}


export const Saldo = (): JSX.Element => {
    const { id: event_id } = useParams<{ id: string }>();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const { theme } = useTheme();
    const [balance, setBalance] = useState("0.00");
    const [withdrawStats, setWithdrawStats] = useState({
        total_requests: 0,
        total_rejected: 0,
        total_accepted: 0
    });
    const [totalRevenue, setTotalRevenue] = useState("0.00");

    const [dailyRevenue, setDailyRevenue] = useState<DailyRevenue[]>([]);
    const [withdrawRequests, setWithdrawRequests] = useState<WithdrawRequest[]>([]);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const handleModal2Open = () => {
        setIsModal2Open(true);
    };

    const handleModal2Close = () => {
        setIsModal2Open(false);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if (date) {
            const month = date.getMonth() + 1; // Janeiro é 0
            const year = date.getFullYear();
            fetchData(month, year);
        } else {
            fetchData();
        }
    };

    const fetchData = async (month?: number, year?: number) => {
        try {
            const company_id = localStorage.getItem('companyId');

            if (!company_id) {
                toast.error("ID da empresa não encontrado no localStorage");
                return;
            }

            let url = `${API_URL}/api/participations/total-revenue?company_id=${company_id}`;
            if (month) url += `&month=${month}`;
            if (year) url += `&year=${year}`;

            const response = await fetch(url);
            const data = await response.json();

            if (response.ok) {
                setBalance(data.balance);
                setWithdrawStats(data.withdrawStats);
                setTotalRevenue(data.totalRevenue.total);

                const sortedWithdrawRequests = data.withdrawRequests.sort((a, b) =>
                    new Date(b.request_date).getTime() - new Date(a.request_date).getTime()
                );
                setWithdrawRequests(sortedWithdrawRequests);
            } 
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
            toast.error("Erro ao buscar dados do servidor");
        }
    };


    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div className="flex flex-col lg:flex-row h-screen overflow-hidden"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6 flex flex-col items-start gap-4 lg:flex-row lg:items-center lg:justify-between">
                    <div className="text-left w-full">
                        <h1
                            className="font-semibold [font-family:'Poppins',Helvetica] text-sm"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Saldo Disponível
                        </h1>

                        <div className="flex flex-wrap items-center mt-2 space-x-4">
                            <span
                                className="text-3xl font-bold"
                                style={{ color: "#E1FF01" }}
                            >
                                R$ {parseFloat(balance).toFixed(2)}
                            </span>
                        </div>
                    </div>

                    <button
                        className="bg-[#E1FF01] text-[#22252A] rounded-full px-4 py-2 font-bold text-sm lg:text-base lg:self-start"
                        style={{
                            minWidth: "190px",
                            height: "auto"
                        }}
                        onClick={handleModal2Open}
                    >
                        Solicitar novo saque
                    </button>
                </div>


                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="flex flex-col lg:flex-row w-full justify-center items-center gap-4 mt-2">
                    {[
                        { label: "Total de solicitações", value: withdrawStats.total_requests },
                        { label: "Solicitações recusadas", value: withdrawStats.total_rejected },
                        { label: "Solicitações aprovadas", value: withdrawStats.total_accepted },
                        { label: "Valor das vendas antecipadas", value: `R$ ${parseFloat(totalRevenue).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[420px] text-left"
                            style={{
                                backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                            }}
                        >
                            <div className="flex items-center mb-2">
                                <p className="text-[#E1FF01] font-medium text-sm whitespace-nowrap">
                                    {item.label}
                                </p>
                            </div>
                            <span
                                className="text-2xl font-bold whitespace-nowrap"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    fontSize: "clamp(1rem, 2vw, 1.5rem)", // fonte responsiva com clamp
                                }}
                            >
                                {item.value}
                            </span>
                        </div>
                    ))}
                </div>



                <div className="mt-6 p-4 overflow-x-auto w-full rounded-lg border border-solid border-[#575560]">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-[#E1FF01] font-bold text-lg">Histórico de Saques</h2>

                        <div className="flex justify-end w-full lg:w-auto">
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                customInput={
                                    <button className="flex items-center justify-center px-3 py-1.5 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-medium"
                                        style={{
                                            backgroundColor: "#E1FF01",
                                            color: "#22252A",
                                        }}
                                    >
                                        <FiCalendar className="mr-1 sm:mr-2" size={16} />
                                        {selectedDate ? selectedDate.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' }) : "Selecionar Mês"}
                                    </button>
                                }
                            />
                        </div>
                    </div>
                    <div className="min-w-full h-full bg-transparent text-center">
                        <div className="w-full">
                            <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr className="bg-[#302F38]" style={{
                                        backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                    }}>
                                        <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs rounded-tl-lg rounded-bl-lg sm:text-sm w-1/4" style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}>Usuário</th>
                                        <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4" style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}>Valor</th>
                                        <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4" style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}>Status</th>
                                        <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] rounded-tr-lg rounded-br-lg text-xs sm:text-sm w-1/4" style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}>Data da Solicitação</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                        <div style={{
                            maxHeight: '460px',
                            overflowY: 'scroll',
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none'
                        }}
                            className="scrollbar-hidden">
                            <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {withdrawRequests.map((request, index) => (
                                        <tr key={index} className="border-b border-[#575560]">
                                            <td className="p-2 sm:p-3 font-bold text-[#F1F1F1] text-xs sm:text-sm" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{request.user_name}</td>
                                            <td className="p-2 sm:p-3 font-bold text-xs sm:text-sm">
                                                <span style={{ color: "#E1FF01" }}>R$</span>{" "}
                                                <span style={{
                                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                                }}>
                                                    {parseFloat(request.amount).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </span>
                                            </td>
                                            <td className="p-2 sm:p-3 text-xs font-bold sm:text-sm">
                                                <span
                                                    style={{
                                                        color: request.status === 'pending' ? '#1E90FF' :
                                                            request.status === 'accepted' ? '#E1FF01' :
                                                                request.status === 'rejected' ? '#F50058' : '#FFFFFF'
                                                    }}
                                                >
                                                    {request.status === 'pending' ? 'Pendente' :
                                                        request.status === 'accepted' ? 'Aprovado' :
                                                            request.status === 'rejected' ? 'Recusado' : ''}
                                                </span>
                                            </td>
                                            <td className="p-2 sm:p-3 font-bold text-[#F1F1F1] text-xs sm:text-sm" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>
                                                {new Date(request.request_date).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                }).replace(/\./g, '')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>


            </div>

            <ModalSaque isOpen={isModal2Open} onClose={handleModal2Close} fetchData={fetchData} />
        </div>
    );
};
