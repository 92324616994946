import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Promoter {
    id: number;
    full_name: string;
    email: string;
    created_at: string;
}

export const AprovarPromoter = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [promoters, setPromoters] = useState<Promoter[]>([]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { theme } = useTheme();

    useEffect(() => {
        const fetchPromoters = async () => {
            try {
                const companyId = localStorage.getItem('companyId');
                if (!companyId) {
                    throw new Error('companyId não encontrado no localStorage');
                }

                const response = await fetch(`${API_URL}/api/promoters?company_id=${companyId}`);
                if (!response.ok) {
                    throw new Error('Erro ao buscar promoters');
                }
                const data = await response.json();
                setPromoters(data);
                console.log("Promoters carregados:", data); // Log para verificar se a lista de promoters é carregada
            } catch (error) {
                console.error(error);
                toast.error("Erro ao carregar lista de promoters.");
            }
        };

        fetchPromoters();
    }, []);

    const approvePromoter = async (id: number) => {
        try {
            const response = await fetch(`${API_URL}/api/${id}/approve`, { method: 'POST' });
            if (!response.ok) {
                throw new Error('Erro ao aprovar promoter');
            }
            const result = await response.json();
            setPromoters(promoters.filter((promoter) => promoter.id !== id));
            toast.success("Promoter aprovado com sucesso.");
        } catch (error) {
            console.error(error);
            toast.error("Erro ao aprovar promoter.");
        }
    };

    const rejectPromoter = async (id: number) => {
        try {
            const response = await fetch(`${API_URL}/api/${id}/reject`, { method: 'POST' });
            if (!response.ok) {
                throw new Error('Erro ao recusar promoter');
            }
            const result = await response.json();
            setPromoters(promoters.filter((promoter) => promoter.id !== id));
            toast.warn("Promoter recusado com sucesso.");
        } catch (error) {
            console.error(error);
            toast.error("Erro ao recusar promoter.");
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6">
                    <h1 className="font-bold [font-family:'Poppins',Helvetica] text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl whitespace-nowrap" style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}>
                        Aprovar promoters
                    </h1>
                    <p className="text-xs sm:text-xs md:text-sm lg:text-base xl:text-base font-medium mt-2 [font-family:'Poppins',Helvetica] leading-tight" style={{
                        color: theme === "dark" ? "#ffffff66" : "#545454",
                    }}>
                        Aprove o cadastro de promoters para seus eventos.
                    </p>
                </div>

                <img className="w-full h-3 object-cover mb-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <p className="font-semibold mb-4 [font-family:'Poppins',Helvetica] text-[#E1FF01] text-sm sm:text-base md:text-lg lg:text-xl whitespace-nowrap">
                    Promoters aguardando aprovação
                </p>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-transparent border-separate border-spacing-0 text-center">
                        <thead>
                            <tr className="bg-[#302F38]" style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                            }}>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm rounded-tl-lg rounded-bl-lg" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Promoter</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Data de cadastro</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Email</th>
                                <th className="p-3 font-medium text-[#F1F1F1] text-sm rounded-tr-lg rounded-br-lg" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {promoters.map((promoter, index) => (
                                <tr key={promoter.id} className={`text-[#F1F1F1] ${index === promoters.length - 1 ? 'rounded-b-lg' : ''}`} style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>
                                    <td className="p-4 text-sm font-bold text-center">{promoter.full_name}</td>
                                    <td className="p-4 text-sm font-bold text-center">
                                        {new Date(promoter.created_at).toLocaleDateString('pt-BR', {
                                            day: '2-digit',
                                            month: 'short',
                                            year: 'numeric'
                                        }).replace('.', '')}
                                    </td>

                                    <td className="p-4 text-sm font-bold text-center">{promoter.email}</td>
                                    <td className="p-4 flex gap-2 justify-center">
                                        <button
                                            className="bg-[#333] text-white px-4 py-2 rounded-full text-sm font-medium transition hover:bg-[#444] flex items-center justify-center"
                                            onClick={() => rejectPromoter(promoter.id)}
                                        >
                                            Recusar
                                        </button>
                                        <button
                                            className="bg-[#E1FF01] text-[#22252A] px-4 py-2 rounded-full text-sm font-bold transition hover:bg-[#b8dd01] flex items-center justify-center"
                                            onClick={() => approvePromoter(promoter.id)}
                                        >
                                            Aprovar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
};
