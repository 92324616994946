import React, { useState, useEffect, useContext } from "react";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { Sidebar } from "../../components/Sidebar";
import { ModalCadastro } from "../../components/ModalCadastro";
import { ModalConvidado } from "../../components/ModalConvidado";
import { useTheme } from "../../ThemeContext";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp, FaEllipsisH, FaPlus } from 'react-icons/fa';

interface Event {
  id: number;
  event_image_url: string;
  event_date: string;
  event_name: string;
}

interface Birthday {
  id: number;
  name: string;
  whatsapp: string;
  event_image_url: string;
  event_date: string;
  created_at: string;
}

export const Eventos = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState("eventos");
  const { theme } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedBirthday, setSelectedBirthday] = useState<Birthday | null>(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [events, setEvents] = useState<Event[]>([]);
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEvents = async () => {
    const companyId = localStorage.getItem("companyId");
    if (!companyId) {
      setError("ID da empresa não encontrado.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/list-events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ company_id: companyId }),
      });

      if (!response.ok) {
        throw new Error("Erro ao buscar eventos.");
      }

      const data: Event[] = await response.json();
      setEvents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar aniversariantes
  const fetchBirthdays = async () => {
    const companyId = localStorage.getItem("companyId");
    if (!companyId) {
      setError("ID da empresa não encontrado.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/list-birthdays/${companyId}`);

      if (!response.ok) {
        throw new Error("Erro ao buscar aniversariantes.");
      }

      const data = await response.json();

      // Verifique se data.birthdays existe e é um array
      if (Array.isArray(data.birthdays)) {
        setBirthdays(data.birthdays);
      } else {
        console.error("Formato de dados inesperado:", data);
        setError("Erro ao processar os aniversariantes.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "eventos") {
      fetchEvents();
    } else if (activeTab === "aniversariantes") {
      fetchBirthdays();
    }
  }, [activeTab]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModal2Open = () => {
    setIsModal2Open(true);
  };

  const handleModal2Close = () => {
    setIsModal2Open(false);
  };

  const [currentPage, setCurrentPage] = useState(1); 
  const [currentPageBirthdays, setCurrentPageBirthdays] = useState(1);
  const itemsPerPage = window.innerWidth < 640 ? 1 : 4;

  const totalPagesEvents = Math.ceil(events.length / itemsPerPage);
  const totalPagesBirthdays = Math.ceil(birthdays.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPagesEvents) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPageBirthdays = () => {
    if (currentPageBirthdays < totalPagesBirthdays) {
      setCurrentPageBirthdays(currentPageBirthdays + 1);
    }
  };
  const prevPageBirthdays = () => {
    if (currentPageBirthdays > 1) {
      setCurrentPageBirthdays(currentPageBirthdays - 1);
    }
  };

  const indexOfLastEventItem = currentPage * itemsPerPage;
  const indexOfFirstEventItem = indexOfLastEventItem - itemsPerPage;
  const currentEvents = events.slice(indexOfFirstEventItem, indexOfLastEventItem);

  const indexOfLastBirthdayItem = currentPageBirthdays * itemsPerPage;
  const indexOfFirstBirthdayItem = indexOfLastBirthdayItem - itemsPerPage;
  const currentBirthdays = birthdays.slice(indexOfFirstBirthdayItem, indexOfLastBirthdayItem);



  return (
    <div className="flex flex-col lg:flex-row h-screen overflow-hidden" style={{
      backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
    }}>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <Header />
      <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full mx-auto" style={{ maxWidth: "100%" }}>
        <header className="w-full h-[134px] mb-6">
          <div className="w-full relative">
            <div className="absolute w-full h-16 top-[50px] left-[-15px]">
              <img
                className="absolute w-full h-3 top-11 left-0 object-cover"
                alt="Divisor"
                src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg"
              />

              <div
                className={`inline-flex items-center justify-center gap-2.5 p-5 absolute top-0 left-0 cursor-pointer transition-colors ${activeTab === "eventos" ? "text-[#E1FF01]" : theme === "dark" ? "text-[#F1F1F1]" : "text-[#000000]"
                  } hover:text-[#E1FF01]`}
                onClick={() => handleTabChange("eventos")}
              >
                <div className="relative w-fit font-bold text-base">Eventos</div>
              </div>

              <div
                className={`inline-flex items-center justify-center gap-2.5 p-5 absolute top-0 left-[103px] cursor-pointer transition-colors ${activeTab === "aniversariantes" ? "text-[#E1FF01]" : theme === "dark" ? "text-[#F1F1F1]" : "text-[#000000]"
                  } hover:text-[#E1FF01]`}
                onClick={() => handleTabChange("aniversariantes")}
              >
                <div className="relative w-fit font-bold text-base">Aniversariantes</div>
              </div>
            </div>

            <div className="flex w-full items-end justify-between absolute top-0 left-0">
              <div className="flex flex-col w-[155px] items-start gap-2.5">
                <div
                  className={`relative w-fit font-bold ${activeTab === 'aniversariantes' ? 'text-2xl' : 'text-4xl'}`}
                  style={{
                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                  }}
                >
                  {activeTab === "eventos" ? "Eventos" : "Aniversariantes"}
                </div>

              </div>

              <div className="inline-flex items-center gap-5">
                <button
                  className={`inline-flex ${activeTab === 'aniversariantes' ? 'h-[28px] p-4' : 'h-[37px] p-5'} border-none items-center justify-center gap-2.5 bg-[#e1ff01] rounded-[62px] cursor-pointer hover:bg-[#b8cc00] transition-all`}
                  onClick={() => {
                    if (activeTab === "eventos") {
                      navigate('/eventos/criar');
                    } else if (activeTab === "aniversariantes") {
                      handleModalOpen();
                    }
                  }}
                >
                  <FaPlus />
                  <div className="relative w-fit font-bold text-[#302e38] text-sm">
                    {activeTab === "eventos" ? "Novo evento" : "Novo aniversariante"}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className="relative w-full mx-auto h-[401px]">
          {error && <p>{error}</p>}

          {/* Eventos */}
          {!loading && !error && activeTab === "eventos" && events.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
              {currentEvents.map((event) => (
                <div
                  key={event.id}
                  className="w-full h-[368px] group transition-transform duration-300 transform hover:scale-105 cursor-pointer"
                  onClick={() => navigate(`/eventos/detalhes/${event.id}`)}
                >
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-[310px] object-cover absolute top-0 left-0 transition-all duration-300 rounded-t-3xl"
                      alt="Event"
                      src={`${API_URL}/api${event.event_image_url}`}
                      style={{
                        filter: 'brightness(20%)',
                        transition: 'filter 0.3s ease',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(70%)')}
                      onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(20%)')}
                    />

                    <div className="absolute w-[56px] h-[76px] top-6 right-4">
                      <div className="flex flex-col items-center justify-center bg-[#e1ff01] rounded-xl p-[7.5px]">
                        <p className="font-medium text-black text-lg">
                          {new Date(event.event_date).getDate()}
                        </p>
                        <p className="font-medium text-black text-lg">
                          {new Date(event.event_date).toLocaleString('default', { month: 'short' })}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center bg-white rounded-xl p-[7.5px] mt-[7px]">
                        <p className="font-medium text-black text-lg">
                          {new Date(event.event_date).toLocaleDateString('pt-BR', { weekday: 'short' })}
                        </p>
                      </div>
                    </div>
                    <div className="absolute w-full h-[70px] bg-[#e1ff01] rounded-b-3xl bottom-0 flex items-center justify-center">
                      <p className="w-full px-4 font-bold text-black text-2xl text-center overflow-hidden text-ellipsis whitespace-nowrap">
                        {event.event_name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Paginação para Eventos */}
          {activeTab === "eventos" && totalPagesEvents > 1 && (
            <div className="flex justify-end mt-6">
              <div className="flex gap-2 items-center">
                <button
                  className="px-4 py-2 rounded-full transition-all bg-gray-700 text-white hover:bg-[#b8cc00]"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  &lt;
                </button>

                {currentPage > 2 && (
                  <>
                    <button
                      className={`px-4 py-2 rounded-full transition-all ${currentPage === 1 ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                      onClick={() => setCurrentPage(1)}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className="text-gray-500">...</span>}
                  </>
                )}

                {Array.from({ length: totalPagesEvents }, (_, index) => {
                  const page = index + 1;
                  if (page >= currentPage - 1 && page <= currentPage + 1) {
                    return (
                      <button
                        key={page}
                        className={`px-4 py-2 rounded-full transition-all ${currentPage === page ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                        onClick={() => setCurrentPage(page)}
                      >
                        {page}
                      </button>
                    );
                  }
                  return null;
                })}

                {currentPage < totalPagesEvents - 1 && (
                  <>
                    {currentPage < totalPagesEvents - 2 && <span className="text-gray-500">...</span>}
                    <button
                      className={`px-4 py-2 rounded-full transition-all ${currentPage === totalPagesEvents ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                      onClick={() => setCurrentPage(totalPagesEvents)}
                    >
                      {totalPagesEvents}
                    </button>
                  </>
                )}

                <button
                  className="px-4 py-2 rounded-full transition-all bg-gray-700 text-white hover:bg-[#b8cc00]"
                  onClick={nextPage}
                  disabled={currentPage === totalPagesEvents}
                >
                  &gt;
                </button>
              </div>
            </div>
          )}

          {/* Aniversariantes */}
          {!loading && !error && activeTab === "aniversariantes" && birthdays.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {currentBirthdays.map((birthday) => (
                <div
                  key={birthday.id}
                  className="w-full h-[328px] group transition-transform duration-300 transform hover:scale-105 cursor-pointer"
                  onClick={() => {
                    setSelectedBirthday(birthday);
                    handleModal2Open();
                  }}
                >
                  <div className="relative w-full h-full">
                    <img
                      className="w-full h-[310px] object-cover absolute top-0 left-0 transition-all duration-300 rounded-t-3xl"
                      alt="Aniversariante"
                      src={`${API_URL}/api${birthday.event_image_url}`}
                      style={{
                        filter: 'brightness(20%)',
                        transition: 'filter 0.3s ease',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.filter = 'brightness(70%)')}
                      onMouseLeave={(e) => (e.currentTarget.style.filter = 'brightness(20%)')}
                    />

                    <div className="absolute w-[56px] h-[76px] top-6 right-4">
                      <div className="flex flex-col items-center justify-center bg-[#e1ff01] rounded-xl p-[7.5px]">
                        <p className="font-medium text-black text-lg">
                          {new Date(birthday.event_date).getDate()}
                        </p>
                        <p className="font-medium text-black text-lg">
                          {new Date(birthday.event_date).toLocaleString('default', { month: 'short' })}
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-center bg-white rounded-xl p-[7.5px] mt-[7px]">
                        <p className="font-medium text-black text-lg">
                          {new Date(birthday.event_date).toLocaleDateString('pt-BR', { weekday: 'short' })}
                        </p>
                      </div>
                    </div>
                    <div className="absolute w-full h-[70px] bg-[#e1ff01] rounded-b-3xl bottom-0 flex items-center justify-center">
                      <p className="w-full px-4 font-bold text-black text-2xl text-center overflow-hidden text-ellipsis whitespace-nowrap">
                        {birthday.name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeTab === "aniversariantes" && totalPagesBirthdays > 1 && (
            <div className="flex justify-end mt-6">
              <div className="flex gap-2 items-center">
                <button
                  className="px-4 py-2 rounded-full transition-all bg-gray-700 text-white hover:bg-[#b8cc00]"
                  onClick={prevPageBirthdays}
                  disabled={currentPageBirthdays === 1}
                >
                  &lt;
                </button>

                {currentPageBirthdays > 2 && (
                  <>
                    <button
                      className={`px-4 py-2 rounded-full transition-all ${currentPageBirthdays === 1 ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                      onClick={() => setCurrentPageBirthdays(1)}
                    >
                      1
                    </button>
                    {currentPageBirthdays > 3 && <span className="text-gray-500">...</span>}
                  </>
                )}

                {Array.from({ length: totalPagesBirthdays }, (_, index) => {
                  const page = index + 1;
                  if (page >= currentPageBirthdays - 1 && page <= currentPageBirthdays + 1) {
                    return (
                      <button
                        key={page}
                        className={`px-4 py-2 rounded-full transition-all ${currentPageBirthdays === page ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                        onClick={() => setCurrentPageBirthdays(page)}
                      >
                        {page}
                      </button>
                    );
                  }
                  return null;
                })}

                {currentPageBirthdays < totalPagesBirthdays - 1 && (
                  <>
                    {currentPageBirthdays < totalPagesBirthdays - 2 && <span className="text-gray-500">...</span>}
                    <button
                      className={`px-4 py-2 rounded-full transition-all ${currentPageBirthdays === totalPagesBirthdays ? 'bg-[#e1ff01] text-black font-bold' : 'bg-gray-700 text-white hover:bg-[#b8cc00]'}`}
                      onClick={() => setCurrentPageBirthdays(totalPagesBirthdays)}
                    >
                      {totalPagesBirthdays}
                    </button>
                  </>
                )}

                <button
                  className="px-4 py-2 rounded-full transition-all bg-gray-700 text-white hover:bg-[#b8cc00]"
                  onClick={nextPageBirthdays}
                  disabled={currentPageBirthdays === totalPagesBirthdays}
                >
                  &gt;
                </button>
              </div>
            </div>
          )}
        </div>


      </div>

      <ModalCadastro isOpen={isModalOpen} onClose={handleModalClose} />

      <ModalConvidado
        isOpen={isModal2Open}
        onClose={handleModal2Close}
        birthday={selectedBirthday}
      />
    </div>
  );
};
