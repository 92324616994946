import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { AiOutlineLoading3Quarters, AiOutlineCheckCircle, AiOutlineCopy } from "react-icons/ai";
import { API_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

interface ModalPagamentoTicketProps {
    isOpen: boolean;
    onClose: () => void;
    ticketInfo: {
        name: string;
        cpf: string;
        phone: string;
        gender: string;
        email: string;
        ticketPrice: string;
        eventName: string;
        eventColor: string;
        qrCodeUrl?: string;
    };
    onPaymentApproved: (paymentInfo: {
        qrCodeImage: string;
        qrCodeUrl: string;
        name: string;
        cpf: string;
        gender: string;
        ticketPrice: string;
        eventName: string;
    }) => void;
}


export const ModalPagamentoTicket = ({
    isOpen,
    onClose,
    ticketInfo,
    onPaymentApproved,
}: ModalPagamentoTicketProps): JSX.Element | null => {
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [pixCopyPaste, setPixCopyPaste] = useState<string | null>(null);
    const [paymentId, setPaymentId] = useState<string | null>(null);
    const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
    const [isMonitoring, setIsMonitoring] = useState<boolean>(false);

    const fetchQrCode = async () => {
        try {
            const response = await fetch(`${API_URL}/api/generate-qrcodeticket`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(ticketInfo),
            });

            const data = await response.json();
            if (response.ok) {
                setQrCode(data.qrCode);
                setPixCopyPaste(data.pixCopyPaste);
                setPaymentId(data.paymentId);
                setPaymentStatus(data.status);
                setIsMonitoring(true);
            } else {
                toast.error(data.error || "Erro ao gerar QR Code");
            }
        } catch (error) {
            console.error("Erro ao buscar QR Code:", error);
            toast.error("Erro ao processar a solicitação.");
        }
    };

    const fetchPaymentStatus = async () => {
        if (!paymentId) return;
    
        try {
            // Extrair os tokens da URL
            const pathname = window.location.pathname; 
            const pathSegments = pathname.split("/"); 
            const token = pathSegments.length >= 4 ? pathSegments[3] : null;
            const tokenAniversario = pathSegments.length === 5 ? pathSegments[4] : null; 
    
            let apiUrl = `${API_URL}/api/payment-statusticket/${paymentId}`;
            if (token) apiUrl += `?token=${token}`;
            if (tokenAniversario) apiUrl += token ? `&tokenaniversario=${tokenAniversario}` : `?tokenaniversario=${tokenAniversario}`;
    
            const response = await fetch(apiUrl);
            const data = await response.json();
    
            if (response.ok) {
                setPaymentStatus(data.status);
    
                if (data.message === "Pagamento aprovado") {
                    toast.success("Pagamento aprovado!");
                    setIsMonitoring(false);
                    onClose();
    
                    onPaymentApproved({
                        qrCodeUrl: data.qrCodeUrl,
                        qrCodeImage: data.qrCodeImage,
                        name: ticketInfo.name,
                        cpf: ticketInfo.cpf,
                        gender: ticketInfo.gender,
                        ticketPrice: ticketInfo.ticketPrice,
                        eventName: ticketInfo.eventName,
                    });
                } else if (data.status === "rejected") {
                    toast.error("Pagamento rejeitado.");
                    setIsMonitoring(false);
                }
            } else {
                toast.error(data.error || "Erro ao verificar status do pagamento");
            }
        } catch (error) {
            console.error("Erro ao verificar status do pagamento:", error);
            toast.error("Erro ao verificar status.");
        }
    };
    



    useEffect(() => {
        if (isOpen) {
            fetchQrCode();
        }
    }, [isOpen]);

    useEffect(() => {
        if (isMonitoring && paymentStatus === "pending") {
            const interval = setInterval(fetchPaymentStatus, 5000);
            return () => clearInterval(interval);
        }
    }, [isMonitoring, paymentStatus]);

    const handleCopy = () => {
        if (pixCopyPaste) {
            navigator.clipboard.writeText(pixCopyPaste);
            toast.success("Código Pix copiado para a área de transferência!", {
                position: "top-center",
            });
        }
    };

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <ToastContainer />
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{ backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6" }}
            >
                <IoMdClose
                    size={20}
                    onClick={() => onClose()}
                    className="cursor-pointer transition-colors"
                    style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    {!qrCode && !paymentStatus && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <AiOutlineLoading3Quarters className="animate-spin" size={48} style={{ color: "#e1ff01" }} />
                            <div className="text-[#e1ff01] text-2xl font-bold">Aguardando QR Code</div>
                            <div
                                className="text-base font-medium"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Estamos gerando o QR Code para o pagamento. Por favor, aguarde.
                            </div>
                        </div>
                    )}

                    {qrCode && paymentStatus === "pending" && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <div className="text-[#e1ff01] text-2xl font-bold">Realize seu pagamento</div>
                            <img src={qrCode} alt="QR Code para pagamento" className="w-full h-auto" />
                            <div className="flex items-center gap-2 w-full">
                                <input
                                    type="text"
                                    value={pixCopyPaste || ""}
                                    readOnly
                                    className="flex-1 p-2 rounded border"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        backgroundColor: theme === "dark" ? "#333" : "#fff",
                                    }}
                                />
                                <button onClick={handleCopy} className="p-2 rounded bg-[#e1ff01] text-black">
                                    <AiOutlineCopy size={20} />
                                </button>
                            </div>
                            <div
                                className="text-sm font-medium"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Use o QR Code ou copie o código Pix para realizar o pagamento.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
