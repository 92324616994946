import React, { useState } from 'react';
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { API_URL } from "../../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ModalAddUserProps {
    isOpen: boolean;
    onClose: () => void;
    eventName: string;
    onSuccess: () => void;
}

const maskCpf = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};

const maskPhone = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
};

export const ModalAddUser = ({ isOpen, onClose, eventName, onSuccess }: ModalAddUserProps): JSX.Element | null => {
    const [nomeCompleto, setNomeCompleto] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [genero, setGenero] = useState('');

    const { theme } = useTheme();

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWhatsapp(maskPhone(e.target.value));
    };

    const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCpf(maskCpf(e.target.value));
    };

    const handleSubmit = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error("Por favor, insira um e-mail válido.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/add-client`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: nomeCompleto,
                    cpf: cpf,
                    phone: whatsapp,
                    email: email,
                    gender: genero,
                    event_name: eventName,
                }),
            });

            const result = await response.json();
            if (response.ok) {
                toast.success(result.message);
                onClose();
                onSuccess();
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao enviar os dados.');
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{ backdropFilter: 'blur(5px)' }}
        >
            <ToastContainer />
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{ backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6" }}
            >
                <IoMdClose
                    size={20}
                    onClick={() => onClose()}
                    className="cursor-pointer transition-colors"
                    style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    <div className="flex flex-col items-start gap-2 w-full">
                        <div className="text-[#e1ff01] text-2xl font-bold">Enviar nome na lista</div>
                        <div className="text-base font-medium" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                            Preencha o formulário.
                        </div>
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="text-[#f1f1f1] text-sm font-bold mb-2" htmlFor="nomeCompleto">
                            Nome Completo
                        </label>
                        <input
                            id="nomeCompleto"
                            name="nomeCompleto"
                            type="text"
                            placeholder="Insira o Nome Completo"
                            className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl"
                            value={nomeCompleto}
                            onChange={(e) => setNomeCompleto(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="text-[#f1f1f1] text-sm font-bold mb-2" htmlFor="cpf">
                            CPF
                        </label>
                        <input
                            id="cpf"
                            name="cpf"
                            type="text"
                            placeholder="123.123.123-00"
                            className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl"
                            value={cpf}
                            onChange={handleCpfChange}
                            maxLength={14}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="text-[#f1f1f1] text-sm font-bold mb-2" htmlFor="email">
                            E-mail
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="exemplo@email.com"
                            className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <div className="flex flex-col w-[50%]">
                            <label className="text-[#f1f1f1] text-sm font-bold mb-2" htmlFor="whatsapp">
                                Whatsapp
                            </label>
                            <div className="flex items-center bg-[#42404a] border border-solid border-[#575560] rounded-2xl p-3">
                                <select
                                    className="text-[#E1FF01] bg-transparent outline-none mr-2"
                                    style={{ fontWeight: "bold" }}
                                >
                                    <option value="BR">BR</option>
                                </select>
                                <input
                                    id="whatsapp"
                                    name="whatsapp"
                                    type="text"
                                    placeholder="(11) 12345-6789"
                                    className="w-full bg-transparent text-[#f1f1f1] outline-none"
                                    value={whatsapp}
                                    onChange={handlePhoneChange}
                                    maxLength={15}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col flex-1">
                            <label className="text-[#f1f1f1] text-sm font-bold mb-2" htmlFor="genero">
                                Gênero
                            </label>
                            <select
                                id="genero"
                                name="genero"
                                className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl"
                                value={genero}
                                onChange={(e) => setGenero(e.target.value)}
                            >
                                <option value="">Selecione</option>
                                <option value="male">Masculino</option>
                                <option value="female">Feminino</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flex w-full gap-8">
                    <button
                        className="h-10 flex-1 bg-[#42404a] text-[#f1f1f1] font-bold py-2 px-4 rounded-2xl"
                        onClick={() => onClose()}
                    >
                        Cancelar
                    </button>

                    <button
                        className="h-10 flex-1 bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl"
                        onClick={handleSubmit}
                    >
                        Prosseguir
                    </button>
                </div>
            </div>
        </div>
    );
};
