import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Client {
    id: number;
    name: string;
    phone: string;
    gender: string;
    visits_count: number;
    last_visit: string;
}

export const Clientes = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [clients, setClients] = useState<Client[]>([]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const { theme } = useTheme();

    useEffect(() => {
        const fetchClients = async () => {
            const company_id = localStorage.getItem("companyId");

            if (!company_id) {
                toast.error("Company ID não encontrado.");
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/clients-list?company_id=${company_id}`);
                if (!response.ok) {
                    throw new Error("Erro ao buscar os dados dos clientes");
                }

                const data: Client[] = await response.json();
                setClients(data);
            } catch (error) {
                console.error(error);
                toast.error("Erro ao buscar dados dos clientes.");
            }
        };

        fetchClients();
    }, []);

    const translateGender = (gender: string) => {
        return gender === "male" ? "Masculino" : gender === "female" ? "Feminino" : "Outro";
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "long", year: "numeric" };
        return new Date(dateString).toLocaleDateString("pt-BR", options);
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6">
                    <h1 className="font-semibold mb-4 [font-family:'Poppins',Helvetica] text-[#E1FF01] text-lg sm:text-xl md:text-2xl lg:text-3xl whitespace-nowrap" style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}>
                        Clientes
                    </h1>
                    <p className="text-xs sm:text-xs md:text-sm lg:text-base xl:text-base font-medium mt-2 [font-family:'Poppins',Helvetica] leading-tight" style={{
                        color: theme === "dark" ? "#ffffff66" : "#545454",
                    }}>
                        Registros detalhados dos nossos clientes para oferecer um atendimento personalizado e eficiente.
                    </p>
                </div>

                <img className="w-full h-3 object-cover mb-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="overflow-x-auto w-full mt-5">
                    <div className="min-w-full h-full bg-transparent text-center">
                        <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr
                                    className="bg-[#302F38]"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                    }}
                                >
                                    <th
                                        className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm rounded-tl-lg rounded-bl-lg w-1/4"
                                        style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}
                                    >
                                        Nome completo
                                    </th>
                                    <th
                                        className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4"
                                        style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}
                                    >
                                        WhatsApp
                                    </th>
                                    <th
                                        className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4"
                                        style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}
                                    >
                                        Gênero
                                    </th>
                                    <th
                                        className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4 rounded-tr-lg rounded-br-lg"
                                        style={{
                                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        }}
                                    >
                                        Última Visita
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <div
                            style={{
                                maxHeight: '660px',
                                overflowY: 'auto',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                            className="w-full"
                        >
                            <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {clients.map((client) => (
                                        <tr key={client.id} className="border-b border-[#42404a]">
                                            <td
                                                className="p-4 sm:p-5 text-[#E1E1E1] font-bold text-xs sm:text-sm w-1/4"
                                                style={{
                                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                                }}
                                            >
                                                {client.name}
                                            </td>
                                            <td className="p-4 text-sm font-bold text-center text-[#E1FF01]">{client.phone}</td>
                                            <td
                                                className="p-4 sm:p-5 text-[#E1E1E1] font-bold text-xs sm:text-sm w-1/4"
                                                style={{
                                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                                }}
                                            >
                                                {translateGender(client.gender)}
                                            </td>
                                            <td
                                                className="p-4 sm:p-5 text-center font-bold text-xs sm:text-sm"
                                                style={{
                                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {client.last_visit ? formatDate(client.last_visit) : "N/A"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    );
};
