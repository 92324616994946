import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ModalConfirmar } from "../../components/ModalConfirmar";
import { ModalAntecipado } from "../../components/ModalAntecipado";
import { ModalTicket } from "../../components/ModalTicket";
import { ModalTicketAntecipado } from "../../components/ModalTicketAntecipado";
import { ModalPagamentoTicket } from "../../components/ModalPagamentoTicket";
import { API_URL } from "../../config";

export const ConfirmarAniversario = (): JSX.Element => {
    const { nomefantasia } = useParams<{ nomefantasia: string; token?: string }>();
    const [events, setEvents] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEventName, setSelectedEventName] = useState('');
    const [selectedEventPriceWomen, setSelectedEventPriceWomen] = useState('');
    const [selectedEventPriceMen, setSelectedEventPriceMen] = useState('');
    const [isModalConfirmarOpen, setIsModalConfirmarOpen] = useState(false);
    const [isModalTicketOpen, setIsModalTicketOpen] = useState(false);
    const [selectedTicketInfo, setSelectedTicketInfo] = useState<any>({});
    const [selectedEventColor, setSelectedEventColor] = useState('');

    const handleModalConfirmarOpen = (eventName: string, ticketPriceMen: string, ticketPriceWomen: string, eventColor: string) => {
        setIsModalConfirmarOpen(true);
        setSelectedEventName(eventName);
        setSelectedEventPriceMen(ticketPriceMen);
        setSelectedEventPriceWomen(ticketPriceWomen);
        setSelectedEventColor(eventColor);
    };
    const handleModalConfirmarClose = (data: { name: string, cpf: string, email: string; phone: string, gender: string, ticketPrice: string, qrCodeUrl?: string } | null) => {
        setIsModalConfirmarOpen(false);
        if (data) {
            setSelectedTicketInfo({
                ...data,
                eventName: selectedEventName,
                ticketPriceMen: selectedEventPriceMen,
                ticketPriceWomen: selectedEventPriceWomen,
                eventColor: selectedEventColor,
            });
            setIsModalTicketOpen(true);
        }
    };

    const getUserIP = async (): Promise<string | null> => {
        try {
            const response = await fetch("https://api64.ipify.org?format=json");
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error("Erro ao obter o IP do usuário:", error);
            return null;
        }
    };

    const incrementGuestCount = async (ip: string) => {
        const storedVisits = JSON.parse(localStorage.getItem("visitedTokens") || "{}");
        if (storedVisits[token!] === ip) {
            console.log("Visita já registrada para este IP e token.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/increment-guest-count`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ tokenaniversario: token }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Contagem de convidados incrementada:", data);
                // Atualizar o localStorage
                localStorage.setItem(
                    "visitedTokens",
                    JSON.stringify({ ...storedVisits, [token!]: ip })
                );
            } else {
                console.error("Erro ao incrementar a contagem de convidados.");
            }
        } catch (error) {
            console.error("Erro na requisição para incrementar a contagem de convidados:", error);
        }
    };

    const [isModalAntecipadoOpen, setIsModalAntecipadoOpen] = useState(false);
    const [isModalPagamentoTicket, setIsModalPagamentoTicketOpen] = useState(false);
    const [isModalTicketAntecipadoOpen, setIsModalTicketAntecipadoOpen] = useState(false);

    const handlePaymentApproved = (paymentInfo: {
        qrCodeImage: string;
        name: string;
        cpf: string;
        gender: string;
        ticketPrice: string;
        eventName: string;
        qrCodeUrl: string;
    }) => {
        setIsModalPagamentoTicketOpen(false);
        setSelectedTicketInfo((prev) => ({
            ...prev,
            qrCodeUrl: paymentInfo.qrCodeUrl,
            qrCodeImage: paymentInfo.qrCodeImage,
            name: paymentInfo.name,
            cpf: paymentInfo.cpf,
            gender: paymentInfo.gender,
            ticketPrice: paymentInfo.ticketPrice,
            eventName: paymentInfo.eventName,
        }));
        setIsModalTicketAntecipadoOpen(true);
    };

    const handleModalTicketAntecipadoClose = () => {
        setIsModalTicketAntecipadoOpen(false);
    };

    const handleModalAntecipadoOpen = (eventName: string, ticketPriceMen: string, ticketPriceWomen: string, eventColor: string) => {
        setIsModalAntecipadoOpen(true);
        setSelectedEventName(eventName);
        setSelectedEventPriceMen(ticketPriceMen);
        setSelectedEventPriceWomen(ticketPriceWomen);
        setSelectedEventColor(eventColor);
    };

    const handleModalAntecipadoClose = (data: { name: string, cpf: string, email: string; phone: string, gender: string, ticketPrice: string } | null) => {
        setIsModalAntecipadoOpen(false);
        if (data) {
            setSelectedTicketInfo({
                ...data,
                eventName: selectedEventName,
                ticketPriceMen: selectedEventPriceMen,
                ticketPriceWomen: selectedEventPriceWomen,
                eventColor: selectedEventColor,
            });
            setIsModalPagamentoTicketOpen(true);
        }
    };

    const handleModalPagamentoTicketClose = () => {
        setIsModalPagamentoTicketOpen(false);
    };

    const handleModalTicketClose = () => {
        setIsModalTicketOpen(false);
    };

    const { token } = useParams(); // Extraindo o token da URL

    useEffect(() => {
        if (nomefantasia && token) {
            fetch(`${API_URL}/api/list-events-by-fantasy-name-aniversary`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ fantasy_name: nomefantasia, token }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.message) {
                        setError(data.message);
                    } else {
                        setEvents(data.events.slice(0, 2));
                        setCompany(data.company);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setError("Erro ao carregar eventos.");
                    console.error(error);
                    setLoading(false);
                });
            // Incrementar a contagem de convidados
            getUserIP().then((ip) => {
                if (ip) incrementGuestCount(ip);
            });
        }
    }, [nomefantasia, token]);

    const formatBirthdayName = (fullName) => {
        if (!fullName) return "";
        const nameParts = fullName.trim().split(" ");
        if (nameParts.length === 1) return nameParts[0];
        return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
    };


    return (
        <div
            className="relative w-full min-h-screen bg-cover bg-[50%_50%]"
            style={{
                backgroundImage: `url(${company?.background_url ? `${API_URL}/api/${company.background_url}` : 'https://c.animaapp.com/Fr9I3Xv4/img/confirmar-presen-a.png'})`,
                backgroundColor: '#000000cf',
            }}
        >
            <div
                className="flex items-center justify-center min-h-screen w-full bg-cover bg-[50%_50%]"
                style={{
                    backgroundImage: `url(${company?.background_url ? `${API_URL}/api/${company.background_url}` : 'https://c.animaapp.com/Fr9I3Xv4/img/confirmar-presen-a.png'})`,
                    backgroundColor: '#000000cf',
                }}
            >
                <div
                    className={`relative w-full h-full min-h-screen  flex flex-col items-center justify-center rounded-lg p-4 md:p-8 ${company?.blur_effect === 'active' ? 'backdrop-blur-[15px] backdrop-brightness-[100%]' : ''}`}
                    style={{
                        backdropFilter: company?.blur_effect === 'active' ? 'blur(5px)' : 'none',
                    }}
                >
                    <div className="grid gap-10 grid-cols-1">
                        {events.slice(0, 2).map((event, index) => (
                            <React.Fragment key={index}>
                                <div className="hidden lg:flex relative w-[824px] h-[374px] rounded-lg">
                                    <div className="relative w-[824px] h-[376px]">
                                        <img
                                            className="absolute w-[724px] h-[368px] top-1 left-0 object-cover object-center rounded-l-2xl"
                                            alt="Foto"
                                            src={`${API_URL}/api/${event.event_image_url}`}
                                            loading="lazy"
                                        />
                                        <div className="flex w-[446px] z-50 h-[38px] items-start gap-2.5 absolute top-[316px] left-[63px]">
                                            {event.guest_list === 'active' && (
                                                <button
                                                    className="flex-1 flex items-center justify-center border-none text-[#1f1e25] text-xs font-bold rounded-full px-4 py-2 cursor-pointer transition duration-200 ease-in-out"
                                                    style={{ backgroundColor: event.event_color }}
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#C8C8C8'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = event.event_color}
                                                    onClick={() => handleModalConfirmarOpen(event.event_name, event.ticket_price_men, event.ticket_price_women, event.event_color)}
                                                >

                                                    nome na lista
                                                    <img
                                                        className="ml-2"
                                                        alt="Seta para baixo"
                                                        src="https://c.animaapp.com/Fr9I3Xv4/img/vector-6.svg"
                                                    />
                                                </button>
                                            )}
                                            {event.event_batch === 'active' && (
                                                <button
                                                    className="flex-1 flex items-center justify-center border-none bg-white text-[#1f1e25] text-xs font-bold rounded-full px-4 py-2 border border-black hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out"

                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#C8C8C8'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                                    onClick={() => handleModalAntecipadoOpen(event.event_name, event.ticket_price_men, event.ticket_price_women, event.event_color)}
                                                >
                                                    Comprar antecipado
                                                    <img
                                                        className="ml-2"
                                                        alt="Seta para baixo"
                                                        src="https://c.animaapp.com/Fr9I3Xv4/img/vector-6.svg"
                                                    />
                                                </button>
                                            )}
                                        </div>


                                        <div className="absolute w-[798px] h-[369px] top-[3px] left-[26px]">
                                            <div className="absolute w-[236px] h-[369px] top-0 left-[560px] rounded-[0px_20px_20px_0px]"
                                                style={{ backgroundColor: event.event_color }}>
                                                <div className="absolute w-[202px] top-[70px] left-5 text-center">
                                                    <div className="[font-family:'Poppins',Helvetica] font-normal text-black text-[16px] tracking-[0] leading-[normal]">
                                                        Aniversário de
                                                    </div>
                                                    <div className="[font-family:'Poppins',Helvetica] font-bold text-black text-[30px] tracking-[0] leading-[normal] whitespace-nowrap">
                                                        {formatBirthdayName(event.birthday_name)}
                                                    </div>
                                                </div>

                                                <div className="flex w-[238px] items-center gap-[7.5px] absolute top-[15px] left-4">
                                                    {event.category.split(",").map((cat, i) => (
                                                        <div key={i} className="inline-flex items-center justify-center gap-[7.5px] px-[7.5px] py-[3px] bg-[#cfcfcf] rounded-3xl">
                                                            <div className="text-[#302e38] text-[9.5px] font-medium">{cat.trim()}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {event.attractions && event.attractions.trim() ? (
                                                    <div className="grid grid-cols-2 w-[204px] items-start gap-5 px-5 py-2.5 absolute top-[200px] left-4 bg-[#1b1b20] rounded-[20px]">
                                                        {event.attractions.split(',').map((attraction, index) => (
                                                            <div
                                                                key={index}
                                                                className={`text-white text-base font-medium ${index % 2 === 0 ? 'text-left' : 'text-right'}`}
                                                            >
                                                                {attraction.trim()}
                                                            </div>
                                                        ))}
                                                        <div className="left-[50px] flex w-[104px] items-center justify-center gap-2.5 px-2.5 py-1 absolute top-[-42px] bg-[#cfcfcf] rounded-[20px]">
                                                            <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-medium text-black text-xs tracking-[0] leading-[28.1px] whitespace-nowrap">
                                                                Atração
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                <div className="flex w-[220px] items-center justify-between gap-5 p-2.5 absolute top-[300px] left-2 rounded-[20px] border-[1px] border-solid border-black">
                                                    <div className="flex items-center gap-2.5">
                                                        <img
                                                            className="w-[15.38px] relative h-[30.75px]"
                                                            alt="Vector Mulher"
                                                            src="https://c.animaapp.com/Fr9I3Xv4/img/vector-7.svg"
                                                        />
                                                        <div className="flex flex-col items-start whitespace-nowrap">
                                                            <div className="text-[#1b1b20] text-[12px] font-medium">Mulher</div>
                                                            <div className="text-[#1b1b20] text-xs font-bold">R$ {event.ticket_price_women}</div>
                                                        </div>
                                                    </div>

                                                    <div className="h-[30px] w-[1px] bg-black"></div>

                                                    <div className="flex items-center gap-2.5">
                                                        <img
                                                            className="w-[12.3px] relative h-[30.75px]"
                                                            alt="Vector Homem"
                                                            src="https://c.animaapp.com/Fr9I3Xv4/img/vector-8.svg"
                                                        />
                                                        <div className="flex flex-col items-start whitespace-nowrap">
                                                            <div className="text-[#1b1b20] text-[12px] font-medium">Homem</div>
                                                            <div className="text-[#1b1b20] text-[12px] font-bold">R$ {event.ticket_price_men}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-[68px] h-[166px] top-6 absolute left-0">
                                                <div
                                                    className="flex flex-col w-[68px] items-center justify-center gap-2.5 px-4 py-2.5 absolute top-0 left-0 rounded-2xl text-center"
                                                    style={{ backgroundColor: event.event_color }}
                                                >
                                                    <div className="text-black text-2xl font-medium">
                                                        <p className="m-0">{new Date(event.event_date).getDate()}</p>
                                                        <p className="m-0">{new Date(event.event_date).toLocaleString('pt-BR', { month: 'short' }).replace('.', '')}</p>
                                                    </div>
                                                </div>
                                                <div className="inline-flex flex-col items-center justify-center gap-2.5 px-4 py-2.5 top-[110px] bg-white rounded-2xl absolute left-0 text-center">
                                                    <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins',Helvetica] font-medium text-black text-2xl tracking-[0] leading-[normal]">
                                                        {new Date(event.event_date).toLocaleString('pt-BR', { weekday: 'short' }).replace('.', '')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:hidden flex flex-col items-center gap-4">
                                    <div className="relative w-[301px] h-[385px]">
                                        <img
                                            className="absolute w-[301px] h-[366px] top-0 left-0 rounded-[20px_20px_0px_0px]"
                                            alt="Foto"
                                            src={`${API_URL}/api/${event.event_image_url}`}
                                        />

                                        <div className="absolute w-[301px] top-[185px] flex justify-center gap-2 px-4 z-10">
                                            {event.guest_list === 'active' && (
                                                <button
                                                    className="flex-1 flex items-center justify-center border-none text-[#1f1e25] h-[28px] text-[12px] font-bold rounded-full px-4 py-2 cursor-pointer transition duration-200 ease-in-out whitespace-nowrap"
                                                    style={{ backgroundColor: event.event_color }}
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#C8C8C8'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = event.event_color}
                                                    onClick={() => handleModalConfirmarOpen(event.event_name, event.ticket_price_men, event.ticket_price_women, event.event_color)}
                                                >
                                                    nome na lista
                                                    <img
                                                        className="ml-2"
                                                        alt="Seta para baixo"
                                                        src="https://c.animaapp.com/Fr9I3Xv4/img/vector-6.svg"
                                                    />
                                                </button>
                                            )}
                                            {event.event_batch === 'active' && (
                                                <button
                                                    className="flex-1 flex items-center justify-center border-none bg-white text-[#1f1e25] h-[28px] text-[12px] font-bold rounded-full px-4 py-2 border border-black hover:bg-gray-200 cursor-pointer transition duration-200 ease-in-out whitespace-nowrap"
                                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#C8C8C8'}
                                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                                    onClick={() => handleModalAntecipadoOpen(event.event_name, event.ticket_price_men, event.ticket_price_women, event.event_color)}
                                                >

                                                    Comprar antecipado
                                                    <img
                                                        className="ml-2"
                                                        alt="Seta para baixo"
                                                        src="https://c.animaapp.com/Fr9I3Xv4/img/vector-6.svg"
                                                    />
                                                </button>
                                            )}
                                        </div>

                                        <div className="absolute w-[302px] h-[170px] top-[220px] rounded-[0px_0px_20px_20px]"
                                            style={{ backgroundColor: event.event_color }} />

                                        {/* Data e dia da semana */}
                                        <div className="h-[115px] top-[17px] left-[17px] absolute w-[57px]">
                                            <div className="flex flex-col items-center justify-center h-[71px] px-4 py-2.5 bg-[#e1ff01] rounded-2xl absolute w-[57px]" style={{ backgroundColor: event.event_color }}>
                                                <div className="flex flex-col items-center text-center">
                                                    <p className="[font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0] leading-[normal]">
                                                        {new Date(event.event_date).getDate()}
                                                    </p>
                                                    <p className="[font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0] leading-[normal] mt-[-4px]">
                                                        {new Date(event.event_date).toLocaleString('pt-BR', { month: 'short' }).replace('.', '')}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex flex-col h-[39px] items-center justify-center gap-2.5 px-4 py-2.5 top-[76px] left-0 bg-white rounded-2xl absolute w-[57px]">
                                                <div className="relative w-fit mt-[-3.10px] mb-[-1.10px] ml-[-0.50px] mr-[-0.50px] [font-family:'Poppins',Helvetica] font-medium text-black text-[15px] tracking-[0] leading-[normal]">
                                                    {new Date(event.event_date).toLocaleString('pt-BR', { weekday: 'short' }).replace('.', '')}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Título e categoria */}
                                        <div className="absolute w-[190px] top-[272px] left-[111px] [font-family:'Poppins',Helvetica] font-bold text-black text-[32px] text-center tracking-[0] leading-[28.1px] whitespace-nowrap">
                                            {event.event_name}
                                        </div>
                                        <div className="absolute w-[172px] top-[254px] left-[120px] [font-family:'Poppins',Helvetica] font-normal text-black text-xs text-center tracking-[0] leading-[28.1px] whitespace-nowrap">
                                            Aniversário de
                                        </div>

                                        <div className="flex w-[156px] absolute top-[226px] left-[133px] items-center gap-[7.5px]">
                                            {event.category.split(",").map((cat, i) => (
                                                <div key={i} className="inline-flex justify-center px-[7.5px] py-[3px] bg-[#cfcfcf] rounded-3xl items-center gap-[7.5px]">
                                                    <div className="relative w-fit mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#302e38] text-[7.5px] tracking-[0] leading-[normal]">
                                                        {cat.trim()}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="absolute w-[89px] h-32 top-[254px] left-[5px] bg-[#1b1b20] rounded-[20px]">
                                            <div className="flex w-[77px] h-[22px] items-center justify-center gap-2.5 px-2.5 py-1 relative -top-7 left-1.5 bg-[#cfcfcf] rounded-[20px]">
                                                <div className="relative w-fit mt-[-8.50px] mb-[-6.50px] [font-family:'Poppins',Helvetica] font-medium text-black text-[10px] tracking-[0] leading-[28.1px] whitespace-nowrap">
                                                    Atração
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-center gap-1">
                                                {event.attractions.split(',').map((attraction, index) => (
                                                    <div key={index} className="[font-family:'Poppins',Helvetica] font-medium text-white text-[12px] tracking-[0] leading-[normal]">
                                                        {attraction.trim()}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex w-[187px] items-center justify-center gap-5 p-2.5 absolute top-[325px] left-[111px] rounded-[20px] border border-solid border-black">
                                            <div className="flex items-start gap-2.5 relative flex-1 grow">
                                                <img
                                                    className="relative w-[15.38px] h-[30.75px]"
                                                    alt="Vector"
                                                    src="https://c.animaapp.com/lOl8XZnJ/img/vector-3.svg"
                                                />
                                                <div className="flex flex-col w-[62.25px] items-start gap-[3.75px] relative mr-[-24.62px]">
                                                    <div className="relative self-stretch mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#1b1b20] text-[9px] tracking-[0] leading-[normal]">
                                                        Mulher
                                                    </div>
                                                    <div className="self-stretch relative [font-family:'Poppins',Helvetica] font-bold text-[#1b1b20] text-xs tracking-[0] leading-[normal]">
                                                        R$ {event.ticket_price_women}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="relative w-px h-[26px] bg-[#33323b] rounded-sm" />
                                            <div className="flex items-start gap-[3.75px] relative flex-1 grow">
                                                <img
                                                    className="relative w-[12.3px] h-[30.75px]"
                                                    alt="Vector"
                                                    src="https://c.animaapp.com/lOl8XZnJ/img/vector-4.svg"
                                                />
                                                <div className="flex flex-col w-[65.25px] items-start gap-[3.75px] relative mr-[-18.30px]">
                                                    <div className="relative self-stretch mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#1b1b20] text-[9px] tracking-[0] leading-[normal]">
                                                        Homem
                                                    </div>
                                                    <div className="w-fit relative [font-family:'Poppins',Helvetica] font-bold text-[#1b1b20] text-xs tracking-[0] leading-[normal]">
                                                        R$ {event.ticket_price_men}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <ModalConfirmar
                isOpen={isModalConfirmarOpen}
                onClose={handleModalConfirmarClose}
                eventName={selectedEventName}
                ticketPriceMen={selectedEventPriceMen}
                ticketPriceWomen={selectedEventPriceWomen}
                promoterToken={token}
            />
            <ModalAntecipado
                isOpen={isModalAntecipadoOpen}
                onClose={handleModalAntecipadoClose}
                eventName={selectedEventName}
                ticketPriceMen={selectedEventPriceMen}
                ticketPriceWomen={selectedEventPriceWomen}
                promoterToken={token}
            />
            <ModalPagamentoTicket
                isOpen={isModalPagamentoTicket}
                onClose={handleModalPagamentoTicketClose}
                ticketInfo={selectedTicketInfo}
                onPaymentApproved={handlePaymentApproved}
            />
            <ModalTicket
                isOpen={isModalTicketOpen}
                onClose={handleModalTicketClose}
                ticketInfo={selectedTicketInfo}
            />
            <ModalTicketAntecipado
                isOpen={isModalTicketAntecipadoOpen}
                onClose={handleModalTicketAntecipadoClose}
                ticketInfo={selectedTicketInfo}
            />
        </div>
    );
};
