import React, { useState } from "react";
import { Menu } from "../../components/Menu";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { SiVisa } from "react-icons/si";
import { useLocation } from "react-router-dom";
import { ModalPagamentoPlano } from "../../components/ModalPagamentoPlano";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../config";

export const Pagamentos = (): JSX.Element => {
    const location = useLocation();
    const { title, price } = location.state || {};
    const [selectedPayment, setSelectedPayment] = useState<string>("");
    const [cep, setCep] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [cardNumber, setCardNumber] = useState<string>("");
    const [cardValidity, setCardValidity] = useState<string>("");
    const [cardCvv, setCardCvv] = useState<string>("");
    const [address, setAddress] = useState({
        endereco: "",
        cidade: "",
        estado: ""
    });
    const [email, setEmail] = useState<string>("");

    const getCurrentDate = () => {
        const now = new Date();
        const months = [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
            "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];
        const day = now.getDate();
        const month = months[now.getMonth()];
        const year = now.getFullYear();
        return `${day} de ${month} ${year}`;
    };


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const currentDate = getCurrentDate();

    const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawCep = e.target.value.replace(/\D/g, "");
        setCep(rawCep.replace(/(\d{5})(\d{3})/, "$1-$2"));

        if (rawCep.length === 8) {
            fetchAddress(rawCep);
        }
    };

    const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawCpf = e.target.value.replace(/\D/g, "");
        setCpf(
            rawCpf
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        );
    };

    const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawNumber = e.target.value.replace(/\D/g, "");
        setCardNumber(rawNumber.replace(/(\d{4})(?=\d)/g, "$1 "));
    };

    const handleCardValidityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValidity = e.target.value.replace(/\D/g, "");
        setCardValidity(rawValidity.replace(/(\d{2})(\d{1,2})$/, "$1/$2"));
    };

    const handleCardCvvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawCvv = e.target.value.replace(/\D/g, "");
        setCardCvv(rawCvv.slice(0, 3));
    };

    const fetchAddress = async (cep: string) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();

            if (!data.erro) {
                setAddress({
                    endereco: data.logradouro,
                    cidade: data.localidade,
                    estado: data.uf
                });
            } else {
                setAddress({ endereco: "", cidade: "", estado: "" });
                alert("CEP não encontrado.");
            }
        } catch (error) {
            console.error("Erro ao buscar o endereço:", error);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const handleModal2Open = () => {
        if (!cpf || cpf.length !== 14) {
            toast.error("Por favor, insira um CPF válido.", {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }
        if (!cep || cep.length !== 9) {
            toast.error("Por favor, insira um CEP válido.", {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }
        if (!address.endereco || !address.cidade || !address.estado) {
            toast.error("Por favor, preencha corretamente o endereço.", {
                position: "top-center",
                autoClose: 3000,
            });
            return;
        }

        setIsModal2Open(true);
    };

    const handleModal2Close = () => {
        setIsModal2Open(false);
    };

    const [fullName, setFullName] = useState<string>("");

    const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFullName(e.target.value);
    };

    const handlePayment = async () => {
        toast.info(
            "A funcionalidade de pagamento por cartão está temporariamente desativada. Nosso sistema realiza uma verificação do ambiente em que o back-end está instalado para garantir a segurança das transações. Assim que o ambiente de produção for validado, os pagamentos estarão disponíveis.",
            {
                position: "top-center",
                autoClose: 15000,
            }
        );
    };

    return (
        <div className="relative w-full min-h-screen bg-[#1b2026] flex flex-col items-center overflow-hidden">
            <Menu />
            <ToastContainer />
            <div className="flex flex-col items-center w-full max-w-[90%] py-10 px-4 sm:px-8 lg:px-16 xl:px-20">
                <div className="flex w-full flex-col lg:flex-row items-stretch lg:items-start justify-between relative">
                    <div className="flex-1 flex flex-col justify-start items-center lg:items-start px-6 mb-8 lg:mb-0 lg:max-w-[48%]">
                        <h2 className="text-white text-2xl mb-6 font-bold">Forma de Pagamento</h2>
                        <div
                            className={`w-full rounded-2xl p-6 mb-4 flex items-center cursor-pointer ${selectedPayment === "pix" ? "border border-[#E1FF01]" : ""
                                }`}
                            onClick={() => setSelectedPayment("pix")}
                            style={{
                                backgroundColor: "rgba(31, 30, 37, 0.25)",
                                border: "1px solid #706D85",
                            }}
                        >
                            <span className={`custom-radio ${selectedPayment === "pix" ? "checked" : ""} mr-4`} />
                            <label className="flex items-center text-white font-bold text-lg">
                                <FaMoneyCheckAlt className="mr-3 text-[#E1FF01] text-2xl" />
                                Pix
                            </label>
                        </div>
                        <div
                            className={`w-full rounded-2xl p-6 mb-6 flex items-center cursor-pointer ${selectedPayment === "credit-card" ? "border border-[#E1FF01]" : ""
                                }`}
                            onClick={() => setSelectedPayment("credit-card")}
                            style={{
                                backgroundColor: "rgba(31, 30, 37, 0.25)",
                                border: "1px solid #706D85",
                            }}
                        >
                            <span
                                className={`custom-radio ${selectedPayment === "credit-card" ? "checked" : ""} mr-4`}
                            />
                            <label className="flex items-center text-white font-bold text-lg">
                                <SiVisa className="mr-3 text-[#E1FF01] text-3xl" />
                                Cartão de Crédito
                            </label>
                        </div>
                    </div>

                    <div className="hidden lg:block absolute left-1/2 transform -translate-x-1/2 h-full w-[1px] bg-[#706D85]"></div>

                    <div className="flex-1 flex flex-col justify-start items-center lg:items-start px-6 lg:max-w-[48%]">
                        {selectedPayment === "credit-card" && (
                            <div className="w-full p-6 bg-[#1b2026] rounded-lg">
                                <h2 className="text-white text-2xl font-bold mb-6">Detalhes do pagamento</h2>
                                <p className="text-gray-400 mb-6">Conclua sua compra de forma rápida e segura.</p>
                                <form className="space-y-4">
                                    <div>
                                        <label htmlFor="titular" className="text-[#F1F1F1] mb-1 block">Titular</label>
                                        <input
                                            id="titular"
                                            type="text"
                                            placeholder="Nome completo"
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="cpf" className="text-[#F1F1F1] mb-1 block">CPF</label>
                                        <input
                                            id="cpf"
                                            type="text"
                                            placeholder="000.000.000-00"
                                            value={cpf}
                                            onChange={handleCpfChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="text-[#F1F1F1] mb-1 block">E-mail</label>
                                        <input
                                            id="email"
                                            type="text"
                                            placeholder="exemplo@gmail.com"
                                            onChange={handleCepChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="cep" className="text-[#F1F1F1] mb-1 block">CEP</label>
                                        <input
                                            id="cep"
                                            type="text"
                                            placeholder="00000-000"
                                            value={cep}
                                            onChange={handleCepChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="endereco" className="text-[#F1F1F1] mb-1 block">Endereço</label>
                                        <input
                                            id="endereco"
                                            type="text"
                                            placeholder="Endereço"
                                            value={address.endereco}
                                            readOnly
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="cidade" className="text-[#F1F1F1] mb-1 block">Cidade</label>
                                            <input
                                                id="cidade"
                                                type="text"
                                                placeholder="Cidade"
                                                value={address.cidade}
                                                readOnly
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="estado" className="text-[#F1F1F1] mb-1 block">Estado</label>
                                            <input
                                                id="estado"
                                                type="text"
                                                placeholder="Estado"
                                                value={address.estado}
                                                readOnly
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="cardNumber" className="text-[#F1F1F1] mb-1 block">Número do Cartão</label>
                                        <input
                                            id="cardNumber"
                                            type="text"
                                            placeholder="0000 0000 0000 0000"
                                            value={cardNumber}
                                            onChange={handleCardNumberChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="cardValidity" className="text-[#F1F1F1] mb-1 block">Validade</label>
                                            <input
                                                id="cardValidity"
                                                type="text"
                                                placeholder="MM/AA"
                                                value={cardValidity}
                                                onChange={handleCardValidityChange}
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="cardCvv" className="text-[#F1F1F1] mb-1 block">CVV</label>
                                            <input
                                                id="cardCvv"
                                                type="text"
                                                placeholder="000"
                                                value={cardCvv}
                                                onChange={handleCardCvvChange}
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                    </div>
                                    <h2 className="text-white text-2xl font-bold mt-8 mb-4">Informações do pagamento</h2>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="valor" className="text-[#F1F1F1] mb-1 block">Valor</label>
                                            <div className="relative">
                                                <span className="absolute left-4 top-3 text-[#E1FF01]">R$</span>
                                                <input
                                                    id="valor"
                                                    type="text"
                                                    value={price || ""}
                                                    placeholder="12345,67"
                                                    className="rounded-2xl w-full p-3 pl-10 bg-transparent bg-[#2f2e39] text-[#F1F1F1] placeholder-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="dataVencimento" className="text-[#F1F1F1] mb-1 block">Data do pagamento</label>
                                            <input
                                                id="dataVencimento"
                                                type="text"
                                                value={currentDate}
                                                placeholder="23 de Jul 2024"
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] placeholder-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="w-full p-3 bg-[#E1FF01] text-black font-bold rounded-md"
                                        onClick={handlePayment}
                                    >
                                        Realizar pagamento
                                    </button>
                                </form>
                            </div>
                        )}
                        {selectedPayment === "pix" && (
                            <div className="w-full p-6 bg-[#1b2026] rounded-lg">
                                <h2 className="text-white text-2xl font-bold mb-6">Detalhes do pagamento</h2>
                                <p className="text-gray-400 mb-6">Conclua sua compra de forma rápida e segura.</p>
                                <form className="space-y-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="nome" className="text-[#F1F1F1] mb-1 block">Nome completo</label>
                                            <input
                                                id="nome"
                                                type="text"
                                                placeholder="Nome completo"
                                                value={fullName}
                                                onChange={handleFullNameChange}
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="cpf" className="text-[#F1F1F1] mb-1 block">CPF</label>
                                            <input
                                                id="cpf"
                                                type="text"
                                                placeholder="CPF"
                                                value={cpf}
                                                onChange={handleCpfChange}
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="text-[#F1F1F1] mb-1 block">E-mail</label>
                                        <input
                                            id="email"
                                            type="text"
                                            placeholder="exemplo@gmail.com"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="cep" className="text-[#F1F1F1] mb-1 block">CEP</label>
                                        <input
                                            id="cep"
                                            type="text"
                                            placeholder="CEP"
                                            value={cep}
                                            onChange={handleCepChange}
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="endereco" className="text-[#F1F1F1] mb-1 block">Endereço</label>
                                        <input
                                            id="endereco"
                                            type="text"
                                            placeholder="Endereço"
                                            value={address.endereco}
                                            readOnly
                                            className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="cidade" className="text-[#F1F1F1] mb-1 block">Cidade</label>
                                            <input
                                                id="cidade"
                                                type="text"
                                                placeholder="Cidade"
                                                value={address.cidade}
                                                readOnly
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="estado" className="text-[#F1F1F1] mb-1 block">Estado</label>
                                            <input
                                                id="estado"
                                                type="text"
                                                placeholder="Estado"
                                                value={address.estado}
                                                readOnly
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                            />
                                        </div>
                                    </div>
                                    <h2 className="text-white text-2xl font-bold mt-8 mb-4">Informações do pagamento</h2>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="valor" className="text-[#F1F1F1] mb-1 block">Valor</label>
                                            <div className="relative">
                                                <span className="absolute left-4 top-3 text-[#E1FF01]">R$</span>
                                                <input
                                                    id="valor"
                                                    type="text"
                                                    value={price || ""}
                                                    placeholder="12345,67"
                                                    className="rounded-2xl w-full p-3 pl-10 bg-transparent bg-[#2f2e39] text-[#F1F1F1] placeholder-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="dataVencimento" className="text-[#F1F1F1] mb-1 block">Data do pagamento</label>
                                            <input
                                                id="dataVencimento"
                                                type="text"
                                                value={currentDate}
                                                placeholder="23 de Jul 2024"
                                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] placeholder-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className="w-full p-3 bg-[#E1FF01] text-black font-bold rounded-md"
                                        type="button"
                                        onClick={handleModal2Open}
                                    >
                                        Realizar pagamento
                                    </button>

                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{`
                .custom-radio {
                    width: 24px;
                    height: 24px;
                    border: 3px solid #4a4a4a;
                    border-radius: 50%;
                    display: inline-block;
                    position: relative;
                }
                .custom-radio.checked {
                    border-color: #e1ff01;
                    background-color: #4a4a4a;
                }
                .custom-radio.checked::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 12px;
                    height: 12px;
                    background-color: #e1ff01;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                }
            `}</style>

            <ModalPagamentoPlano
                isOpen={isModal2Open}
                onClose={handleModal2Close}
                title={title || "Plano"}
                price={price || "0,00"}
                userData={{
                    full_name: fullName,
                    cpf,
                    email,
                    telefone: "",
                    endereco: address.endereco,
                    cidade: address.cidade,
                    estado: address.estado,
                    cep,
                }}
            />
        </div>
    );
};
