import React, { useState } from "react";
import { BotO } from "../../components/BotO";
import { InputField } from "../../components/InputField";
import avaliacoes from "../../imagens/avaliacoes.png";
import imagemsobre from "../../imagens/imagem-sobre.png";
import { Menu } from "../../components/Menu";
import { Help } from "../../components/Help";
import { BoxContato } from "../../components/BoxContato";
import { FaChevronRight, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import CountUp from "react-countup";

export const Sobrenos = (): JSX.Element => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);
    const navigate = useNavigate();

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleVerPrecosClick = () => {
        navigate('/planos');
    };

    const handleFaleConoscoClick = () => {
        window.open('https://wa.me/5515991875600', '_blank');
    };

    return (
        <div className="relative w-full min-h-screen bg-[#1b2026] overflow-hidden">
            <Menu />

            <div className="relative flex flex-col items-center py-10 px-4 sm:px-10 md:px-20 lg:px-32 z-10">
                {/* Seção Sobre */}
                <div className="[font-family:'Poppins',Helvetica] font-semibold text-white text-[40px] text-center tracking-[0] leading-[normal] z-2">
                    Sobre
                </div>
                <p className="text-center text-[#999999] max-w-[700px] mx-auto z-2">
                    Descubra a jornada por trás da nossa tecnologia revolucionária,
                    nossos valores que está transformando o gerenciamento de eventos
                </p>
                <img className="mt-6 w-[370px] h-auto" alt="Imagem adicional" src={avaliacoes} />

                <div className="flex justify-center gap-4 mt-10">
                    <button
                        onClick={handleFaleConoscoClick}
                        className="px-6 py-2 bg-[#22252A] font-bold text-white rounded-[36px] border border-[#464646] transition-colors hover:bg-[#333]"
                    >
                        Fale conosco
                    </button>
                    <button
                        onClick={handleVerPrecosClick}
                        className="px-6 py-2 bg-[#E1FF01] font-bold text-[#22252A] rounded-[36px] flex items-center gap-2 transition-colors hover:bg-[#a8bf00]"
                    >
                        <span>Ver preços</span>
                        <FaArrowRightLong className="text-[#22252A]" />
                    </button>
                </div>

                <img
                    className="absolute w-[683px] h-[171px] z-1"
                    alt="Stars"
                    src="https://c.animaapp.com/HGsyE1ix/img/stars.png"
                />

                {/* Seção de Estatísticas */}
                <div className="flex justify-center w-full mt-20">
                    <div className="flex flex-col md:flex-row items-center md:items-start gap-10 max-w-[1200px]">
                        <img
                            className="hidden md:block w-full md:w-[500px] h-auto rounded-lg"
                            alt="Imagem sobre a jornada"
                            src={imagemsobre}
                        />

                        <div className="text-white text-left md:w-1/2 mt-6 flex flex-col gap-1">
                            <p className="text-[#E1FF01] font-semibold text-xs md:text-sm text-center md:text-left">
                                Descubra a solução completa para gerenciamento eficiente!
                            </p>
                            <h2 className="text-2xl md:text-4xl font-bold leading-tight text-center md:text-left">
                                Estamos apenas começando nossa jornada
                            </h2>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-[80px]">
                                <div className="flex flex-col items-center md:items-start">
                                    <span className="text-[#E1FF01] text-4xl font-bold">400+</span>
                                    <p className="text-[#FFFFFF] text-center md:text-left">Eventos gerenciados com sucesso</p>
                                </div>
                                <div className="flex flex-col items-center md:items-start">
                                    <span className="text-[#E1FF01] text-4xl font-bold">10k</span>
                                    <p className="text-[#FFFFFF] text-center md:text-left">Compras de ingresso usando o nosso sistema</p>
                                </div>
                                <div className="flex flex-col items-center md:items-start">
                                    <span className="text-[#E1FF01] text-4xl font-bold">140%</span>
                                    <p className="text-[#FFFFFF] text-center md:text-left">Retorno sobre investimento</p>
                                </div>
                                <div className="flex flex-col items-center md:items-start">
                                    <span className="text-[#E1FF01] text-4xl font-bold">200+</span>
                                    <p className="text-[#FFFFFF] text-center md:text-left">Avaliações de 5 estrelas</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="relative w-screen h-auto mt-20 left-0 [background:linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(175,245,0,0.07)_25.84%,rgba(148.86,208.41,0,0.06)_51.35%,rgba(0,0,0,0)_100%)]">
                    <div className="flex flex-col items-center px-4 lg:px-0">
                        <div className="w-full text-center text-white font-semibold text-2xl md:text-4xl mt-10 [font-family:'Poppins',Helvetica]">
                            O Que Oferecemos
                        </div>

                        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-10 max-w-[1200px] mx-auto">
                            <div className="w-full lg:col-span-2 flex flex-col items-start gap-4 p-4 bg-[#20222780] border border-solid border-[#575561] rounded-2xl">
                                <img className="mb-4" alt="Icons" src="https://c.animaapp.com/B358nUby/img/icons.svg" />
                                <div className="font-semibold text-white text-xl md:text-2xl">Dashboard Intuitivo</div>
                                <p className="text-[#ffffff66] text-base md:text-lg">
                                    Uma interface fácil de usar que centraliza todas as suas operações em um único lugar.
                                </p>
                            </div>

                            <div className="w-full lg:col-span-2 flex flex-col items-start gap-4 p-4 bg-[#20222780] border border-solid border-[#575561] rounded-2xl">
                                <img className="mb-4" alt="Icons" src="https://c.animaapp.com/B358nUby/img/icons-1.svg" />
                                <div className="font-semibold text-white text-xl md:text-2xl">Gestão de Clientes</div>
                                <p className="text-[#ffffff66] text-base md:text-lg">
                                    Ferramentas para entender e engajar seus clientes de forma mais eficaz.
                                </p>
                            </div>

                            <div className="col-span-1 lg:col-span-4 w-full flex flex-col items-start gap-4 p-4 bg-[#20222780] border border-solid border-[#575561] rounded-2xl">
                                <img className="mb-4" alt="Icons" src="https://c.animaapp.com/B358nUby/img/icons-2.svg" />
                                <div className="font-semibold text-white text-xl md:text-2xl">Análises Detalhadas</div>
                                <p className="text-[#ffffff66] text-base md:text-lg">
                                    Insights valiosos para ajudar na tomada de decisões estratégicas.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center gap-5 mt-20">
                        <div className="text-center [font-family:'Poppins',Helvetica] font-semibold text-white text-2xl md:text-4xl">
                            Monitoramento Imersivo
                        </div>
                        <p className="text-center text-[#999999] max-w-[700px] mx-auto z-2">
                            Visualize cada detalhe do seu evento em tempo real, como nunca antes!
                        </p>
                    </div>

                    <div className="flex flex-col md:flex-row justify-center gap-6 mt-10">
                        <div className="flex flex-col w-full md:w-[336px] items-center gap-10 p-10 bg-[#202227] rounded-[48px] border-2 border-solid border-[#232323]">
                            <img className="w-[63.6px] h-[63.6px]" alt="Icon" src="https://c.animaapp.com/BX8uBd2M/img/icon.svg" />
                            <div className="text-center">
                                <div className="[font-family:'Poppins',Helvetica] font-semibold text-white text-[32px]">Análise em Tempo Real</div>
                                <p className="[font-family:'Poppins',Helvetica] font-medium text-[#b2b2b2] text-base">
                                    Acompanhe as métricas essenciais em tempo real e tome decisões rápidas.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:w-[336px] items-center gap-10 p-10 bg-[#202227] rounded-[48px] border-2 border-solid border-[#232323]">
                            <img className="w-[62.72px] h-[62.72px]" alt="Icon" src="https://c.animaapp.com/BX8uBd2M/img/icon-1.svg" />
                            <div className="text-center">
                                <div className="[font-family:'Poppins',Helvetica] font-semibold text-white text-[32px]">Tenha Controle Total</div>
                                <p className="[font-family:'Poppins',Helvetica] font-medium text-[#b2b2b2] text-base">
                                    Descubra a solução completa para gerenciamento eficiente e sucesso garantido.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col w-full md:w-[336px] items-center gap-10 p-10 bg-[#202227] rounded-[48px] border-2 border-solid border-[#232323]">
                            <img className="w-[63.33px] h-[63.89px]" alt="Icon" src="https://c.animaapp.com/BX8uBd2M/img/icon-2.svg" />
                            <div className="text-center">
                                <div className="[font-family:'Poppins',Helvetica] font-semibold text-white text-[32px]">Insights Intuitivos</div>
                                <p className="[font-family:'Poppins',Helvetica] font-medium text-[#b2b2b2] text-base">
                                    Desbloqueie relatórios dinâmicos que revelam segredos para o sucesso.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center gap-4 mt-10">
                        <button
                            onClick={handleFaleConoscoClick}
                            className="px-6 py-2 bg-[#22252A] font-bold text-white rounded-[36px] border border-[#464646] transition-colors hover:bg-[#333]"
                        >
                            Fale conosco
                        </button>
                        <button
                            onClick={handleVerPrecosClick}
                            className="px-6 py-2 bg-[#E1FF01] font-bold text-[#22252A] rounded-[36px] flex items-center gap-2 transition-colors hover:bg-[#a8bf00]"
                        >
                            <span>Ver preços</span>
                            <FaArrowRightLong className="text-[#22252A]" />
                        </button>
                    </div>
                </div>

                <div className="flex justify-center items-center bg-[#1b2026] py-16 px-4">
                    <div className="flex gap-4 md:gap-8 text-center text-white font-semibold flex-nowrap">
                        <div className="flex flex-col items-center">
                            <CountUp end={25000} duration={4.5} separator="." className="text-3xl md:text-4xl lg:text-5xl" />
                            <p className="text-[#E1FF01] text-sm md:text-base mt-2">Total de Visitas</p>
                        </div>

                        <div className="w-px bg-gray-600 h-10 md:h-12 self-center"></div>

                        <div className="flex flex-col items-center">
                            <CountUp end={18000} duration={4.5} separator="." className="text-3xl md:text-4xl lg:text-5xl" />
                            <p className="text-[#E1FF01] text-sm md:text-base mt-2">Visitantes Únicos</p>
                        </div>

                        <div className="w-px bg-gray-600 h-10 md:h-12 self-center"></div>

                        <div className="flex flex-col items-center">
                            <CountUp end={500} duration={4.5} separator="." className="text-3xl md:text-4xl lg:text-5xl" />
                            <p className="text-[#E1FF01] text-sm md:text-base mt-2">Visitas Diárias</p>
                        </div>
                    </div>
                </div>

                {/* Restante do conteúdo */}
                <div >
                    <Help />
                </div>
            </div>
        </div>
    );
};
