import React, { useState } from "react";
import { BotO } from "../../components/BotO";
import { InputField } from "../../components/InputField";
import contato from "../../imagens/horario.png";
import glowe from "../../imagens/glow-e.png";
import { Menu } from "../../components/Menu";
import { Help } from "../../components/Help";
import { BoxContato } from "../../components/BoxContato";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export const Contato = (): JSX.Element => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="relative w-full min-h-screen bg-[#1b2026] overflow-hidden">
            <Menu />
            <div
                className="fixed inset-0 bg-cover bg-center opacity-10 z-0"
                style={{ backgroundImage: `url(https://c.animaapp.com/RLk3Kegs/img/glow.svg)` }}
            />

            <div className="relative flex flex-col items-center py-10 px-4 sm:px-10 md:px-20 lg:px-32 z-10">
                <div className="text-center mt-10">
                    <h1 className="text-3xl sm:text-4xl font-semibold text-white">
                        Contato
                    </h1>
                    <p className="text-base text-[#999999] mt-2 max-w-[700px] mx-auto">
                        Precisa de ajuda? Nossa equipe está pronta para auxiliar você.
                    </p>
                </div>

                <BoxContato />

                <div className="w-full max-w-[1200px] mt-10 text-center">
                    <h2 className="text-[#e1ff01] text-2xl sm:text-3xl font-semibold">
                        Perguntas Frequentes
                    </h2>
                    <p className="text-white mt-2 text-base max-w-[700px] mx-auto">
                        Tudo que você precisa saber está aqui.
                    </p>

                    <div className="flex flex-col gap-6 mt-8 w-full max-w-[800px] mx-auto">
                        {[
                            {
                                question: (
                                    <>
                                        O que é a <span className="text-[#e1ff01]">Lista.Vip</span>?
                                    </>
                                ),
                                answer:
                                    <>
                                        <span className="text-[#e1ff01]">Lista.Vip</span> é uma plataforma digital que permite a venda de ingressos para eventos de maneira simples e eficaz, conectando organizadores e participantes de forma segura e prática.
                                    </>,
                            },
                            {
                                question: "Como faço para entrar em contato com o suporte?",
                                answer:
                                    "Você pode entrar em contato com o suporte 24 horas por dia através da nossa central de atendimento ou pelo chat disponível na plataforma.",
                            },
                            {
                                question: "A plataforma é segura?",
                                answer:
                                    "Sim, nossa plataforma utiliza criptografia de ponta a ponta para proteger os dados de pagamento e informações pessoais de todos os usuários.",
                            },
                        ].map((item, index) => (
                            <div key={index} className="rounded-lg p-4">
                                <div
                                    className="flex justify-between items-center text-white font-semibold cursor-pointer"
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <p className="text-left">{item.question}</p>
                                    <span className="text-[#e1ff01]">
                                        {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                    </span>
                                </div>
                                {openIndex === index && (
                                    <p className="text-[#727272] mt-2 text-left border-t border-[#333333] pt-4">
                                        {item.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <Help/>
            </div>
        </div>
    );
};
