import React, { useState, useEffect } from "react";
import { API_URL } from "../../config";
import { IoMdClose } from "react-icons/io";
import { useTheme } from "../../ThemeContext";

interface ModalConvidadoProps {
    isOpen: boolean;
    onClose: () => void;
    birthday: Birthday | null; // Recebe o aniversariante selecionado
}

export const ModalConvidado = ({ isOpen, onClose, birthday }: ModalConvidadoProps) => {
    const [guestsCount, setGuestsCount] = useState<number | null>(null);
    const [confirmedGuests, setConfirmedGuests] = useState<number | null>(null);
    const { theme } = useTheme();

    useEffect(() => {
        if (birthday && isOpen) {
            fetch(`${API_URL}/api/birthday-details/${birthday.id}`)
                .then((response) => response.json())
                .then((data) => {
                    setGuestsCount(data.guests_count);
                    setConfirmedGuests(data.confirmed_guests);
                })
                .catch((error) => {
                    console.error("Erro ao buscar os detalhes do aniversariante:", error);
                });
        }
    }, [birthday, isOpen]);

    if (!isOpen || !birthday) return null; 

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            onClick={handleClickOutside}
            style={{
                backdropFilter: "blur(5px)", 
            }}
        >
            <div className="relative w-full max-w-md lg:max-w-lg xl:max-w-xl p-4">
                <div
                    className="relative flex flex-col w-full items-end gap-8 p-6 bg-[#1b2026] rounded-3xl overflow-hidden border border-solid border-[#575560] shadow-[0px_20px_24px_-4px_#00000052]"
                    style={{
                        backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
                    }}
                >
                    <IoMdClose
                        size={20}
                        onClick={onClose}
                        className="cursor-pointer transition-colors"
                        style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}
                    />

                    <div className="flex flex-col items-start gap-5 w-full">
                        <div className="text-[#e1ff01] text-2xl font-bold">
                            Convidados
                        </div>
                        <div
                            className="text-base font-medium"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Visualize informações sobre o aniversariante
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 w-full">
                        <div
                            className="p-4 rounded-2xl border border-solid"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            <div className="flex items-center gap-2">
                                <img
                                    className="w-6 h-6"
                                    alt="Icon"
                                    src="https://c.animaapp.com/3hdJMp2K/img/icon-3.svg"
                                />
                                <div className="text-[#e1ff01] text-base font-medium">
                                    Quantidade de convidados
                                </div>
                            </div>
                            <div
                                className="text-4xl font-bold mt-2"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            >
                                {guestsCount !== null ? guestsCount : "Carregando..."}
                            </div>
                        </div>

                        <div
                            className="p-4 rounded-2xl border border-solid"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            <div className="flex items-center gap-2">
                                <img
                                    className="w-6 h-6"
                                    alt="Icon"
                                    src="https://c.animaapp.com/3hdJMp2K/img/icon-3.svg"
                                />
                                <div className="text-[#e1ff01] text-base font-medium">
                                    Convidados Confirmados
                                </div>
                            </div>
                            <div
                                className="text-4xl font-bold mt-2"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            >
                                {confirmedGuests !== null
                                    ? confirmedGuests
                                    : "Carregando..."}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center gap-4 w-full">
                        <button
                            className="w-full md:w-auto flex-1 py-2 rounded-full border border-solid border-[#575560] bg-[#33323b] text-[#f1f1f1] font-bold transition-all hover:bg-[#3c3c47]"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button className="w-full md:w-auto flex-1 py-2 rounded-full bg-[#e1ff01] text-[#302e38] font-bold transition-all hover:bg-[#b8cc00]">
                            <img
                                src="https://c.animaapp.com/3hdJMp2K/img/icon-4.svg"
                                alt="Icon"
                                className="inline-block mr-2"
                            />
                            Copiar Link
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
