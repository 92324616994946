import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const RelatorioInfoEvento = (): JSX.Element => {
    const { id: event_id } = useParams<{ id: string }>();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [eventData, setEventData] = useState<{
        event_name: string;
        category: string;
        event_date: string;
        revenue_men: string;
        revenue_women: string;
        total_revenue: string;
        men_confirmed: number;
        women_confirmed: number;
        hourly_data: { time: string, men: number, women: number }[];
    } | null>(null);

    const { theme } = useTheme();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchEventData = async () => {
            const company_id = localStorage.getItem("companyId");

            if (!company_id) {
                toast.error("ID da empresa não encontrado no localStorage.");
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/event-info`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ company_id, event_id })
                });

                if (!response.ok) {
                    throw new Error("Erro ao buscar informações do evento.");
                }

                const data = await response.json();
                setEventData(data);
            } catch (error) {
                toast.error(error.message || "Erro ao carregar dados do evento.");
            }
        };

        fetchEventData();
    }, [event_id]);

    const formatEventDate = (dateString: string) => {
        const date = new Date(dateString);
        const dayOfWeek = date.toLocaleDateString('pt-BR', { weekday: 'long' });
        const day = date.getDate();
        const month = date.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '');

        return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)} - ${day} de ${month}`;
    };

    const chartData = {
        labels: eventData?.hourly_data.map((data) => data.time) || [],
        datasets: [
            {
                label: "Homens",
                data: eventData?.hourly_data.map((data) => data.men) || [],
                borderColor: theme === "dark" ? "#FFFFFF" : "#000000", 
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderWidth: 2,
                tension: 0.4,
            },
            {
                label: "Mulheres",
                data: eventData?.hourly_data.map((data) => data.women) || [],
                borderColor: "#E1FF01",
                backgroundColor: "rgba(225, 255, 1, 0.1)",
                borderWidth: 2,
                tension: 0.4,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                titleColor: "#fff",
                bodyColor: "#fff",
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                ticks: {
                    color: theme === "dark" ? "#FFFFFF" : "#000000",
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen overflow-hidden"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6 flex flex-col lg:flex-row items-center lg:items-start justify-between">
                    <div className="text-left w-full">
                        <h1
                            className="font-bold [font-family:'Poppins',Helvetica] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Relatório do evento
                        </h1>

                        {eventData && (
                            <div className="flex flex-wrap items-center mt-2 space-x-4">
                                <span
                                    className="text-2xl font-bold"
                                    style={{ color: "#E1FF01" }}
                                >
                                    {eventData.event_name}
                                </span>
                                <div className="flex space-x-2">
                                    {eventData.category.split(',').map((category, index) => (
                                        <span
                                            key={index}
                                            className="bg-[#111111] text-white text-sm font-medium px-4 py-2 rounded-full"
                                            style={{
                                                backgroundColor: theme === "dark" ? "#111111" : "#111111",
                                                color: theme === "dark" ? "#FFFFFF" : "#F1F1F1",
                                            }}
                                        >
                                            {category.trim()}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {eventData && (
                    <div className="text-sm font-medium mt-2"
                        style={{
                            color: theme === "dark" ? "#F1F1F1" : "#545454",
                        }}
                    >
                        {formatEventDate(eventData.event_date)}
                    </div>
                )}

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="flex flex-col lg:flex-row w-full justify-center items-center gap-4 mt-2">
                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <p className="text-[#E1FF01] font-medium text-sm">Vendas para Mulheres</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>R$ {eventData?.revenue_women}</span>
                    </div>

                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <p className="text-[#E1FF01] font-medium text-sm">Vendas para Homens</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>R$ {eventData?.revenue_men}</span>
                    </div>

                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <p className="text-[#E1FF01] font-medium text-sm">Total de vendas</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>R$ {eventData?.total_revenue}</span>
                    </div>
                </div>

                <div className="mt-6 p-4 rounded-lg border border-solid border-[#575560]">
                    <div className="flex justify-between items-center mb-10 mt-4">
                        <h2 className="text-[#E1FF01] font-bold text-lg mb-4">Participantes no evento</h2>
                        <div className="flex items-center space-x-4">
                            <div className="flex items-center space-x-1">
                                <span className="w-3 h-3 bg-[#FFFFFF] rounded-full inline-block" style={{
                                    backgroundColor: theme === "dark" ? "#F1F1F1" : "#000000"
                                }}></span>
                                <span className="text-[#FFFFFF] font-medium" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000"
                                }}> Homens</span>
                            </div>
                            <div className="flex items-center space-x-1">
                                <span className="w-3 h-3 bg-[#E1FF01] rounded-full inline-block"></span>
                                <span className="text-[#E1FF01] font-medium">Mulheres</span>
                            </div>
                        </div>
                    </div>
                    <Line data={chartData} height={75} options={chartOptions} />
                </div>
            </div>
        </div >
    );
};
