import React, { useState, useEffect } from 'react';
import { useTheme } from "../../ThemeContext";
import { API_URL } from "../../config";
import { IoMdClose } from "react-icons/io";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Defina o tipo dos eventos
interface Event {
    id: number;
    event_name: string;
}

interface ModalCadastroProps {
    isOpen: boolean;
    onClose: () => void;
}

const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");

    if (value.length > 10) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length > 5) {
        value = value.replace(/^(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else if (value.length > 2) {
        value = value.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
    } else {
        value = value.replace(/^(\d*)/, "($1");
    }

    return value;
};

export const ModalCadastro = ({ isOpen, onClose }: ModalCadastroProps): JSX.Element | null => {
    if (!isOpen) return null;

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [selectedEvent, setSelectedEvent] = useState("");
    const [events, setEvents] = useState<Event[]>([]);
    const { theme } = useTheme();

    // Função para carregar eventos
    const loadEvents = async () => {
        const companyId = localStorage.getItem('companyId'); // Recupera o company_id do localStorage
        if (!companyId) {
            toast.error("ID da empresa não encontrado no localStorage.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/list-events/${companyId}`);
            const data = await response.json();
            setEvents(data.events); // Armazena os eventos no estado
        } catch (error) {
            toast.error("Erro ao carregar os eventos.");
            console.error('Erro ao carregar eventos:', error);
        }
    };

    // Função para criar aniversariante
    const createBirthday = async () => {
        if (!name || !phone || !selectedEvent) {
            toast.warn("Por favor, preencha todos os campos.");
            return;
        }

        const companyId = localStorage.getItem('companyId');
        if (!companyId) {
            toast.error("ID da empresa não encontrado.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/create-birthday`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: companyId,
                    event_id: selectedEvent,
                    name: name,
                    whatsapp: phone
                }),
            });

            const data = await response.json();
            if (response.ok) {
                toast.success('Aniversariante criado com sucesso!');
                onClose(); // Fecha o modal após a criação bem-sucedida
            } else {
                toast.error(`Erro: ${data.message}`);
            }
        } catch (error) {
            console.error('Erro ao criar o aniversariante:', error);
            toast.error('Erro ao criar o aniversariante.');
        }
    };

    // Função para fechar modal ao clicar fora do conteúdo
    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    // Carrega os eventos ao abrir o modal
    useEffect(() => {
        if (isOpen) {
            loadEvents();
        }
    }, [isOpen]);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedValue = maskPhone(e.target.value);
        setPhone(maskedValue);
    };

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{
                backdropFilter: 'blur(5px)', // Aplica o desfoque ao fundo
            }}
        >

            <ToastContainer />
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{
                    backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
                }}
            >
                <IoMdClose
                    size={20}
                    onClick={onClose}
                    className="cursor-pointer transition-colors"
                    style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    <div className="flex flex-col items-start gap-2 w-full">
                        <div className="text-[#e1ff01] text-2xl font-bold">Cadastrar Aniversariante</div>
                        <div className="text-base font-medium" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>Preencha o formulário.</div>
                    </div>

                    <div className="flex flex-col w-full">
                        <label
                            className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                            htmlFor="nomeCompleto" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Nome Completo
                        </label>

                        <input
                            id="nomeCompleto"
                            name="nomeCompleto"
                            type="text"
                            placeholder="Insira o Nome Completo"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl [font-family:'Poppins',Helvetica] text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                            style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                border: theme === "dark" ? "#575560" : "#DDDDDD",
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        />
                    </div>

                    <div className="flex gap-4 w-full">
                        <div className="flex flex-col w-[50%]">
                            <label
                                className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                                htmlFor="whatsapp"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            >
                                Whatsapp
                            </label>

                            <input
                                id="whatsapp"
                                name="whatsapp"
                                type="text"
                                value={phone}
                                onChange={handlePhoneChange}
                                placeholder="(19) 98727-2715"
                                className="w-full p-3 bg-[#42404a] text-[#f1f1f1] border border-solid border-[#575560] rounded-2xl [font-family:'Poppins',Helvetica] text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                maxLength={15}
                                style={{
                                    backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                    border: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="flex flex-col flex-1">
                            <label
                                className="text-[#f1f1f1] text-sm font-bold mb-2 [font-family:'Poppins',Helvetica]"
                                htmlFor="evento"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            >
                                Evento
                            </label>

                            <select
                                id="evento"
                                name="evento"
                                value={selectedEvent}
                                onChange={(e) => setSelectedEvent(e.target.value)}
                                className="w-full p-3 bg-[#42404a] border border-solid border-[#575560] rounded-2xl [font-family:'Poppins',Helvetica] text-base font-medium leading-6 transition-all focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#666666" : "#666666",
                                }}
                            >
                                <option value="" disabled selected hidden>Selecione</option>
                                {events.map((event) => (
                                    <option key={event.id} value={event.id}>
                                        {event.event_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                </div>

                <div className="flex w-full gap-8">
                    <button
                        className="h-10 flex-1 bg-[#42404a] text-[#f1f1f1] font-bold py-2 px-4 rounded-2xl border-none transition-all hover:bg-[#3a383d] [font-family:'Poppins',Helvetica]"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>

                    <button
                        className="h-10 flex-1 bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl border-none transition-all hover:bg-[#d1e600] [font-family:'Poppins',Helvetica]"
                        onClick={createBirthday}
                    >
                        Salvar alterações
                    </button>
                </div>
            </div>
        </div>
    );
};
