import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { SketchPicker } from 'react-color';
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Importar para redirecionar
import Switch from "react-switch"; // Biblioteca para o switch

const formatCurrency = (value: string): string => {
    value = value.replace(/\D/g, "");
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    );
    return result;
};

export const CriacaoEvento = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventType, setEventType] = useState('');
    const [categories, setCategories] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const [valorHomens, setValorHomens] = useState("0,00");
    const [valorMulheres, setValorMulheres] = useState("0,00");
    const [selectedColor, setSelectedColor] = useState('#e1ff01');
    const [customColor, setCustomColor] = useState<string | null>(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [eventImage, setEventImage] = useState<File | null>(null);
    const { theme } = useTheme();

    const [eventByBatch, setEventByBatch] = useState(false);
    const [addNamesToList, setAddNamesToList] = useState(false);

    const predefinedColors = ['#e1ff01', '#c8c8c8', '#f1f1f1'];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === ' ' && inputValue.trim() !== "") {
            if (categories.length < 3) {
                setCategories([...categories, inputValue.trim()]);
                setInputValue("");
            }
            e.preventDefault();
        }
    };

    const handleValorHomensChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValorHomens(formatCurrency(e.target.value));
    };

    const handleValorMulheresChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValorMulheres(formatCurrency(e.target.value));
    };

    const handlePredefinedColorClick = (color: string) => {
        setSelectedColor(color);
        setCustomColor(null);
    };

    const handleCustomColorClick = () => {
        setIsPickerOpen(!isPickerOpen);
    }

    const handleColorChange = (color: any) => {
        setSelectedColor(color.hex);
        setCustomColor(color.hex);
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setEventImage(e.target.files[0]);
        }
    };

    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (!eventImage) {
            toast.error("A imagem do evento é obrigatória.");
            return;
        }

        if (!eventName.trim()) {
            toast.error("O nome do evento é obrigatório.");
            return;
        }

        if (!eventDate) {
            toast.error("A data do evento é obrigatória.");
            return;
        }

        if (parseFloat(valorHomens.replace(",", ".")) <= 0 || parseFloat(valorMulheres.replace(",", ".")) <= 0) {
            toast.error("Os valores dos ingressos devem ser maiores que zero.");
            return;
        }

        if (categories.length === 0) {
            toast.error("Pelo menos uma categoria é obrigatória.");
            return;
        }

        if (isSubmitting) { // Verifica se já está enviando para não permitir outro clique
            return;
        }

        setIsSubmitting(true); // Bloquear o botão ao clicar

        try {
            let imageUrl = null;

            if (eventImage) {
                const formData = new FormData();
                formData.append('image', eventImage);

                const uploadResponse = await fetch(`${API_URL}/api/upload`, {
                    method: 'POST',
                    body: formData,
                });

                if (!uploadResponse.ok) {
                    throw new Error('Erro ao fazer upload da imagem');
                }

                const uploadData = await uploadResponse.json();
                imageUrl = uploadData.imageUrl;
            }

            const response = await fetch(`${API_URL}/api/create-event`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: localStorage.getItem('companyId'),
                    event_name: eventName,
                    event_date: eventDate,
                    event_type: eventType,
                    category: categories.join(', '),
                    ticket_price_men: parseFloat(valorHomens.replace(",", ".")),
                    ticket_price_women: parseFloat(valorMulheres.replace(",", ".")),
                    event_color: customColor || selectedColor,
                    event_image_url: imageUrl,
                    attractions,
                    event_batch: eventByBatch ? 'active' : 'inactive',  // Salva o estado do switch eventByBatch
                    guest_list: addNamesToList ? 'active' : 'inactive',  // Salva o estado do switch addNamesToList
                }),
            });

            if (response.ok) {
                toast.success("Evento criado com sucesso!");
                setTimeout(() => {
                    navigate('/eventos'); // Redirecionar após 1 segundo
                }, 1000); // Aguardar 1 segundo antes do redirecionamento
            } else {
                toast.error("Tivemos um problema ao criar o evento, verifique e tente novamente!");
                setIsSubmitting(false); // Permitir clicar novamente em caso de erro
            }
        } catch (error) {
            console.error(error);
            setIsSubmitting(false); // Permitir clicar novamente em caso de erro
        }
    };



    const [attractions, setAttractions] = useState<string[]>([]);
    const [attractionInputValue, setAttractionInputValue] = useState<string>("");

    const handleAttractionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAttractionInputValue(e.target.value);
    };

    const handleAttractionKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && attractionInputValue.trim() !== "") {
            if (attractions.length < 4) {
                setAttractions([...attractions, attractionInputValue.trim()]);
                setAttractionInputValue("");
            }
            e.preventDefault();
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6">
                    <h1 className="text-4xl font-bold text-[#f1f1f1] [font-family:'Poppins',Helvetica]" style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}>Criar novo evento</h1>
                    <p className="text-base font-medium text-[#ffffff66] [font-family:'Poppins',Helvetica]" style={{
                        color: theme === "dark" ? "#ffffff66" : "#545454",
                    }}>
                        Preencha o formulário para criar um novo evento.
                    </p>
                </div>

                <div className="border-b border-[#575560] pb-2 mb-4">
                    <h3 className="text-base font-bold text-[#e1ff01] mb-2">Informações do Evento</h3>
                    <div className="flex flex-col md:flex-row flex-wrap gap-4">
                        <div className="w-full md:w-[48%]">
                            <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}>Nome do evento</h3>
                            <input
                                type="text"
                                placeholder="Insira um nome para o evento"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                className="h-11 w-full bg-[#2f2e39] text-[#666666] rounded-2xl border border-[#575560] px-3 focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    border: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full md:w-[48%]">
                            <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}>Data do evento</h3>
                            <input
                                type="date"
                                value={eventDate}
                                onChange={(e) => setEventDate(e.target.value)}
                                className="h-11 w-full bg-[#2f2e39] text-[#666666] rounded-2xl border border-[#575560] px-3 focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    border: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }} />
                        </div>

                        <div className="w-full md:w-[48%]">
                            <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}>Categoria</h3>
                            <div
                                className="h-11 w-full rounded-2xl border px-3 flex items-center gap-2"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB", // Cor do fundo com base no tema
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD", // Cor da borda com base no tema
                                }}
                            >
                                <div className="flex flex-wrap gap-2">
                                    {categories.map((category, index) => (
                                        <span
                                            key={index}
                                            className="px-2 py-1 rounded-lg text-sm"
                                            style={{
                                                backgroundColor: theme === "dark" ? "#e1ff01" : "#e1ff01", // Cor da tag de categoria com base no tema
                                                color: theme === "dark" ? "#302e38" : "#302e38", // Cor do texto da tag com base no tema
                                            }}
                                        >
                                            {category}
                                        </span>
                                    ))}
                                </div>
                                <input
                                    type="text"
                                    placeholder={categories.length < 3 ? "Digite e pressione espaço" : ""}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    className={`flex-1 bg-transparent focus:outline-none ${theme === "dark" ? "placeholder-dark" : "placeholder-light"}`}
                                    disabled={categories.length >= 3}
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do texto do input com base no tema
                                    }}
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-[48%]">
                            <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}>Atrações</h3>
                            <div
                                className="h-11 w-full rounded-2xl border px-3 flex items-center gap-2"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB", // Cor do fundo com base no tema
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD", // Cor da borda com base no tema
                                }}
                            >
                                <div className="flex flex-wrap gap-2">
                                    {attractions.map((attraction, index) => (
                                        <span
                                            key={index}
                                            className="px-2 py-1 rounded-lg text-sm"
                                            style={{
                                                backgroundColor: theme === "dark" ? "#e1ff01" : "#e1ff01", // Cor da tag de atração com base no tema
                                                color: theme === "dark" ? "#302e38" : "#302e38", // Cor do texto da tag com base no tema
                                            }}
                                        >
                                            {attraction}
                                        </span>
                                    ))}
                                </div>
                                <input
                                    type="text"
                                    placeholder={attractions.length < 4 ? "Digite e pressione Enter" : ""}
                                    value={attractionInputValue}
                                    onChange={handleAttractionInputChange}
                                    onKeyDown={handleAttractionKeyDown}
                                    className={`flex-1 bg-transparent focus:outline-none ${theme === "dark" ? "placeholder-dark" : "placeholder-light"}`}
                                    disabled={attractions.length >= 4}
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do texto do input com base no tema
                                    }}
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-[48%] flex items-center">
                            <div className="w-full">
                                <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Tipo de evento</h3>
                                <input
                                    type="text"
                                    value={eventType}
                                    onChange={(e) => setEventType(e.target.value)}
                                    placeholder="Tipo de evento"
                                    className="h-11 w-full bg-[#2f2e39] text-[#666666] rounded-2xl border border-[#575560] px-3 focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                        border: theme === "dark" ? "#575560" : "#DDDDDD",
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                />
                            </div>

                            <div className="flex items-center gap-4 ml-4 w-full mt-6">
                                <div className="flex items-center">
                                    <Switch
                                        checked={eventByBatch}
                                        onChange={setEventByBatch}
                                        onColor="#e1ff01"
                                        offColor={theme === "dark" ? "#575560" : "#CACBCB"}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={22}
                                        width={44}
                                    />
                                    <label className="ml-2 text-[#e1ff01]">Evento por lote?</label>
                                </div>

                                <div className="flex items-center">
                                    <Switch
                                        checked={addNamesToList}
                                        onChange={setAddNamesToList}
                                        onColor="#e1ff01"
                                        offColor={theme === "dark" ? "#575560" : "#CACBCB"}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        height={22}
                                        width={44}
                                    />
                                    <label className="ml-2 text-[#e1ff01]">Adicionar nomes na lista?</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="mt-4 max-w-[800px] border-b border-[#575560] pb-4">
                    <h3 className="text-base font-bold text-[#e1ff01] mb-4">Valor do Ingresso</h3>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="w-full md:w-[48%]">
                            <h3
                                className="text-sm font-bold mb-1"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do título com base no tema
                                }}
                            >
                                Para homens
                            </h3>
                            <div
                                className="h-11 rounded-2xl border flex items-center px-3"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB", // Cor de fundo do input com base no tema
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD", // Cor da borda com base no tema
                                }}
                            >
                                <span className="text-[#e1ff01]">R$</span>
                                <input
                                    type="text"
                                    value={valorHomens}
                                    onChange={handleValorHomensChange}
                                    className="w-full bg-transparent px-2 focus:outline-none"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do texto do input com base no tema
                                    }}
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-[48%]">
                            <h3
                                className="text-sm font-bold mb-1"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do título com base no tema
                                }}
                            >
                                Para mulheres
                            </h3>
                            <div
                                className="h-11 rounded-2xl border flex items-center px-3"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB", // Cor de fundo do input com base no tema
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD", // Cor da borda com base no tema
                                }}
                            >
                                <span className="text-[#e1ff01]">R$</span>
                                <input
                                    type="text"
                                    value={valorMulheres}
                                    onChange={handleValorMulheresChange}
                                    className="w-full bg-transparent px-2 focus:outline-none"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do texto do input com base no tema
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="mt-4 border-b border-[#575560] pb-4">
                    <h3 className="text-base font-bold text-[#e1ff01] mb-4" >Lista de Convidados</h3>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="w-full md:w-[48%]">
                            <h3 className="text-sm font-bold text-[#f1f1f1] mb-1" style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}>Cor do Fundo</h3>
                            <div className="flex items-center gap-2.5">
                                {customColor && (
                                    <div
                                        className={`relative w-[38px] h-[38px] rounded-[19px] border-4 border-solid border-[#302e38] cursor-pointer shadow-[0px_0px_0px_2px_#e1ff01]`}
                                        style={{ backgroundColor: customColor }}
                                        onClick={() => {
                                            handleCustomColorClick();
                                        }}
                                    />
                                )}

                                {predefinedColors.map((color, index) => (
                                    <div
                                        key={index}
                                        className={`relative w-[38px] h-[38px] rounded-[19px] border-4 border-solid border-[#302e38] ${selectedColor === color && !customColor ? 'shadow-[0px_0px_0px_2px_#e1ff01]' : ''} cursor-pointer`}
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            handlePredefinedColorClick(color);
                                        }}
                                    />
                                ))}

                                <div className={`relative w-[38px] h-[38px] cursor-pointer`} onClick={() => { handleCustomColorClick(); }}>
                                    <img className="w-full h-full" alt="Escolher cor personalizada" src="https://c.animaapp.com/z4rpfJ0E/img/-.svg" />
                                </div>

                                {isPickerOpen && (
                                    <div className="absolute left-[420px] z-50">
                                        <SketchPicker color={selectedColor} onChange={handleColorChange} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-full md:w-[48%]">
                            <h3
                                className="text-sm font-bold mb-1"
                                style={{
                                    color: theme === "dark" ? "#F1F1F1" : "#000000", // Cor do título com base no tema
                                }}
                            >
                                Imagem do Evento
                            </h3>
                            <div
                                className="flex items-center gap-2 rounded-2xl border h-11 px-4"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB", // Cor de fundo do input com base no tema
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD", // Cor da borda com base no tema
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="bg-transparent flex-1 text-sm file:mr-4 file:py-2 file:px-4 file:rounded-xl file:border-0 file:text-sm file:font-semibold file:bg-[#e1ff01] file:text-[#302e38] focus:outline-none"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                        borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                />
                            </div>

                            <style>
                                {`
            .file:hover {
                background-color: #b8cc00; /* Cor hover do botão de arquivo */
            }
        `}
                            </style>
                        </div>

                    </div>
                </div>

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="mt-10 flex flex-col md:flex-row gap-4 justify-start">
                    <button className="w-full md:w-[48%] py-2.5 px-10 bg-[#2f2e39] text-[#f1f1f1] font-bold rounded-2xl border border-[#575560] hover:bg-[#3c3c47] transition-all">
                        Cancelar
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={`w-full md:w-[48%] py-2.5 px-10 font-bold rounded-2xl transition-all ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#e1ff01] hover:bg-[#b8cc00] text-[#302e38]'}`}
                        disabled={isSubmitting}
                    >
                        Salvar Evento
                    </button>
                </div>

            </div>
        </div>
    );
};
