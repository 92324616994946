import React, { useState } from 'react';
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

interface ModalPlanoProps {
    isOpen: boolean;
    onClose: () => void;
}

const maskPhone = (value: string) => {
    value = value.replace(/\D/g, "");

    if (value.length > 10) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length > 5) {
        value = value.replace(/^(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    } else if (value.length > 2) {
        value = value.replace(/^(\d{2})(\d{0,5})/, "($1) $2");
    } else {
        value = value.replace(/^(\d*)/, "($1");
    }

    return value;
};

export const ModalPlano = ({ isOpen, onClose }: ModalPlanoProps): JSX.Element | null => {
    if (!isOpen) return null;
    
    const navigate = useNavigate();

    const handleViewPlans = () => {
        navigate('/planos');
    }
    const [phone, setPhone] = useState("");
    const { theme } = useTheme();

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedValue = maskPhone(e.target.value);
        setPhone(maskedValue);
    };

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };



    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{
                backdropFilter: 'blur(5px)',
            }}
        >
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{
                    backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
                }}
            >
                <IoMdClose
                    size={20}
                    onClick={onClose}
                    className="cursor-pointer transition-colors"
                    style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}
                />

                <div className="flex flex-col items-center gap-5 w-full">
                    <div className="flex flex-col items-center gap-2 w-full"> 
                        <div className="text-[#e1ff01] text-2xl font-bold text-center">O plano da sua empresa expirou!</div>
                        <div className="text-base font-medium text-center" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>
                            Renove sua assinatura para continuar acessando todas as funcionalidades do painel. Veja nossos planos e escolha o melhor para você.
                        </div>
                    </div>
                </div>

                <div className="flex w-full justify-center gap-8">
                    <button
                        className="h-10 flex-1 bg-[#e1ff01] text-[#302e38] font-bold py-2 px-4 rounded-2xl border-none transition-all hover:bg-[#d1e600] [font-family:'Poppins',Helvetica]"
                        onClick={handleViewPlans}
                    >
                        Ver planos
                    </button>
                </div>
            </div>
        </div>
    );
};
