import React, { useState, useEffect } from "react";
import { useTheme } from "../../ThemeContext";
import { IoMdClose } from "react-icons/io";
import { AiOutlineLoading3Quarters, AiOutlineCheckCircle, AiOutlineCopy } from "react-icons/ai";
import { API_URL } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import para redirecionar
import "react-toastify/dist/ReactToastify.css";

interface ModalPagamentoPlanoProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    price: string;
    userData: {
        full_name: string;
        cpf: string;
        email: string;
        telefone: string;
        endereco: string;
        cidade: string;
        estado: string;
        cep: string;
    };
}

export const ModalPagamentoPlano = ({
    isOpen,
    onClose,
    title,
    price,
    userData,
}: ModalPagamentoPlanoProps): JSX.Element | null => {
    const { theme } = useTheme();
    const navigate = useNavigate(); // Hook para redirecionar
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [pixCopyPaste, setPixCopyPaste] = useState<string | null>(null);
    const [paymentId, setPaymentId] = useState<string | null>(null);
    const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
    const [isMonitoring, setIsMonitoring] = useState<boolean>(false);
    const [createCompany, setCreateCompany] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>("");

    const fetchQrCode = async () => {
        if (!userData.full_name || userData.full_name.trim() === "") {
            toast.error("O campo Nome Completo é obrigatório.", {
                position: "top-center",
            });
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/generate-qrcode`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ title, price, userData }),
            });

            const data = await response.json();
            if (response.ok && data.qrCode) {
                setQrCode(data.qrCode);
                setPixCopyPaste(data.pixCopyPaste);
                setPaymentId(data.paymentId);
                setPaymentStatus("pending"); // Inicia com status "pending"
                setIsMonitoring(true); // Ativa o monitoramento
            } else {
                toast.error("Erro ao gerar QR Code para pagamento.", {
                    position: "top-center",
                });
            }
        } catch (error) {
            console.error("Erro ao buscar QR Code:", error);
            toast.error("Erro ao processar pagamento. Tente novamente mais tarde.", {
                position: "top-center",
            });
        }
    };

    const fetchPaymentStatus = async () => {
        if (!paymentId) {
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/payment-status/${paymentId}`);
            const data = await response.json();

            if (response.ok) {
                setPaymentStatus(data.status);

                if (data.status === "approved") {
                    setIsMonitoring(false);

                    if (data.message === "Pagamento aprovado, mas é necessário criar a empresa para finalizar.") {
                        setCreateCompany(true);
                        toast.info("Pagamento aprovado, mas é necessário criar a empresa para finalizar.", {
                            position: "top-center",
                        });
                    } else if (data.message === "Pagamento aprovado. Usuário criado. Por favor, finalize a criação da empresa.") {
                        setCreateCompany(true);
                        toast.info("Usuário criado! Crie sua empresa para finalizar.", {
                            position: "top-center",
                        });
                    } else {
                        toast.success("Pagamento aprovado e assinatura atualizada com sucesso!", {
                            position: "top-center",
                        });
                    }
                }
            } else {
                toast.error("Erro ao consultar status do pagamento.", {
                    position: "top-center",
                });
            }
        } catch (error) {
            toast.error("Erro ao consultar status do pagamento.", {
                position: "top-center",
            });
        }
    };

    const handleCreateCompany = async () => {
        if (!companyName) {
            toast.error("O nome da empresa é obrigatório.", {
                position: "top-center",
            });
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/create-company`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ companyName, userEmail: userData.email }),
            });

            if (response.ok) {
                toast.success("Empresa criada e assinatura configurada com sucesso!", {
                    position: "top-center",
                });
                setCreateCompany(false);
                setPaymentStatus("approved"); // Atualiza para "approved" após criar a empresa
            } else {
                toast.error("Erro ao criar a empresa. Tente novamente.", {
                    position: "top-center",
                });
            }
        } catch (error) {
            console.error("Erro ao criar a empresa:", error);
            toast.error("Erro ao criar a empresa. Tente novamente.", {
                position: "top-center",
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchQrCode();
        }
    }, [isOpen]);

    useEffect(() => {
        if (isMonitoring && paymentStatus === "pending") {
            const interval = setInterval(fetchPaymentStatus, 5000); // Monitorar a cada 5 segundos
            return () => clearInterval(interval); // Limpar o intervalo quando não monitorar mais
        }
    }, [isMonitoring, paymentStatus]);

    const handleCopy = () => {
        if (pixCopyPaste) {
            navigator.clipboard.writeText(pixCopyPaste);
            toast.success("Código Pix copiado para a área de transferência!", {
                position: "top-center",
            });
        }
    };

    const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center"
            onClick={handleClickOutside}
            style={{ backdropFilter: "blur(5px)" }}
        >
            <ToastContainer />
            <div
                className="relative z-50 flex flex-col w-[480px] items-end gap-8 p-6 rounded-[20px] border border-solid border-[#575560]"
                style={{ backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6" }}
            >
                <IoMdClose
                    size={20}
                    onClick={() => onClose()}
                    className="cursor-pointer transition-colors"
                    style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                />

                <div className="flex-col items-start gap-5 w-full flex">
                    {!qrCode && !paymentStatus && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <AiOutlineLoading3Quarters className="animate-spin" size={48} style={{ color: "#e1ff01" }} />
                            <div className="text-[#e1ff01] text-2xl font-bold">Aguardando QR Code</div>
                            <div
                                className="text-base font-medium"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Estamos gerando o QR Code para o pagamento. Por favor, aguarde.
                            </div>
                        </div>
                    )}

                    {qrCode && paymentStatus === "pending" && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <div className="text-[#e1ff01] text-2xl font-bold">Realize seu pagamento</div>
                            <img src={qrCode} alt="QR Code para pagamento" className="w-full h-auto" />
                            <div className="flex items-center gap-2 w-full">
                                <input
                                    type="text"
                                    value={pixCopyPaste || ""}
                                    readOnly
                                    className="flex-1 p-2 rounded border"
                                    style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                        backgroundColor: theme === "dark" ? "#333" : "#fff",
                                    }}
                                />
                                <button onClick={handleCopy} className="p-2 rounded bg-[#e1ff01] text-black">
                                    <AiOutlineCopy size={20} />
                                </button>
                            </div>
                            <div
                                className="text-sm font-medium"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Use o QR Code ou copie o código Pix para realizar o pagamento.
                            </div>
                        </div>
                    )}

                    {createCompany && (
                        <div className="flex flex-col gap-4 w-full">
                            <div className="text-[#e1ff01] text-2xl font-bold">Crie sua empresa</div>
                            <div
                                className="text-base font-medium text-[#F1F1F1]"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Defina o nome da sua empresa para finalizar a criação e começar a usar nosso sistema.
                            </div>
                            <input
                                type="text"
                                placeholder="Digite o nome da empresa"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className="rounded-2xl w-full p-3 bg-transparent bg-[#2f2e39] text-[#F1F1F1] placeholder-[#F1F1F1] border border-[#575560] focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                            />
                            <button
                                onClick={handleCreateCompany}
                                className="p-2 rounded-2xl bg-[#e1ff01] text-black"
                            >
                                Criar Empresa
                            </button>
                        </div>
                    )}

                    {paymentStatus === "approved" && !createCompany && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <AiOutlineCheckCircle size={48} style={{ color: "#28a745" }} />
                            <div className="text-[#28a745] text-2xl font-bold">Pagamento aprovado e assinatura definida!</div>
                            <div
                                className="text-base font-medium"
                                style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}
                            >
                                Sua conta foi criada automaticamente. A senha foi enviada para o seu e-mail.
                            </div>
                            <button
                                onClick={() => navigate("/login")}
                                className="p-3 rounded-2xl bg-[#e1ff01] text-black font-bold"
                            >
                                Ir para o Login
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
