import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

interface ProtectedRouteProps {
    children: JSX.Element;
    requiredRoles: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredRoles }) => {
    const { isLoggedIn, loading } = useContext(AuthContext);
    const userRole = localStorage.getItem("role");
    const isento = localStorage.getItem("isento") === "true";
    
    // Log inicial do estado das variáveis principais
    console.log("ProtectedRoute: Estado inicial");
    console.log("isLoggedIn:", isLoggedIn);
    console.log("loading:", loading);
    console.log("userRole:", userRole);
    console.log("isento:", isento);
    console.log("requiredRoles:", requiredRoles);

    if (loading) {
        console.log("ProtectedRoute: Carregando...");
        return <div>Carregando...</div>;
    }

    if (!isLoggedIn) {
        console.log("ProtectedRoute: Usuário não está logado, redirecionando para /login");
        return <Navigate to="/login" replace />;
    }

    if (isento) {
        console.log("ProtectedRoute: Usuário é isento, permitindo acesso irrestrito.");
        return children;
    }

    if (userRole && !requiredRoles.includes(userRole)) {
        console.log(`ProtectedRoute: Usuário com role "${userRole}" não possui permissão para acessar, redirecionando para /eventos`);
        return <Navigate to="/eventos" replace />;
    }

    console.log("ProtectedRoute: Acesso permitido, renderizando componente filho.");
    return children;
}