import React, { useState, useEffect, useContext } from "react";
import logo from "../../imagens/logo.png";
import { API_URL } from "../../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalLogin } from "../../components/ModalLogin";
import { ModalPlano } from "../../components/ModalPlano";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

export const Login = (): JSX.Element => {
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const [rememberMe, setRememberMe] = useState(false);
    const [attemptReconnect, setAttemptReconnect] = useState(false);

    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const handleModal2Open = () => {
        setIsModal2Open(true);
    };

    const handleModal2Close = () => {
        setIsModal2Open(false);
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const savedEmail = localStorage.getItem("rememberedEmail");
        const savedRememberMe = localStorage.getItem("rememberMe");
        if (savedEmail && savedRememberMe === "true") {
            setFormData({ ...formData, email: savedEmail });
            setRememberMe(true);
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setRememberMe(isChecked);

        if (!isChecked) {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberMe");
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (rememberMe) {
            localStorage.setItem("rememberedEmail", formData.email);
            localStorage.setItem("rememberMe", "true");
        }

        if (attemptReconnect) {
            handleReconnect();
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password
                })
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem("authToken", data.sessionToken);
                localStorage.setItem("fullName", data.full_name);
                localStorage.setItem("companyId", data.company_id);
                localStorage.setItem("role", data.role);

                login(data.sessionToken);
                toast.success("Login bem-sucedido.");
                navigate("/eventos");
            } else if (data.reconnect) {
                toast.warning("Já existe um usuário logado. Tente novamente para realizar o login.");
                setAttemptReconnect(true);
            } else if (data.message === "Usuário não está vinculado a nenhuma empresa.") {
                handleModalOpen();
            } else if (data.message === "A empresa do usuário não possui uma assinatura ativa.") {
                handleModal2Open();
            } else {
                toast.error(data.message || "Erro ao realizar o login.");
            }
        } catch (error) {
            toast.error("Erro ao conectar com o servidor.");
        }
    };

    const handleReconnect = async () => {
        try {
            const response = await fetch(`${API_URL}/api/reconnect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password
                })
            });

            const data = await response.json();

            if (response.ok) {
                login(data.sessionToken); // Passa o token para o contexto de autenticação
                toast.success("Reconexão bem-sucedida.");
                navigate("/eventos");
            } else {
                toast.error(data.message || "Erro ao reconectar.");
            }
        } catch (error) {
            toast.error("Erro ao conectar com o servidor.");
        }
    };

    return (
        <div className="relative w-full h-full min-h-screen bg-[#1b2026] overflow-hidden">
            <ToastContainer />
            <div className="relative w-full h-auto overflow-hidden">
                <div className="absolute w-full h-full top-0 left-0 opacity-[0.2] bg-[url(https://c.animaapp.com/RLk3Kegs/img/glow.svg)] bg-cover bg-center overflow-hidden"></div>

                <div className="flex justify-center items-center w-full h-screen bg-[#1b2026]">
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col w-full max-w-lg h-auto p-10 gap-8 rounded-[20px] border border-solid border-[#707070] flex items-center"
                    >
                        <div className="relative w-52 h-10">
                            <img
                                className="w-[215px] max-w-full h-auto bg-blend-soft-light"
                                srcSet={`${logo} 1x, ${logo}@2x.png 2x, ${logo}@3x.png 3x`}
                                src={logo}
                                alt="Logo"
                            />
                        </div>

                        <header className="flex flex-col items-center gap-6 relative self-stretch w-full flex-[0_0_auto] bg-transparent">
                            <div className="flex flex-col items-start gap-3 relative self-stretch w-full">
                                <div className="self-stretch font-semibold text-[#e1ff01] text-3xl text-center leading-[38px] tracking-[0]">
                                    Entre na sua conta
                                </div>
                                <p className="relative self-stretch font-medium text-[#ffffff] text-base text-center tracking-[0] leading-6">
                                    Insira suas credenciais para entrar na sua conta.
                                </p>
                            </div>
                        </header>

                        <div className="flex-col gap-6 relative self-stretch w-full rounded-xl flex items-center">
                            <div className="flex flex-col items-start gap-5 relative self-stretch w-full">
                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid border-1 rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira seu email"
                                    />
                                </div>

                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Senha
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid border-1 rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira sua senha"
                                    />
                                </div>
                            </div>

                            <div className="w-full flex justify-between items-center">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="remember"
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        className="mr-2 appearance-none w-5 h-5 rounded-full bg-[#302f39] border border-[#575561] checked:bg-[#e1ff01] checked:border-none focus:ring-2 focus:ring-offset-2 focus:ring-[#e1ff01]"
                                    />
                                    <label
                                        htmlFor="remember"
                                        className="text-[#ffffff] text-sm"
                                    >
                                        Lembrar de mim
                                    </label>
                                </div>

                                <a
                                    href="/recuperar"
                                    className="text-[#e1ff01] text-sm font-semibold transition duration-300 ease-in-out hover:text-[#a8bf00] hover:underline"
                                >
                                    Esqueceu sua senha?
                                </a>
                            </div>

                            <button
                                type="submit"
                                className="self-stretch h-10 bg-[#e1ff01] border-none text-[#22252a] font-semibold rounded-2xl transition duration-300 ease-in-out transform hover:bg-[#a8bf00] hover:scale-105"
                            >
                                Entrar
                            </button>

                        </div>

                        <div className="flex items-start justify-center gap-1 w-full">
                            <div className="font-medium text-[#ffffff] text-sm leading-5">
                                Não tem uma conta?
                            </div>
                            <a
                                href="/cadastro"
                                className="text-[#e1ff01] text-sm font-semibold z-50  transition duration-300 ease-in-out hover:text-[#a8bf00] hover:underline"
                            >
                                Cadastre-se
                            </a>
                        </div>
                    </form>
                </div>
            </div>

            <ModalLogin isOpen={isModalOpen} onClose={handleModalClose} />
            <ModalPlano isOpen={isModal2Open} onClose={handleModal2Close} />
        </div>
    );
};
