import React, { useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { Menu } from "../../components/Menu";
import { Help } from "../../components/Help";
import { ConteudoDesktop } from "../../components/ConteudoDesktop";
import { ConteudoMobile } from "../../components/ConteudoMobile";

export const PaginaPrincipal = (): JSX.Element => {
    const [isDesktop] = useMediaQuery("(min-width: 768px)");

    return (
        <div className="relative w-full min-h-screen bg-[#1b2026] overflow-hidden">
            <Menu />
            <div
                className="fixed inset-0 bg-cover bg-center opacity-10 z-0"
                style={{ backgroundImage: `url(https://c.animaapp.com/RLk3Kegs/img/glow.svg)` }}
            />

            <div className="relative flex flex-col items-center">
                {isDesktop ? <ConteudoDesktop /> : <ConteudoMobile />}
            </div>
        </div>
    );
};
