import React, { useState } from "react";
import logo from "../../imagens/logo.png";
import { API_URL } from "../../config";  
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export const Cadastro = (): JSX.Element => {
    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            toast.error("As senhas não coincidem.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    full_name: formData.full_name,
                    email: formData.email,
                    password: formData.password
                })
            });

            const data = await response.json();

            if (response.ok) {
                toast.success("Usuário registrado com sucesso.");
                setTimeout(() => {
                    navigate('/login');  
                }, 2000);  
            } else {
                toast.error(data.message || "Erro ao registrar o usuário.");
            }
        } catch (error) {
            toast.error("Erro ao conectar com o servidor.");
        }
    };

    return (
        <div className="relative w-full h-full min-h-screen bg-[#1b2026] overflow-hidden">
            <ToastContainer />
            <div className="relative w-full h-auto overflow-hidden">
                <div className="absolute w-full h-full top-0 left-0 opacity-[0.2] bg-[url(https://c.animaapp.com/RLk3Kegs/img/glow.svg)] bg-cover bg-center overflow-hidden"></div>

                <div className="flex justify-center items-center w-full h-screen bg-[#1b2026]">
                    <form
                        onSubmit={handleSubmit}
                        className="flex flex-col w-full max-w-lg h-auto p-10 gap-8 rounded-[20px] border border-solid border-[#707070] items-center"
                    >
                        <div className="relative w-52 h-10">
                            <img
                                className="w-[215px] max-w-full h-auto bg-blend-soft-light"
                                srcSet={`${logo} 1x, ${logo}@2x.png 2x, ${logo}@3x.png 3x`}
                                src={logo}
                                alt="Logo"
                            />
                        </div>

                        <header className="flex flex-col items-center gap-6 relative self-stretch w-full bg-transparent">
                            <div className="flex flex-col items-start gap-3 relative self-stretch w-full">
                                <div className="self-stretch font-semibold text-[#e1ff01] text-3xl text-center leading-[38px]">
                                    Crie uma nova conta
                                </div>
                                <p className="relative self-stretch font-medium text-[#ffffff] text-base text-center leading-6">
                                    Insira suas credenciais para criar uma nova conta.
                                </p>
                            </div>
                        </header>

                        <div className="flex flex-col gap-6 relative self-stretch w-full rounded-xl items-center">
                            <div className="flex flex-col items-start gap-5 relative self-stretch w-full">
                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Nome Completo
                                    </label>
                                    <input
                                        type="text"
                                        name="full_name"
                                        value={formData.full_name}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira o seu nome"
                                    />
                                </div>

                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira seu email"
                                    />
                                </div>

                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Senha
                                    </label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira sua senha"
                                    />
                                </div>

                                <div className="w-full">
                                    <label className="text-[#ffffff] text-sm font-semibold leading-5">
                                        Confirme sua senha
                                    </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        className="w-full mt-1 h-11 border border-[#575561] border-solid rounded-2xl bg-[#302f39] text-white placeholder-[#666666] text-base font-medium leading-6 p-3"
                                        placeholder="Insira sua senha novamente"
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="self-stretch h-10 bg-[#e1ff01] border-none text-[#22252a] font-semibold rounded-2xl transition duration-300 ease-in-out transform hover:bg-[#a8bf00] hover:scale-105"
                            >
                                Criar uma nova conta
                            </button>
                        </div>

                        <div className="flex items-start justify-center gap-1 w-full">
                            <div className="font-medium text-[#ffffff] text-sm leading-5">
                                Já tem uma conta?
                            </div>
                            <a
                                href="/login"
                                className="text-[#e1ff01] text-sm z-50  font-semibold transition duration-300 ease-in-out hover:text-[#a8bf00] hover:underline"
                            >
                                Inicie sua sessão
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
