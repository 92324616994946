import React, { useState, useEffect } from "react";
import { BotO } from "../../components/BotO";
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { API_URL } from "../../config";
import { SketchPicker } from 'react-color';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from "../../ThemeContext";
import { Switch } from "@chakra-ui/react";


export const Configuracao = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const predefinedColors = ['#e1ff01', '#c8c8c8', '#f1f1f1'];
    const { theme } = useTheme();
    const [selectedColor, setSelectedColor] = useState('#e1ff01');
    const [customColor, setCustomColor] = useState<string | null>(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [blurEffect, setBlurEffect] = useState<string>("inactive"); // Estado para efeito de blur
    const [role, setRole] = useState<string>("");
    const [eventImage, setEventImage] = useState<File | null>(null);

    const toggleBlurEffect = () => {
        setBlurEffect(blurEffect === "active" ? "inactive" : "active");
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setEventImage(e.target.files[0]);
        }
    };

    const [formData, setFormData] = useState({
        nome: "",
        email: "",
        telefone: "",
        nascimento: "",
        cpf: "",
        rg: "",
        chavePix: "",
        dominio: "",
        backgroundLink: "",
        logoLink: "",
    });

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fullName = localStorage.getItem('fullName');
                if (!fullName) {
                    console.error("Nome completo não encontrado no localStorage");
                    return;
                }

                const response = await fetch(`${API_URL}/api/user-infos?full_name=${encodeURIComponent(fullName)}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setFormData({
                        nome: data.full_name || "",
                        email: data.email || "",
                        telefone: data.phone || "",
                        nascimento: data.birth_date || "",
                        cpf: data.cpf || "",
                        rg: data.rg || "",
                        chavePix: data.pix_key || "",
                        dominio: data.company.domain || "",
                        backgroundLink: data.company.background_url || "",
                        logoLink: data.company.logo_url || "",
                    });
                    
                    setBlurEffect(data.company.blur_effect || "inactive");
                    setRole(data.role || "");
                    if (data.company?.background_color && !predefinedColors.includes(data.company.background_color)) {
                        setCustomColor(data.company.background_color);
                        setSelectedColor(data.company.background_color);
                    } else if (data.company?.background_color) {
                        setSelectedColor(data.company.background_color);
                        setCustomColor(null);
                    }
                } else {
                    console.error("Erro ao buscar os dados do usuário.");
                }
            } catch (error) {
                console.error("Erro na requisição:", error);
            }
        };

        fetchUserInfo();
    }, []);

    const handleSaveChanges = async () => {
        try {
            const company_id = localStorage.getItem('companyId');
            let uploadedImageUrl = formData.backgroundLink; // Valor padrão se não houver nova imagem

            if (eventImage) {
                const formDataUpload = new FormData();
                formDataUpload.append('image', eventImage);

                // Enviar o arquivo para a API de upload
                const uploadResponse = await fetch(`${API_URL}/api/upload`, {
                    method: "POST",
                    body: formDataUpload,
                });

                if (uploadResponse.ok) {
                    const uploadData = await uploadResponse.json();
                    uploadedImageUrl = uploadData.imageUrl; // Obter o caminho da imagem retornada pela API
                } else {
                    toast.error("Erro ao fazer upload da imagem.");
                    return;
                }
            }

            // Enviar os dados atualizados junto com o caminho da imagem para a API
            const formDataToSend = {
                company_id: company_id || '',
                full_name: formData.nome,
                email: formData.email,
                phone: formData.telefone,
                birth_date: formData.nascimento,
                cpf: formData.cpf,
                rg: formData.rg,
                pix_key: formData.chavePix,
                domain: formData.dominio,
                logo_url: formData.logoLink,
                background_url: uploadedImageUrl, // Usar a URL da imagem recebida após o upload
                background_color: customColor || selectedColor,
                blur_effect: blurEffect,
            };

            // Atualizar os dados do usuário na API
            const response = await fetch(`${API_URL}/api/update-user-info`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formDataToSend),
            });

            if (response.ok) {
                localStorage.setItem('fullName', formData.nome);
                toast.success("Informações atualizadas com sucesso!");
            } else {
                console.error("Erro ao atualizar informações.");
                toast.error("Erro ao atualizar as informações.");
            }
        } catch (error) {
            console.error("Erro na requisição:", error);
            toast.error("Erro ao processar a requisição.");
        }
    };



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePredefinedColorClick = (color: string) => {
        if (role === 'ceo') {
            setSelectedColor(color);
            setCustomColor(null);
        }
    };

    const handleCustomColorClick = () => {
        if (role === 'ceo') {
            setIsPickerOpen(!isPickerOpen);
        }
    };

    const handleColorChange = (color: any) => {
        setSelectedColor(color.hex);
        setCustomColor(color.hex);
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6">
                    <h1 className="text-4xl font-bold text-[#f1f1f1] [font-family:'Poppins',Helvetica]" style={{
                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                    }}>Configurações</h1>
                    <p className="text-base font-medium text-[#ffffff66] [font-family:'Poppins',Helvetica]" style={{
                        color: theme === "dark" ? "#ffffff66" : "#545454",
                    }}>
                        Ajuste e personalize seu painel de controle para melhor atender às suas necessidades.
                    </p>
                </div>

                <div className="border-b border-[#575560] pb-4 mb-6">
                    <h3 className="text-xl font-bold text-[#e1ff01] mb-4">Informações Pessoais</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Nome Completo
                            </label>
                            <input
                                type="text"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                                placeholder="Aloe Vera"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                E-mail
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="AloeVera@gmail.com"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Telefone
                            </label>
                            <input
                                type="tel"
                                name="telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                                placeholder="+55 (11) 1234-1234"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Data de Nascimento
                            </label>
                            <input
                                type="text"
                                name="nascimento"
                                value={formData.nascimento}
                                onChange={handleChange}
                                placeholder="21 de Jul 2024"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                CPF
                            </label>
                            <input
                                type="text"
                                name="cpf"
                                value={formData.cpf}
                                onChange={handleChange}
                                placeholder="123.123.123-9"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                RG
                            </label>
                            <input
                                type="text"
                                name="rg"
                                value={formData.rg}
                                onChange={handleChange}
                                placeholder="123.123.123-9"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <label className="text-sm font-bold" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                Chave PIX
                            </label>
                            <input
                                type="text"
                                name="chavePix"
                                value={formData.chavePix}
                                onChange={handleChange}
                                placeholder="emailplaceholder@gmail.com"
                                className="rounded-2xl w-full p-3 bg-transparent border focus:outline-none focus:ring-2 focus:ring-[#e1ff01]"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                    color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}
                            />
                        </div>
                    </div>
                </div>

                <img className="w-full h-3 object-cover mt-4" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="w-full mt-10">
                    <div className="mb-6">
                        <h2 className="font-bold text-[#e1ff01] text-2xl tracking-normal leading-normal [font-family:'Poppins',Helvetica]">
                            Personalização de Background
                        </h2>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                        <div>
                            <h3 className="font-bold text-sm text-[#f1f1f1]">Cor do Fundo</h3>
                            <div className="flex gap-2 mt-2">
                                {customColor && (
                                    <div
                                        className={`w-[38px] h-[38px] rounded-full border-4 border-[#302e38] ${role === "ceo" ? "cursor-pointer shadow-[0px_0px_0px_2px_#e1ff01]" : "cursor-not-allowed"
                                            }`}
                                        style={{ backgroundColor: customColor }}
                                        onClick={() => {
                                            if (role === "ceo") {
                                                handleCustomColorClick();
                                            }
                                        }}
                                    />
                                )}

                                {predefinedColors.map((color, index) => (
                                    <div
                                        key={index}
                                        className={`w-[38px] h-[38px] rounded-full border-4 border-[#302e38] ${selectedColor === color && !customColor
                                                ? "shadow-[0px_0px_0px_2px_#e1ff01]"
                                                : ""
                                            } ${role === "ceo" ? "cursor-pointer" : "cursor-not-allowed"}`}
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            if (role === "ceo") {
                                                handlePredefinedColorClick(color);
                                            }
                                        }}
                                    />
                                ))}

                                <div
                                    className={`w-[38px] h-[38px] ${role === "ceo" ? "cursor-pointer" : "cursor-not-allowed"}`}
                                    onClick={() => {
                                        if (role === "ceo") {
                                            handleCustomColorClick();
                                        }
                                    }}
                                >
                                    <img className="w-full h-full" alt="Escolher cor personalizada" src="https://c.animaapp.com/z4rpfJ0E/img/-.svg" />
                                </div>

                                {isPickerOpen && role === "ceo" && (
                                    <div className="absolute z-50 mt-2">
                                        <SketchPicker color={selectedColor} onChange={handleColorChange} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div>
                            <h3 className="font-bold text-sm text-[#f1f1f1]">Alterar Fundo</h3>
                            <div
                                className="flex items-center gap-2 rounded-2xl border mt-2 h-11 px-4"
                                style={{
                                    backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                    borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                }}
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="bg-transparent flex-1 text-sm file:mr-4 file:py-2 file:px-4 file:rounded-xl file:border-0 file:text-sm file:font-semibold file:bg-[#e1ff01] file:text-[#302e38] focus:outline-none"
                                    style={{
                                        backgroundColor: theme === "dark" ? "#2f2e39" : "#CACBCB",
                                        borderColor: theme === "dark" ? "#575560" : "#DDDDDD",
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}
                                    disabled={role !== "ceo"} // Desabilitado se a role não for "ceo"
                                />
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                            <div className="flex items-center gap-4 mt-6 w-full sm:w-auto">
                                <span className="font-bold text-sm" style={{ color: theme === "dark" ? "#F1F1F1" : "#000000" }}>
                                    Efeito Blur
                                </span>
                                <Switch
                                    size="lg"
                                    isChecked={blurEffect === "active"}
                                    onChange={() => {
                                        if (role === "ceo") {
                                            toggleBlurEffect();
                                        }
                                    }}
                                    isDisabled={role !== "ceo"}
                                    sx={{
                                        ".chakra-switch__track": {
                                            backgroundColor: blurEffect === "active" ? "#e1ff01" : "#575560",
                                            height: "28px",
                                            width: "60px",
                                            display: "flex",
                                            alignItems: "center",
                                        },
                                        ".chakra-switch__thumb": {
                                            backgroundColor: "#302e38",
                                            width: "20px",
                                            height: "20px",
                                            transform: "translateX(2px)",
                                        },
                                        ".chakra-switch__track[data-checked] .chakra-switch__thumb": {
                                            transform: "translateX(30px)",
                                        },
                                    }}
                                />
                            </div>

                            <button
                                onClick={() => {
                                    if (role === "ceo") handleSaveChanges();
                                }}
                                className="bg-[#e1ff01] text-[#302e38] mt-6 font-bold py-2 px-6 sm:px-4 rounded-lg shadow-md hover:bg-[#d1d100] transition w-full sm:w-auto"
                                style={{ minWidth: "200px" }}
                            >
                                Salvar
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};
