import React, { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { useTheme } from "../../ThemeContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaChevronDown, FaChevronUp, FaTrashAlt, FaPlus } from 'react-icons/fa';
import { API_URL } from "../../config";
import { ModalCreateUser } from "../../components/ModalCreateUser";

interface User {
    id: number;
    role: string;
    name: string;
}

const AccordionSection = ({ title, rows, onDelete }: { title: string; rows: User[]; onDelete: (id: number) => void }) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggleAccordion = () => setIsOpen(!isOpen);
    const { theme } = useTheme();
    return (
        <div className="mb-6">
            <div
                className="flex items-center justify-between cursor-pointer"
                onClick={toggleAccordion}
            >
                <h2 className="font-semibold text-lg text-[#E1FF01] flex items-center">
                    {title}
                    {isOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
                </h2>
            </div>
            {isOpen && (
                <div className="overflow-x-auto mt-4 rounded-lg p-4 border border-solid border-[#575560]">
                    <table className="min-w-full bg-transparent text-center border-separate border-spacing-0" style={{ tableLayout: "fixed" }}>
                        <thead>
                            <tr className="bg-[#302F38]" style={{
                                backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                            }}>
                                <th className="p-3 text-sm font-medium text-[#F1F1F1] rounded-tl-lg" style={{
                                    width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Cargo</th>
                                <th className="p-3 text-sm font-medium text-[#F1F1F1]" style={{
                                    width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Nome Completo</th>
                                <th className="p-3 text-sm font-medium text-[#F1F1F1] rounded-tr-lg" style={{
                                    width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000",
                                }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row.id} className="text-[#F1F1F1]">
                                    <td className="p-4 text-sm font-bold" style={{ width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000", }}>{row.role}</td>
                                    <td className="p-4 text-sm font-bold" style={{ width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000", }}>{row.name}</td>
                                    <td className="p-4 text-sm font-bold" style={{ width: "30%", textAlign: "center", color: theme === "dark" ? "#F1F1F1" : "#000000", }}>
                                        <div className="flex items-center justify-center">
                                            <button
                                                className="flex items-center justify-center w-8 h-8 rounded-full bg-[#333] hover:bg-[#444] text-[#F1F1F1] transition"
                                                onClick={() => onDelete(row.id)}
                                            >
                                                <FaTrashAlt />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export const Admin = (): JSX.Element => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [administrators, setAdministrators] = useState<User[]>([]);
    const [moderators, setModerators] = useState<User[]>([]);
    const [receptionists, setReceptionists] = useState<User[]>([]);
    const { theme } = useTheme();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const company_id = localStorage.getItem("companyId");

            if (!company_id) {
                toast.error("Company ID não encontrado.");
                return;
            }

            try {
                const response = await fetch(`${API_URL}/api/users-list?company_id=${company_id}`);
                if (!response.ok) {
                    throw new Error("Erro ao buscar os dados dos usuários");
                }

                const users = await response.json();
                const admins = users
                    .filter((user: any) => user.role === "Administrador")
                    .map((user: any) => ({ ...user, role: "Administrador" }));
                const mods = users
                    .filter((user: any) => user.role === "Moderador")
                    .map((user: any) => ({ ...user, role: "Moderador" }));
                const recs = users
                    .filter((user: any) => user.role === "Recepcionista")
                    .map((user: any) => ({ ...user, role: "Recepcionista" }));

                setAdministrators(admins);
                setModerators(mods);
                setReceptionists(recs);
            } catch (error) {
                console.error(error);
                toast.error("Erro ao buscar dados dos usuários.");
            }
        };

        fetchUsers();
    }, []);

    const handleDeleteUser = async (id: number) => {
        const company_id = localStorage.getItem("companyId");

        try {
            const response = await fetch(`${API_URL}/api/users/${id}?company_id=${company_id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error("Erro ao deletar o usuário");
            }

            toast.success("Usuário deletado com sucesso");

            setAdministrators(prev => prev.filter(user => user.id !== id));
            setModerators(prev => prev.filter(user => user.id !== id));
            setReceptionists(prev => prev.filter(user => user.id !== id));
        } catch (error) {
            console.error(error);
            toast.error("Erro ao deletar o usuário.");
        }
    };

    return (
        <div
            className="flex flex-col lg:flex-row h-screen"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}
        >
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto px-4 py-4 lg:px-6 lg:py-6 mt-[70px] w-full max-w-[95vw] lg:max-w-[1880px] mx-auto">
                <div className="mb-6 flex flex-col lg:flex-row lg:items-center lg:justify-between">
                    <div className="flex-1">
                        <h1
                            className="font-bold [font-family:'Poppins',Helvetica] text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            Gerenciamento de Acessos e Permissões
                        </h1>
                        <p
                            className="text-xs sm:text-xs md:text-sm lg:text-base xl:text-base font-medium mt-2 [font-family:'Poppins',Helvetica] leading-tight"
                            style={{
                                color: theme === "dark" ? "#ffffff66" : "#545454",
                            }}
                        >
                            Administre as permissões de acesso de sua equipe, garantindo um controle organizado e seguro para cada função.
                        </p>
                    </div>
                    <button
                        className="flex justify-center items-center gap-2 font-bold bg-[#e1ff01] hover:bg-[#42404a] py-2 px-4 rounded-full transition-all mt-4 lg:mt-0 lg:ml-4"
                        style={{
                            color: "#000000",
                        }}
                        onClick={() => {
                            handleModalOpen();
                        }}
                    >
                        <FaPlus />
                        Criar novo usuário
                    </button>

                </div>
                <AccordionSection title="Administradores" rows={administrators} onDelete={handleDeleteUser} />
                <AccordionSection title="Moderadores" rows={moderators} onDelete={handleDeleteUser} />
                <AccordionSection title="Recepcionista" rows={receptionists} onDelete={handleDeleteUser} />
                <ModalCreateUser isOpen={isModalOpen} onClose={handleModalClose} />

            </div>
        </div>
    );


};
