import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Cadastro } from "./screens/Cadastro";
import { Login } from "./screens/Login";
import { Esqueci } from "./screens/Esqueci";
import { Contato } from "./screens/Contato";
import { Eventos } from "./screens/Eventos";
import { CadastroPromoters } from "./screens/CadastroPromoters";
import { Configuracao } from "./screens/Configuracao";
import { CriacaoEvento } from "./screens/CriacaoEvento";
import { AprovarPromoter } from "./screens/AprovarPromoter";
import { Admin } from "./screens/Admin";
import { GeralEvento } from "./screens/GeralEvento";
import { ConfirmarPresenca } from "./screens/ConfirmarPresenca";
import { ConfirmarAniversario } from "./screens/ConfirmarAniversario";
import { Clientes } from "./screens/Clientes";
import { LoginPromoter } from "./screens/LoginPromoter";
import { EventoRelatorio } from "./screens/EventoRelatorio";
import { RelatorioInfoEvento } from "./screens/RelatorioInfoEvento";
import { ConfiguracaoAdmin } from "./screens/ConfiguracaoAdmin";
import { ModalTicket } from "./components/ModalTicket";
import { RelatorioPromoter } from "./screens/RelatorioPromoter";
import { Teste } from "./screens/Teste";
import { Saldo } from "./screens/Saldo";
import { Principal } from "./screens/Principal";
import { Precos } from "./screens/Precos";
import { LoginAdmin } from "./screens/LoginAdmin";
import { SolicitacaoSaque } from "./screens/SolicitacaoSaque";
import { RelatorioAdmin } from "./screens/RelatorioAdmin";
import { Sobrenos } from "./screens/Sobrenos";
import { Promoter } from "./screens/Promoters";
import { ClientesAdmin } from "./screens/ClientesAdmin";
import { Pagamentos } from "./screens/Pagamentos";
import { PaginaPrincipal } from "./screens/PaginaPrincipal";
import { VerEventos } from "./screens/VerEventos";
import { AuthProvider } from "./AuthContext";
import { ProtectedRoute } from "./ProtectedRoute";
import { ProtectedRouteAdmin } from "./ProtectedRouteAdmin";
import { Recuperar } from "./screens/Recuperar";
import ErrorBoundary from "./ErroBoundary";
import { ThemeProvider } from "./ThemeContext";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

root.render(
  <ErrorBoundary>
    <ChakraProvider>
      <ThemeProvider>
        <Router>
          <AuthProvider>
            <Routes>
              <Route
                path="/eventos"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod', 'rec']}>
                    <Eventos />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configuracao"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod', 'rec']}>
                    <Configuracao />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/eventos/criar"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod']}>
                    <CriacaoEvento />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/eventos/detalhes/:id"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod', 'rec']}>
                    <EventoRelatorio />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/eventos/relatorio/:id"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod']}>
                    <RelatorioInfoEvento />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod']}>
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/clientes"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod', 'rec']}>
                    <Clientes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/promoters/aprovar"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod']}>
                    <AprovarPromoter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/promoters/relatorio/:id"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod', 'rec', 'promoter']}>
                    <RelatorioPromoter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/saldo"
                element={
                  <ProtectedRoute requiredRoles={['ceo']}>
                    <Saldo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/promoters"
                element={
                  <ProtectedRoute requiredRoles={['ceo', 'mod']}>
                    <Promoter />
                  </ProtectedRoute>
                }
              />
              <Route path="/eventos/:nomefantasia" element={<ConfirmarPresenca />} />
              <Route path="/eventos/:nomefantasia/:token" element={<ConfirmarPresenca />} />
              <Route path="/eventos/:nomefantasia/aniversario/:token" element={<ConfirmarAniversario />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login-admin" element={<LoginAdmin />} />
              <Route path="/login/promoter/:nomefantasia" element={<LoginPromoter />} />
              <Route path="/cadastro" element={<Cadastro />} />
              <Route path="/cadastro/promoter/:nomefantasia" element={<CadastroPromoters />} />
              <Route path="/recuperar" element={<Recuperar />} />
              <Route path="/" element={<PaginaPrincipal />} />
              <Route path="/contato" element={<Contato />} />
              <Route path="/planos" element={<Precos />} />
              <Route path="/pagamento" element={<Pagamentos />} />
              <Route path="/sobre" element={<Sobrenos />} />
              <Route path="/teste" element={<Teste />} />
              <Route path="/esqueci-senha/:token" element={<Esqueci />} />

              <Route
                path="/saque-requests"
                element={
                  <ProtectedRouteAdmin>
                    <SolicitacaoSaque />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/clientes-admin"
                element={
                  <ProtectedRouteAdmin>
                    <ClientesAdmin />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/relatorios"
                element={
                  <ProtectedRouteAdmin>
                    <RelatorioAdmin />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/ver-eventos"
                element={
                  <ProtectedRouteAdmin>
                    <VerEventos />
                  </ProtectedRouteAdmin>
                }
              />
              <Route
                path="/configuracao-admin"
                element={
                  <ProtectedRouteAdmin>
                    <ConfiguracaoAdmin />
                  </ProtectedRouteAdmin>
                }
              />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </ChakraProvider>
  </ErrorBoundary>
);
