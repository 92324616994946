import React from "react";
import { BotO } from "../../components/BotO";

export const Teste = (): JSX.Element => {
    return (
        <div className="fixed w-[305px] h-[385px] top-[10%] left-[50%]">
            <div className="relative w-[301px] h-[385px]">
                <img
                    className="absolute w-[301px] h-[366px] top-0 left-0"
                    alt="Foto"
                    src="https://c.animaapp.com/lOl8XZnJ/img/foto.svg"
                />

                <div className="absolute w-[302px] h-[170px] top-[220px] bg-[#E1FF01] rounded-[0px_0px_20px_20px]" />

                <div className="h-[115px] top-[17px] left-[17px] absolute w-[57px]">
                    <div className="flex flex-col h-[71px] items-center justify-center gap-2.5 px-4 py-2.5 top-0 left-0 bg-[#e1ff01] rounded-2xl absolute w-[57px]">
                        <div className="inline-flex flex-col items-start gap-2.5">
                            <p className="relative self-stretch [font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0] leading-[normal]">
                                <span className="[font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0]">
                                    30
                                    <br />
                                </span>
                            </p>

                            <p className="relative self-stretch [font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0] leading-[normal]">
                                <span className="[font-family:'Poppins',Helvetica] font-medium text-black text-base tracking-[0]">
                                    Jul{" "}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col h-[39px] items-center justify-center gap-2.5 px-4 py-2.5 top-[76px] left-0 bg-white rounded-2xl absolute w-[57px]">
                        <div className="relative w-fit mt-[-3.10px] mb-[-1.10px] ml-[-0.50px] mr-[-0.50px] [font-family:'Poppins',Helvetica] font-medium text-black text-[15px] tracking-[0] leading-[normal]">
                            Sex
                        </div>
                    </div>
                </div>

                <div className="flex w-72 h-[35px] items-start gap-2.5 absolute top-[175px] left-1.5">

                </div>

                <div className="absolute w-[190px] top-[272px] left-[111px] [font-family:'Poppins',Helvetica] font-bold text-black text-[32px] text-center tracking-[0] leading-[28.1px] whitespace-nowrap">
                    Aloe Vera
                </div>

                <div className="absolute w-[172px] top-[254px] left-[120px] [font-family:'Poppins',Helvetica] font-normal text-black text-xs text-center tracking-[0] leading-[28.1px] whitespace-nowrap">
                    Aniversário de
                </div>

                <div className="flex w-[156px] absolute top-[226px] left-[133px] items-center gap-[7.5px]">
                    <div className="inline-flex justify-center px-[7.5px] py-[3px] relative flex-[0_0_auto] bg-[#cfcfcf] rounded-3xl items-center gap-[7.5px]">
                        <div className="relative w-fit mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#302e38] text-[7.5px] tracking-[0] leading-[normal]">
                            Funkzin
                        </div>
                    </div>

                    <div className="inline-flex justify-center px-[7.5px] py-[3px] relative flex-[0_0_auto] bg-[#cfcfcf] rounded-3xl items-center gap-[7.5px]">
                        <div className="relative w-fit mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#302e38] text-[7.5px] tracking-[0] leading-[normal]">
                            Brega
                        </div>
                    </div>

                    <div className="flex w-[55px] items-center justify-center gap-[7.5px] p-[3px] relative bg-[#cfcfcf] rounded-3xl">
                        <div className="relative w-fit mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#302e38] text-[7.5px] tracking-[0] leading-[normal]">
                            Black music
                        </div>
                    </div>
                </div>



                <div className="flex w-[187px] items-center justify-center gap-5 p-2.5 absolute top-[325px] left-[111px] rounded-[20px] border border-solid border-black">
                    <div className="flex items-start gap-2.5 relative flex-1 grow">
                        <img
                            className="relative w-[15.38px] h-[30.75px]"
                            alt="Vector"
                            src="https://c.animaapp.com/lOl8XZnJ/img/vector-3.svg"
                        />

                        <div className="flex flex-col w-[62.25px] items-start gap-[3.75px] relative mr-[-24.62px]">
                            <div className="relative self-stretch mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#1b1b20] text-[9px] tracking-[0] leading-[normal]">
                                Mulher
                            </div>

                            <div className="self-stretch relative [font-family:'Poppins',Helvetica] font-bold text-[#1b1b20] text-xs tracking-[0] leading-[normal]">
                                R$ 10,00
                            </div>
                        </div>
                    </div>

                    <div className="relative w-px h-[26px] bg-[#33323b] rounded-sm" />

                    <div className="flex items-start gap-[3.75px] relative flex-1 grow">
                        <img
                            className="relative w-[12.3px] h-[30.75px]"
                            alt="Vector"
                            src="https://c.animaapp.com/lOl8XZnJ/img/vector-4.svg"
                        />

                        <div className="flex flex-col w-[65.25px] items-start gap-[3.75px] relative mr-[-18.30px]">
                            <div className="relative self-stretch mt-[-0.75px] [font-family:'Poppins',Helvetica] font-medium text-[#1b1b20] text-[9px] tracking-[0] leading-[normal]">
                                Homem
                            </div>

                            <div className="w-fit relative [font-family:'Poppins',Helvetica] font-bold text-[#1b1b20] text-xs tracking-[0] leading-[normal]">
                                R$ 20,00
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute w-[89px] h-32 top-[254px] left-[5px] bg-[#1b1b20] rounded-[20px]">
                    <div className="flex w-[77px] h-[22px] items-center justify-center gap-2.5 px-2.5 py-1 relative -top-7 left-1.5 bg-[#cfcfcf] rounded-[20px]">
                        <div className="relative w-fit mt-[-8.50px] mb-[-6.50px] [font-family:'Poppins',Helvetica] font-medium text-black text-[10px] tracking-[0] leading-[28.1px] whitespace-nowrap">
                            Atração
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-1 absolute left-5 top-5">
                        <div className="relative [font-family:'Poppins',Helvetica] font-medium text-white text-[12px] tracking-[0] leading-[normal]">
                            Mc Gui
                        </div>
                        <div className="relative [font-family:'Poppins',Helvetica] font-medium text-white text-[12px] tracking-[0] leading-[normal]">
                            DJ X
                        </div>
                        <div className="relative [font-family:'Poppins',Helvetica] font-medium text-white text-[12px] tracking-[0] leading-[normal]">
                            DJ Y
                        </div>
                        <div className="relative [font-family:'Poppins',Helvetica] font-medium text-white text-[12px] tracking-[0] leading-[normal]">
                            Banda Z
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
