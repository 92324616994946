import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { API_URL } from "../../config";
import { useTheme } from "../../ThemeContext";
import { ModalAddUser } from "../../components/ModalAddUser";
import { toast, ToastContainer } from 'react-toastify';
import glow from "../../imagens/glow-geral-evento.png";
import { ModalQrReader } from "../../components/ModalQrReader";
import 'react-toastify/dist/ReactToastify.css';
import { FaChartBar, FaLink, FaCamera, FaCheckCircle, FaDollarSign, FaTicketAlt, FaSearch } from 'react-icons/fa';
import { MdOutlineCameraAlt } from "react-icons/md";
import { BsBarChartFill } from "react-icons/bs";

interface Participant {
    full_name: string;
    cpf: string;
    promoter_name: string;
    telefone: string;
    status: string; // Adiciona o campo status
}

interface SalesData {
    event_name: string;
    confirmed_participants: number;
    total_sales: string;
    daily_sales: string;
    participants: Participant[];
}

export const EventoRelatorio = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);
    const handleQrModalOpen = () => setIsQrModalOpen(true);
    const handleQrModalClose = () => setIsQrModalOpen(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [salesData, setSalesData] = useState<SalesData>({
        event_name: '',
        confirmed_participants: 0,
        total_sales: '0.00',
        daily_sales: '0.00',
        participants: []
    });

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    const { theme } = useTheme();

    useEffect(() => {
        const fetchEventSalesData = async () => {
            try {
                const company_id = localStorage.getItem("companyId");
                const event_id = id;

                if (!company_id || !event_id) {
                    toast.error('Company ID ou Event ID não encontrados');
                    return;
                }

                const response = await fetch(`${API_URL}/api/event-sales`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ company_id, event_id }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setSalesData(data);

                    if (data.company_name) {
                        localStorage.setItem("companyFantasyName", data.company_name);
                    }
                } else {
                    const errorData = await response.json();
                    toast.error(errorData.message || 'Erro ao carregar dados de vendas');
                }
            } catch (error) {
                console.error("Erro ao buscar dados de vendas:", error);
                toast.error('Erro ao carregar dados de vendas');
            }
        };

        fetchEventSalesData();
    }, [id]);

    const handleCopyLink = () => {
        const companyFantasyName = localStorage.getItem("companyFantasyName");

        if (!companyFantasyName) {
            toast.error('Nome fantasia da empresa não encontrado');
            return;
        }

        const link = `${window.location.origin}/eventos/${companyFantasyName}`;
        navigator.clipboard.writeText(link)
            .then(() => {
                toast.success('Link de divulgação copiado com sucesso!');
            })
            .catch(() => {
                toast.error('Erro ao copiar o link de divulgação');
            });
    };



    const handleScan = async (data: string | null) => {
        if (data) {
            try {
                const response = await fetch(data);

                if (response.ok) {
                    const result = await response.json();
                    toast.success(`Status do usuário: ${result.status}`);
                    refreshSalesData();
                } else {
                    const errorData = await response.json();
                    toast.error(errorData.message || 'Erro ao verificar QR Code');
                }
            } catch (error) {
                console.error("Erro ao verificar QR Code:", error);
                toast.error('Erro ao verificar QR Code');
            }

            handleQrModalClose(); // Fecha o modal após a leitura
        }
    };

    const refreshSalesData = async () => {
        try {
            const company_id = localStorage.getItem("companyId");
            const event_id = id;

            if (!company_id || !event_id) {
                toast.error('Company ID ou Event ID não encontrados');
                return;
            }

            const response = await fetch(`${API_URL}/api/event-sales`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ company_id, event_id }),
            });

            if (response.ok) {
                const data = await response.json();
                setSalesData(data);
            } else {
                const errorData = await response.json();
                toast.error(errorData.message || 'Erro ao carregar dados de vendas');
            }
        } catch (error) {
            console.error("Erro ao buscar dados de vendas:", error);
            toast.error('Erro ao carregar dados de vendas');
        }
    };

    const [searchTerm, setSearchTerm] = useState("");
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        refreshSalesData();
    }, [id]);

    const getStatusInlineStyle = (status: string) => {
        const baseStyle = {
            color: "#F1F1F1",
            height: "44px",
            maxWidth: "100%", // para garantir que se ajuste ao container
            padding: "0 16px", // padding horizontal para espaçamento interno
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
            borderRadius: "9999px",
            fontSize: "0.875rem", // ajuste de tamanho da fonte para responsividade
        };

        if (status === "Confirmado") {
            return {
                ...baseStyle,
                color: "#E1FF01",
                backgroundColor: "rgba(175, 245, 0, 0.1)",
                border: "1px solid rgba(175, 245, 0, 0.25)",
            };
        } else if (status === "Cancelado") {
            return {
                ...baseStyle,
                backgroundColor: "rgba(245, 0, 88, 0.1)",
                border: "1px solid rgba(245, 0, 88, 0.25)",
            };
        } else if (status === "Pagamento Antecipado") {
            return {
                ...baseStyle,
                backgroundColor: "rgba(83, 156, 255, 0.1)",
                border: "1px solid rgba(83, 156, 255, 0.25)",
            };
        }

        return {};
    };

    const filteredParticipants = salesData.participants.filter(participant =>
        participant.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const navigate = useNavigate();

    const handleNavigateToReport = () => {
        navigate(`/eventos/relatorio/${id}`);
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen overflow-hidden"
            style={{
                backgroundColor: theme === "dark" ? "#22252A" : "#D6D6D6",
            }}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <Header />
            <ToastContainer />
            <div className="flex-1 overflow-y-auto p-4 lg:p-6 mt-[70px] w-full max-w-[1880px] mx-auto">
                <div className="mb-6 flex flex-col lg:flex-row items-center lg:items-start justify-between">
                    <div className="text-center lg:text-left">
                        <h1
                            className="font-bold [font-family:'Poppins',Helvetica] text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                            }}
                        >
                            {salesData.event_name || 'Evento'}
                        </h1>
                        <p
                            className="text-[10px] sm:text-xs md:text-xs lg:text-sm font-medium mt-2 [font-family:'Poppins',Helvetica] leading-tight whitespace-nowrap"
                            style={{
                                color: theme === "dark" ? "#ffffff66" : "#545454",
                            }}
                        >
                            Visão geral
                        </p>


                        <div className="flex flex-col gap-2 mt-3 w-full lg:hidden">
                            <button
                                className="flex items-center justify-center gap-1 rounded-full bg-[#302F38] text-[#F1F1F1] w-[340px] h-[25px] text-[12px] font-bold hover:bg-[#44424c] transition-all"
                                style={{
                                    border: "1px solid #575561"
                                }}
                                onClick={handleNavigateToReport}
                            >
                                <BsBarChartFill style={{ width: "13px", height: "11px" }} />
                                <span>Ver Relatório</span>
                            </button>
                            <button
                                onClick={handleCopyLink}
                                className="flex items-center justify-center gap-1 rounded-full text-white w-[340px] h-[25px] text-[12px] font-bold hover:bg-[#0056b3] transition-all"
                                style={{
                                    backgroundColor: "rgba(83, 156, 255, 0.45)",
                                    border: "1px solid #539CFF"
                                }}
                            >
                                <FaLink style={{ width: "13px", height: "11px" }} className="text-white" />
                                <span>Copiar Link de divulgação</span>
                            </button>
                            <button
                                className="flex items-center justify-center gap-1 rounded-full bg-[#E1FF01] text-black w-[340px] h-[25px] text-[12px] font-bold hover:bg-[#c6e600] transition-all"
                                onClick={handleQrModalOpen}
                            >
                                <MdOutlineCameraAlt style={{ width: "15px", height: "13px" }} className="text-black" />
                                <span>Abrir câmera</span>
                            </button>
                        </div>
                    </div>

                    <div className="hidden lg:flex flex-wrap gap-2 justify-end w-full mt-2">
                        <button
                            className="flex items-center justify-center gap-1 rounded-full bg-[#302F38] text-[#F1F1F1] w-[140px] h-[32px] text-xs font-bold hover:bg-[#44424c] transition-all"
                            style={{
                                border: "1px solid #575561"
                            }}
                            onClick={handleNavigateToReport}
                        >
                            <BsBarChartFill style={{ width: "15px", height: "13px" }} />
                            <span>Ver Relatório</span>
                        </button>
                        <button
                            className="flex items-center justify-center gap-1 rounded-full text-white w-[257px] h-[32px] text-xs font-bold hover:bg-[#0056b3] transition-all"
                            style={{
                                backgroundColor: "rgba(83, 156, 255, 0.45)",
                                border: "1px solid #539CFF"
                            }}
                            onClick={handleCopyLink}
                        >
                            <FaLink style={{ width: "15px", height: "13px" }} className="text-white" />
                            <span>Copiar Link de divulgação</span>
                        </button>
                        <button
                            className="flex items-center justify-center gap-1 rounded-full bg-[#E1FF01] text-black w-[150px] h-[32px] text-xs font-bold hover:bg-[#c6e600] transition-all"
                            onClick={handleQrModalOpen}
                        >
                            <MdOutlineCameraAlt style={{ width: "17px", height: "15px" }} className="text-black" />
                            <span>Abrir câmera</span>
                        </button>
                    </div>
                </div>

                <img className="w-full h-3 object-cover" alt="Divisor" src="https://c.animaapp.com/tyxgDvEv/img/divisor-7.svg" />

                <div className="flex flex-col lg:flex-row w-full justify-center items-center gap-4 mt-2">
                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <FaCheckCircle className="text-[#E1FF01] text-xl mr-2" />
                            <p className="text-[#E1FF01] font-medium text-sm">Presenças confirmadas</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>{salesData.confirmed_participants}</span>
                    </div>
                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <FaDollarSign className="text-[#E1FF01] text-xl mr-2" />
                            <p className="text-[#E1FF01] font-medium text-sm">Total de Vendas</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>R$ {salesData.total_sales}</span>
                    </div>
                    <div className="border border-solid border-[#575560] flex flex-col rounded-lg p-6 w-full max-w-[350px] lg:max-w-[380px] text-left"
                        style={{
                            backgroundColor: theme === "dark" ? "rgba(51, 50, 60, 0.25)" : "#CACBCB",
                        }}>
                        <div className="flex items-center mb-2">
                            <FaTicketAlt className="text-[#E1FF01] text-xl mr-2" />
                            <p className="text-[#E1FF01] font-medium text-sm">Venda Diária</p>
                        </div>
                        <span className="text-[#F1F1F1] text-2xl font-bold" style={{
                            color: theme === "dark" ? "#F1F1F1" : "#000000",
                        }}>R$ {salesData.daily_sales}</span>
                    </div>
                </div>
                <div className="flex items-center w-full mt-5 mb-4">
                    <div className="relative flex items-center w-full">
                        <FaSearch className="absolute left-3 text-[#A0A0A0] text-sm sm:text-base md:text-lg" />
                        <input
                            type="text"
                            placeholder="Procurar nome"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full pl-10 pr-[120px] sm:pr-[160px] md:pr-[250px] lg:pr-[270px] h-10 sm:h-12 md:h-14 rounded-lg bg-[#33323C] text-[#E1E1E1] focus:outline-none"
                            style={{
                                border: "1px solid #575561",
                                backgroundColor: theme === "dark" ? "#302F38" : "#CACBCB",
                            }}
                        />
                        <button
                            className="absolute right-2 bg-[#44424C] hover:bg-[#5b5a63] text-[#E1E1E1] font-bold rounded-lg 
               text-xs sm:text-sm md:text-base px-2 sm:px-3 md:px-4 
               h-[30px] sm:h-[35px] md:h-[37px] w-[150px] sm:w-[160px] md:w-[200px]"
                            style={{
                                border: "1px solid #575561",
                            }}
                            onClick={handleModalOpen}
                        >
                            + Adicionar cadastro
                        </button>
                    </div>
                </div>

                <div className="overflow-x-auto w-full mt-5">
                    <div className="min-w-full h-full bg-transparent text-center">
                        <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr className="bg-[#302F38]" style={{
                                    backgroundColor: theme === "dark" ? "#42404a" : "#CACBCB",
                                }}>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm rounded-tl-lg rounded-bl-lg w-1/4" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Nome Completo</th>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Promoter</th>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>WhatsApp</th>
                                    <th className="p-2 sm:p-3 font-medium text-[#F1F1F1] text-xs sm:text-sm w-1/4 rounded-tr-lg rounded-br-lg" style={{
                                        color: theme === "dark" ? "#F1F1F1" : "#000000",
                                    }}>Presença</th>
                                </tr>
                            </thead>

                        </table>
                        <div
                            style={{
                                maxHeight: '460px',
                                overflowY: 'auto',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                            className="w-full"
                        >
                            <table className="w-full bg-transparent" style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {filteredParticipants.map((participant, index) => (
                                        <tr key={index} className="border-b border-[#42404a]">
                                            <td className="p-4 sm:p-5 text-[#E1E1E1] font-bold text-xs sm:text-sm w-1/4" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{participant.full_name}</td>
                                            <td className="p-4 sm:p-5 text-[#E1E1E1] font-bold text-xs sm:text-sm w-1/4" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{participant.promoter_name || ""}</td>
                                            <td className="p-4 sm:p-5 text-[#E1E1E1] font-bold text-xs sm:text-sm w-1/4" style={{
                                                color: theme === "dark" ? "#F1F1F1" : "#000000",
                                            }}>{participant.telefone}</td>
                                            <td
                                                className="p-4 sm:p-5 text-center font-bold text-xs sm:text-sm"
                                                style={{
                                                    ...getStatusInlineStyle(participant.status),
                                                    textAlign: "center",
                                                }}
                                            >
                                                {participant.status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddUser
                isOpen={isModalOpen}
                onClose={handleModalClose}
                eventName={salesData.event_name}
                onSuccess={refreshSalesData}
            />
            <ModalQrReader
                isOpen={isQrModalOpen}
                onClose={handleQrModalClose}
                onScan={handleScan}
            />
        </div>
    );
};
